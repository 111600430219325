import React from 'react';
import { ExamplePatient } from '../../../models/ExamplePatient';
import { getWorstCaseDeg } from '../../../helpers/helpers';
import { DegenerationLevels } from '../../../models/ExampleXray';
import './CareMatrix.scss';

interface CareMatrixProps {
  patient: ExamplePatient;
  hideHighlight?: boolean;
}

const CareMatrix: React.FC<CareMatrixProps> = ({ patient, hideHighlight }) => {
  const getHighlightClass = (cell: number) => {
    const score = patient.proScore;
    const worstCase = getWorstCaseDeg(Object.values(patient.xrays.forAnalysis));
    const cssClass = 'cm-highlight'

    if (hideHighlight) return;

    if (
      (
        cell === 5
        && score > 70
        && (worstCase === DegenerationLevels.NORMAL || worstCase === DegenerationLevels.MODERATE)
      )
      ||
      (
        cell === 6
        && score < 70
        && (worstCase === DegenerationLevels.NORMAL || worstCase === DegenerationLevels.MODERATE)
      )
      ||
      (
        cell === 8
        && score > 70
        && (worstCase === DegenerationLevels.NEAR_ENDSTAGE || worstCase === DegenerationLevels.ENDSTAGE)
      )
      ||
      (
        cell === 9
        && score < 70
        && (worstCase === DegenerationLevels.NEAR_ENDSTAGE || worstCase === DegenerationLevels.ENDSTAGE)
      )
    ) {
      return cssClass;
    }
  };

  return (
    <div className='CareMatrix__Container'>
      <div className='cell-1' />
      <div className='CareMatrix__Label cell-2'>
        <p>Score &gt; 70</p>
      </div>
      <div className='CareMatrix__Label cell-3'>
        <p>Score &lt; 70</p>
      </div>

      <div className='CareMatrix__Label cell-4'>
        <p>Normal / Slight to Moderate</p>
      </div>
      <div className={`CareMatrix__Cell cell-5 ${getHighlightClass(5)}`}>
        <p>Non-Operative Candidate</p>
      </div>
      <div className={`CareMatrix__Cell cell-6 ${getHighlightClass(6)}`}>
        <p>Non-Operative Candidate</p>
      </div>

      <div className='CareMatrix__Label cell-7'><p>Near End Stage to End Stage</p></div>
      <div className={`CareMatrix__Cell cell-8 ${getHighlightClass(8)}`}>
        <p>Non-Operative Candidate</p>
      </div>
      <div className={`CareMatrix__Cell cell-9 ${getHighlightClass(9)}`}>
        <p>Surgical Candidate</p>
      </div>
    </div>
  );
};

export default CareMatrix;
