import React, { HTMLAttributes } from 'react';
import './Surface.scss';

type SurfaceProps = {
  children: React.ReactNode;
  variant?: 'contained' | 'smooth';
  fullWidth?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Surface: React.FC<SurfaceProps> = ({
  children,
  fullWidth,
  variant = 'contained',
  ...props
}) => (
  <div
    {...props}
    className={`Surface__Container ${variant} ${fullWidth ? 'full-width' : ''} ${props.className ? props.className : ''}`}
  >
    {children}
  </div>
);

export default Surface;
