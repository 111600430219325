import React, { HTMLAttributes } from 'react';
import './BulletItem.scss';

type BulletItemProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const BulletItem: React.FC<BulletItemProps> = ({ children }) => (
  <li className='BulletItem__Container'>
    <p className='BulletItem__Bullet'>•</p>
    {children}
  </li>
);

export default BulletItem;
