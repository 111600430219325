import React, { useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CornerTag } from '../../atoms/CornerTag';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { getJoint } from '../../../helpers/helpers';
import { CTA } from '../../templates/CTA';
import { PDF } from '../../molecules/PDF';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import { ReactComponent as SBSHips } from '../../../assets/images/SBS Hips 1.svg';
import { ReactComponent as SBSKnees } from '../../../assets/images/SBSKneesSkeleton.svg';
import './SummaryStep.scss';

// patient flow step 10 - final patient exam summary with a static PDF report

const SummaryStep: React.FC<StepProps> = ({ flow }) => {
  const {
    patients,
    activePatient,
    activePatientNumber,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  const joint = getJoint(activePatient?.joint);

  const allPatients = !isTrainingFlow && !isTestingFlow ? Object.values(patients) : Object.values(patients).sort((a, b) => a.flowOrders[flow] - b.flowOrders[flow]);
  const kneePatients = allPatients.filter((patient) => getJoint(patient.joint) === 'knee');
  const hipPatients = allPatients.filter((patient) => getJoint(patient.joint) === 'hip');

  const isLastJointPatient = activePatient?.key === kneePatients[kneePatients.length - 1].key
    || activePatient?.key === hipPatients[hipPatients.length - 1].key;

  const [showCTA, setShowCTA] = useState<boolean>(isLastJointPatient);

  if (showCTA) {
    return (
      <CTA
        declineBtnText='Maybe Later, See Patient Summary'
        declineBtnAction={() => setShowCTA(false)}
      />
    );
  }

  if (!activePatient) return null;

  return (
    <div className='SummaryStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='SummaryStep__Container'>
        <h3 className='SummaryStep__Header'>
          Patient Summary + Recommended Care Pathway
        </h3>
        <div className='SummaryStep__MainContent'>
          <div className='SummaryStep__MainContentL'>
            <PDF src={isTrainingFlow ? activePatient.pdf : activePatient.TYKPdf} />
          </div>
          <div className='SummaryStep__MainContentR'>
            <h4>{joint} summary</h4>
            {joint === 'hip'
              ? <SBSHips />
              : <SBSKnees />
            }
          </div>
        </div>
      </div>
      <NavigationFooter
        className='SummaryStep__Nav'
        prev={{ action: prevStep }}
        next={{ action: nextStep, label: 'Finish' }}
      />
    </div>
  );
};

export default SummaryStep;
