import React, { useState } from 'react';
import { Modal } from '../../molecules/Modal';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { StepperController } from '../../molecules/StepperController';
import HipStep1 from '../../../assets/tutorialAnimations/HipGIF1.gif';
import HipStep2 from '../../../assets/tutorialAnimations/HipGIF2.gif';
import HipStep3 from '../../../assets/tutorialAnimations/HipGIF3.gif';
import KneeStep1 from '../../../assets/tutorialAnimations/KneeGIF1.gif';
import KneeStep2 from '../../../assets/tutorialAnimations/KneeGIF2.gif';
import KneeStep3 from '../../../assets/tutorialAnimations/KneeGIF3.gif';
import './XrayMatchingTutorialModal.scss';

interface XrayMatchingTutorialModalProps {
  joint: string;
  closeModal(): void;
  completeTutorial?(): void;
}

const XrayMatchingTutorialModal: React.FC<XrayMatchingTutorialModalProps> = ({
  joint,
  completeTutorial,
  closeModal,
}) => {
  const [activeModalScreenKey, setActiveModalScreenKey] = useState<number>(0);

  const modalScreens: React.ReactNode[] = [
    (
      <>
        <h2 className='XrayMatchingTutorialModal__Header'>
          Tutorial: How to Match X-rays
        </h2>
        <div className='XrayMatchingTutorialModal__SubHeaderContainer'>
          <h3 className='XrayMatchingTutorialModal__SubHeader'>
            The next 3 slides are a step by step guide on how to complete an X-ray Matching
            Evaluation. There will be an example Evaluation demonstrated, so no action is
            required on your end.
          </h3>
        </div>
      </>
    ),
    (
      <>
        <h2 className='XrayMatchingTutorialModal__Header'>
          How to Match X-rays
        </h2>
        <div className='XrayMatchingTutorialModal__SubHeaderContainer'>
          <h1 className='XrayMatchingTutorialModal__StepNumber'>1</h1>
          <h3 className='XrayMatchingTutorialModal__SubHeader'>
            Focus on the area within the {joint === 'knee' ? 'box' : 'wedge'} of the X-ray with the least amount of
            space between the subchondral bone lines.
          </h3>
        </div>
        <div className='XrayMatchingTutorialModal__GifContainer'>
          <img
            className='XrayMatchingTutorialModal__Gif'
            src={joint === 'hip' ? HipStep1 : KneeStep1}
            alt='Tutorial Step 1'
          />
        </div>
      </>
    ),
    (
      <>
        <h2 className='XrayMatchingTutorialModal__Header'>
          How to Match X-rays
        </h2>
        <div className='XrayMatchingTutorialModal__SubHeaderContainer'>
          <h1 className='XrayMatchingTutorialModal__StepNumber'>2</h1>
          <h3 className='XrayMatchingTutorialModal__SubHeader'>
            Click the different levels of degeneration to see which option looks
            most like the patient's.
          </h3>
        </div>
        <div className='XrayMatchingTutorialModal__GifContainer'>
          <img
            className='XrayMatchingTutorialModal__Gif'
            src={joint === 'hip' ? HipStep2 : KneeStep2}
            alt='Tutorial Step 2'
          />
        </div>
      </>
    ),
    (
      <>
        <h2 className='XrayMatchingTutorialModal__Header'>
          How to Match X-rays
        </h2>
        <div className='XrayMatchingTutorialModal__SubHeaderContainer'>
          <h1 className='XrayMatchingTutorialModal__StepNumber'>3</h1>
          <h3 className='XrayMatchingTutorialModal__SubHeader'>
            Match the joint space of the 'Patient's X-ray' with the 'Comparison X-ray'
            and then submit your evaluation.
          </h3>
        </div>
        <div className='XrayMatchingTutorialModal__GifContainer'>
          <img
            className='XrayMatchingTutorialModal__Gif'
            src={joint === 'hip' ? HipStep3 : KneeStep3}
            alt='Tutorial Step 3'
          />
        </div>
      </>
    ),
  ];

  return (
    <Modal>
      <div className='XrayMatchingTutorialModal__ContentContainer'>
        <div className={`XrayMatchingTutorialModal__Content ${activeModalScreenKey === 0 ? 'xessm-center' : ''}`}>
          {modalScreens[activeModalScreenKey]}
        </div>
      </div>
      <NavigationFooter
        style={{ backgroundColor: 'unset', boxShadow: 'unset', padding: '0 5%' }}
        centerElement={activeModalScreenKey !== 0
          ? (
            <StepperController
              stepCount={3}
              activeStep={activeModalScreenKey}
              setStep={setActiveModalScreenKey}
            />
          )
          : null
        }
        prev={{ action: activeModalScreenKey === 0
          ? closeModal
          : () => setActiveModalScreenKey(activeModalScreenKey - 1)
        }}
        next={{ action: activeModalScreenKey === modalScreens.length - 1
          ? completeTutorial || closeModal
          : () => setActiveModalScreenKey(activeModalScreenKey + 1)
        }}
      />
    </Modal>
  );
};

export default XrayMatchingTutorialModal;
