import React, { useState } from 'react';
import './EmailFormGate.scss';
import httpService from '../../../services/httpService';
import lsService from '../../../services/lsService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Personas } from '../../../models/Personas';
import { Surface } from '../../atoms/Surface';
import { Input } from '../../atoms/Input';
import { DropdownSelect } from '../../molecules/DropdownSelect';
import { Checkbox } from '../../atoms/Checkbox';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { validateEmail } from '../../../helpers/helpers';

interface EmailGateProps {
    children: React.ReactNode
}

const LS_KEY = 'signedUp';
const HIDE_EMAIL_PARAM = 'hideemail';
const TRANSITION_DURATION_MS = 500
// the exposed lambda function URL which will place the provided email onto the edu_emails table
const EMAIL_LAMBDA_URL = process.env.REACT_APP_EMAIL_COLLECTION_URL

const EmailFormGate: React.FC<EmailGateProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { saveItem, getItem } = lsService;
    const { post } = httpService;

    const queryParams = new URLSearchParams(location.search);
    const hideParam = !!(parseInt(queryParams.get(HIDE_EMAIL_PARAM) || '0') === 1);

    const userSignedUp = !!getItem(LS_KEY);
    const hideForm = userSignedUp || hideParam;

    const [showEmailForm, setShowEmailForm] = useState<boolean>(!hideForm);
    const [renderEmailForm, setRenderEmailForm] = useState<boolean>(!hideForm);
    const [email, setEmail] = useState<string>('');
    const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
    const [selectedPersona, setSelectedPersona] = useState<Personas>(Personas.ORTHO_SURGEON);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const emailInvalid = !validateEmail(email);
    const disableButton = !termsAgreed || !email || emailInvalid;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsError(false);

        try {
            setIsLoading(true);
            await post({ url: EMAIL_LAMBDA_URL!, data: { email, persona: selectedPersona } });

            setIsLoading(false);
            // set the flag in local storage to hide the email form
            saveItem(LS_KEY, 'true');
            setTimeout(() => {
                setRenderEmailForm(false);
            }, TRANSITION_DURATION_MS);
            setShowEmailForm(false);
        } catch (e) {
            setIsError(true);
            setIsLoading(false);
        }
    };

    return (
        <>
            {renderEmailForm && (
                <Modal>
                    <div className='EmailFormGate__Container'>
                        <Surface
                            className={`EmailFormGate__EmailFormContainer ${showEmailForm ? 'home-visible' : ''}`}
                            variant='smooth'
                            style={{ transition: `opacity ${TRANSITION_DURATION_MS}ms ease, transform ${TRANSITION_DURATION_MS}ms ease` }}
                        >
                            <form className='EmailFormGate__EmailForm' onSubmit={handleSubmit}>
                                <p className='EmailFormGate__FormLabel'>Enter your email address to proceed:</p>
                                <Input
                                    className='EmailFormGate__EmailInput'
                                    isLoading={isLoading}
                                    showError={isError}
                                    isRequired
                                    errorMessage='Something went wrong. Please try again.'
                                    aria-label='Email form'
                                    value={email}
                                    onChange={(e) => setEmail(e)}
                                />
                                <p className='EmailFormGate__FormLabel'>Which option best describes you?</p>
                                <DropdownSelect
                                    showBorder
                                    className='EmailFormGate__PersonaSelect'
                                    options={Object.values(Personas).map((persona) => ({
                                        value: persona,
                                        handleClick: () => setSelectedPersona(persona)
                                    }))}
                                    value={selectedPersona}
                                />
                                <Checkbox
                                    size='small'
                                    checkboxContainerClass='EmailFormGate__Checkbox'
                                    isSelected={termsAgreed}
                                    onChange={(e) => setTermsAgreed(e)}
                                >
                                    I would like to receive emails about new education modules,
                                    products, and partnership opportunites from JointAI.
                                </Checkbox>
                                <div className='EmailFormGate__Buttons'>
                                    <Button type="button" onClick={() => navigate("/")}>Back</Button>
                                    <Button type='submit' isDisabled={disableButton}>Next</Button>
                                </div>
                            </form>
                        </Surface>
                    </div>
                </Modal>
            )}
            {children}
        </>
    );
};

export default EmailFormGate;
