// the top level routes on the app

export enum RouteKeys {
  HOME = 'home',
  SBS_TRAINING = 'SBSTraining',
  TEST_YOUR_KNOWLEDGE = 'testYourKnowledge',
  KNEE_EDU = 'kneeEdu',
  HIP_EDU = 'hipEdu',
  CTA='cta'
};
