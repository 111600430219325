import React from 'react';
import './CornerTag.scss';

interface CornerTagProps {
  children: React.ReactNode;
  position?: 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft';
}

const CornerTag: React.FC<CornerTagProps> = ({ children, position = 'topRight' }) => (
  <div className={`CornerTag__Container ${position}`}>
    {children}
  </div>
);

export default CornerTag;
