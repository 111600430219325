import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CTA } from "../components/templates/CTA";
import { HipEdu } from "../components/templates/HipEdu";
import { Home } from "../components/templates/Home";
import { KneeEdu } from "../components/templates/KneeEdu";
import { SBSTraining } from "../components/templates/SBSTraining";
import { TestYourKnowledge } from "../components/templates/TestYourKnowledge";
import { RouteKeys } from "./routeKeys";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { educationFlowMap } from "./educationFlow";

// route objects for each member of the RouteKeys enum

interface Route {
  route: string;
  component: React.ReactNode;
  menuLabel?: string;
  menuIcon?: React.ReactNode;
  firstSubroute?: string;
}

type RoutesMap = {
  [K in RouteKeys]: Route;
};

export const routes: RoutesMap = {
  [RouteKeys.HOME]: {
    route: '/',
    component: <Home />,
    menuLabel: 'Home',
    menuIcon: <FontAwesomeIcon icon={faHome} />,
  },
  [RouteKeys.KNEE_EDU]: {
    route: '/knee-education',
    component: <KneeEdu />,
    menuLabel: 'Knee Education',
    firstSubroute: Object.values(educationFlowMap)[0].route,
  },
  [RouteKeys.HIP_EDU]: {
    route: '/hip-education',
    component: <HipEdu />,
    menuLabel: 'Hip Education',
    firstSubroute: Object.values(educationFlowMap)[0].route,
  },
  [RouteKeys.SBS_TRAINING]: {
    route: '/sbs-training',
    component: <SBSTraining />,
    menuLabel: 'Training',
  },
  [RouteKeys.TEST_YOUR_KNOWLEDGE]: {
    route: '/test-your-knowledge',
    component: <TestYourKnowledge />,
    menuLabel: 'Test Your Knowledge',
  },
  [RouteKeys.CTA]: {
    route: '/schedule',
    component: <CTA />,
  },
};
