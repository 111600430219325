import React, { HTMLAttributes } from 'react'
import { ExamplePatient } from '../../../models/ExamplePatient';
import { DegenerationLevels, ViewTypes, XrayForAnalysis } from '../../../models/ExampleXray';
import { getJoint, sortXrayEntiresByGroupKey } from '../../../helpers/helpers';
import { RouteKeys } from '../../../data/routeKeys';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check_icon.svg'
import { ReactComponent as XIcon } from '../../../assets/icons/x_icon.svg'
import './XrayTable.scss';

type XrayTableProps = {
  flow?: RouteKeys,
  patient: ExamplePatient,
  variant?: 'degeneration' | 'reportCard'
  showFirstSelection?: boolean;
  showAnswerMarkers?: boolean;
} & HTMLAttributes<HTMLDivElement>;

type XrayTableSingleRowProps = {
  label: string;
  correct: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const XrayTableReportCardSingleRow: React.FC<XrayTableSingleRowProps> = ({
  label,
  correct,
  ...props
}) => (
  <div className={`XrayTable__Container ${props.className ? props.className : ''}`}>
    <div className='XrayTable__SectionContainer dt-halfHeight'>
      <div className={`XrayTable__SectionLabelContainer reportCard dt-last dt-fullWidth`}>
        <h4 className='XrayTable__SectionLabel reportCard'>
          {label}
        </h4>
      </div>
      <div className='XrayTable__RowsContainer reportCard'>
        <div className='XrayTable__Row dt-halfHeight'>
          <div className='XrayTable__Cell reportCard dt-fullWidth dt-last'>
            {correct && <CheckIcon className='XrayTable__AnswerMarkerCheck' />}
          </div>
          <div className='XrayTable__Cell reportCard dt-fullWidth dt-last dt-end'>
            {!correct && <XIcon className='XrayTable__AnswerMarkerX' />}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const XrayTable: React.FC<XrayTableProps> = ({
  flow,
  patient,
  showFirstSelection,
  showAnswerMarkers,
  variant = 'degeneration',
  ...props
}) => {
  const isDegVariant = variant === 'degeneration';
  const isReportCardVariant = variant === 'reportCard';

  if (!flow && showFirstSelection) throw new Error('Flow prop is required to render first selection');
  if (!flow && showAnswerMarkers) throw new Error('Flow prop is required to render answer markers');
  if (!flow && isReportCardVariant) throw new Error('Flow prop is required to render Report Card variant');

  const isPatientFlow = flow === RouteKeys.SBS_TRAINING || flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  const joint = getJoint(patient.joint);

  const generateTableData = () => {
    let data: {[key: string]: {[key: string]: XrayForAnalysis}} = {};

    for (const key in patient.xrays.forAnalysis) {
      const xray = patient.xrays.forAnalysis[parseInt(key)];
      if (joint === 'knee') {
        data = {
          ...data,
          [xray.compartment]: {
            ...data[xray.compartment],
            [xray.viewType]: xray,
          }
        };
      } else {
        data = {
          ...data,
          [xray.viewType]: {
            ...data[xray.viewType],
            [xray.compartment]: xray,
          }
        };
      }
    };

    return data;
  };

  const tableData = generateTableData();
  const tableSections = Object.entries(tableData).sort((a, b) => sortXrayEntiresByGroupKey(a, b, joint));

  interface generateCSSClassParams {
    baseClass: string;
    xray?: XrayForAnalysis;
    cellFor?: DegenerationLevels;
    isLast?: boolean;
    isSingleRow?: boolean;
    isEndCell?: boolean;
    isHalfHeight?: boolean;
    isFullWidth?: boolean;
    needsAnswerMarkers?: boolean;
  }

  const generateCSSClass = ({
    baseClass,
    cellFor,
    xray,
    isLast,
    isSingleRow,
    isEndCell,
    isHalfHeight,
    isFullWidth,
    needsAnswerMarkers,
  }: generateCSSClassParams) => {
    const patientFirstSelection = isPatientFlow && xray ? patient[flow].evaluatedXrays[xray.id].firstSelection : null;
    let className = baseClass + ' ';
    if (isLast) className = className + 'dt-last ';
    if (isEndCell) className = className + 'dt-end ';
    if (isSingleRow) className = className + 'dt-fullWidth ';
    if (xray?.degeneration === cellFor) {
      if (cellFor === DegenerationLevels.ENDSTAGE || cellFor === DegenerationLevels.NEAR_ENDSTAGE) className = className + 'error ';
      if (cellFor === DegenerationLevels.MODERATE) className = className + 'warning ';
      if (cellFor === DegenerationLevels.NORMAL) className = className + 'success ';
    }
    if (showFirstSelection && isPatientFlow && (cellFor === patientFirstSelection) && (xray?.degeneration !== patientFirstSelection)) {
      className = className + 'dt-hilight ';
    }
    if (needsAnswerMarkers) className = className + 'dt-showMarkers ';
    if (isHalfHeight) className = className + 'dt-halfHeight ';
    if (isFullWidth) className = className + 'dt-fullWidth ';

    return className;
  };

  return (
    <div 
      {...props}
      className={
        generateCSSClass({
          baseClass: `XrayTable__Container ${props.className ? props.className : ''}`,
          needsAnswerMarkers: showAnswerMarkers,
        })
      }
    >
      {tableSections.map(([sectionLabel, rowData], sectIdx, sectArr) => (
        <div
          key={sectionLabel}
          className={
            generateCSSClass({
              baseClass: 'XrayTable__SectionContainer',
              isHalfHeight: Object.values(rowData).length === 1,
            })
          }
        >
          <div className={
            generateCSSClass({
              baseClass: `XrayTable__SectionLabelContainer ${variant}`,
              isLast: sectIdx === sectArr.length - 1,
              isFullWidth: Object.values(rowData).length === 1,
            })
          }>
            <h4 className={`XrayTable__SectionLabel ${variant}`}>
              {joint === 'knee'
                ? <>{sectionLabel} {sectionLabel === 'kneecap' ? 'view' : 'compartment'}</>
                : <>{sectionLabel === ViewTypes.LATERAL_HIP ? 'Lateral View' : sectionLabel}</>
                }
            </h4>
          </div>
          <div className={`XrayTable__RowsContainer ${variant}`}>
            {Object.entries(rowData).map(([rowLabel, xray], rowIdx, rowArr) => (
              <div
                key={rowLabel}
                className={
                  generateCSSClass({
                    baseClass: 'XrayTable__Row',
                    isHalfHeight: Object.values(rowData).length === 1,
                  })
                }
              >
                {Object.values(rowData).length > 1 && (
                  <div className={
                    generateCSSClass({
                      baseClass: `XrayTable__RowLabelContainer ${variant}`,
                      isLast: (sectIdx === sectArr.length - 1) && (rowIdx === rowArr.length - 1),
                    })
                  }>
                    <h5 className={`XrayTable__RowLabel ${variant}`}>
                      {joint === 'knee' ? rowLabel === ViewTypes.NON_FLEX ? 'Non-flexion View' : `${rowLabel} View` : rowLabel}
                    </h5>
                  </div>
                )}
                {isDegVariant && Object.values(DegenerationLevels).map((degLvl, degIdx, degArr) => (
                  <div
                    key={degLvl}
                    className={
                      generateCSSClass({
                        baseClass: 'XrayTable__Cell',
                        xray,
                        cellFor: degLvl,
                        isLast: (sectIdx === sectArr.length - 1) && (rowIdx === rowArr.length - 1),
                        isSingleRow: Object.values(rowData).length === 1,
                        isEndCell: degIdx === degArr.length - 1,
                        needsAnswerMarkers: showAnswerMarkers,
                      })
                    }
                  >
                    {sectIdx === 0 && rowIdx === 0 && (
                      <p className='XrayTable__CellLabel'>
                        {degLvl}
                      </p>
                    )}
                    {xray.degeneration === degLvl && <CheckIcon className='XrayTable__CheckIcon' />}
                    {showFirstSelection
                      && isPatientFlow
                      && (xray.degeneration !== patient[flow].evaluatedXrays[xray.id].firstSelection)
                      && (patient[flow].evaluatedXrays[xray.id].firstSelection === degLvl)
                      && (
                        <p className='XrayTable__FirstSelectionLabel'>
                          Your First Selection
                        </p>
                      )
                    }
                  </div>
                ))}
                {isReportCardVariant && (
                  <>
                    <div
                      className={
                        generateCSSClass({
                          baseClass: 'XrayTable__Cell reportCard',
                          isLast: (sectIdx === sectArr.length - 1) && (rowIdx === rowArr.length - 1),
                          isSingleRow: Object.values(rowData).length === 1,
                        })
                      }
                    >
                      {sectIdx === 0 && rowIdx === 0 && (
                        <p className='XrayTable__CellLabel'>
                          Correct
                        </p>
                      )}
                      {isPatientFlow && xray.degeneration === patient[flow].evaluatedXrays[xray.id].firstSelection && (
                        <CheckIcon className='XrayTable__AnswerMarkerCheck' />
                      )}
                    </div>
                    <div
                    className={
                      generateCSSClass({
                        baseClass: 'XrayTable__Cell reportCard',
                        isLast: (sectIdx === sectArr.length - 1) && (rowIdx === rowArr.length - 1),
                        isSingleRow: Object.values(rowData).length === 1,
                        isEndCell: true,
                      })
                    }
                  >
                    {sectIdx === 0 && rowIdx === 0 && (
                      <p className='XrayTable__CellLabel'>
                        Incorrect
                      </p>
                    )}
                    {isPatientFlow && xray.degeneration !== patient[flow].evaluatedXrays[xray.id].firstSelection && (
                      <XIcon className='XrayTable__AnswerMarkerX' />
                    )}
                  </div>
                  </>
                )}
                {isDegVariant && showAnswerMarkers && isPatientFlow && (
                  <div className='XrayTable__AnswerMarkerContainer'>
                    {
                      patient[flow].evaluatedXrays[xray.id].firstSelection === xray.degeneration
                        ? <CheckIcon className='XrayTable__AnswerMarkerCheck' />
                        : <XIcon className='XrayTable__AnswerMarkerX' />
                    }
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default XrayTable;
