import React, { HTMLAttributes } from 'react';
import './ImageShowcaseFrame.scss';

type ImageShowcaseFrameProps = {
  imgSrc?: string;
  label?: string;
  imgStyles?: React.CSSProperties;
} & HTMLAttributes<HTMLDivElement>;

const ImageShowcaseFrame: React.FC<ImageShowcaseFrameProps> = ({
  imgSrc,
  label,
  imgStyles,
  ...props
}) => (
  <div {...props} className={`ImageShowcaseFrame__Container ${props.className ? props.className : ''}`}>
    {label && (
      <div className='ImageShowcaseFrame__LabelContainer'>
        <p className='ImageShowcaseFrame__Label'>{label}</p>
      </div>
    )}
    <div className={`ImageShowcaseFrame__ImgContainer ${!label ? 'no-label' : ''} ${!imgSrc ? 'isf-transparent' : ''}`}>
      {imgSrc && <img style={imgStyles ? imgStyles : {}} className='ImageShowcaseFrame__Img' src={imgSrc} alt={label || ''} />}
    </div>
  </div>
);

export default ImageShowcaseFrame;
