import React, { useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { RouteKeys } from '../../../data/routeKeys';
import { Surface } from '../../atoms/Surface';
import { StepProps } from '../../../models/StepProps';
import { Checkbox } from '../../atoms/Checkbox';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check_icon.svg';
import { ReactComponent as XIcon } from '../../../assets/icons/x_icon.svg';
import { ReactComponent as MissedIcon } from '../../../assets/icons/missed_icon.svg';
import { examplePatientsMap } from '../../../data/examplePatients';
import BilatKnee from '../../../assets/educationAssets/XraySelectionStep/bilat_kneecap.jpg';
import './XraySelectionStep.scss';

// corresponding page in app:
// https://education.jointai.co/knee-education/request-xrays

const XraySelectionStep: React.FC<StepProps> = ({ flow }) => {
  const {
    prevStep,
    nextStep,
  } = useGlobalState({ flow });
  
  const {
    eduPageContainerClassName,
    eduContentContainerClassName,
  } = useLayoutState({
    eduPageContainerBaseClassName: 'XraySelectionStep__Page',
    eduContentContainerBaseClassName: 'XraySelectionStep__Container',
  });

  const joint = flow === RouteKeys.HIP_EDU ? 'hip' : 'knee';

  const patients = [
    {
      bio: (
        <>
          A {joint === 'knee' ? '64' : '60'}-year-old male patient states that he has <span style={{ fontWeight: 600 }}>{joint === 'knee' ? 'left' : 'right'} {joint} pain</span>.
          Which X-rays are needed to evaluate this patient for joint degeneration? {' '}
          <span style={{ fontWeight: 600 }}>Click on all boxes that apply</span>.
        </>
      ),
      jointSide: joint === 'knee' ? 'left' : 'right',
    },
    {
      bio: (
        <>
          A {joint === 'knee' ? '70' : '73'}-year-old female patient states that she has <span style={{ fontWeight: 600 }}>right and left {joint} pain</span>.
          Which X-rays are needed to evaluate this patient for joint degeneration? {' '}
          <span style={{ fontWeight: 600 }}>Click on all boxes that apply</span>.
        </>
      ),
      jointSide: 'both',
    },
  ];

  const [activePatientIdx, setActivePatientIdx] = useState(0);
  const [showBio, setShowBio] = useState<boolean>(true);
  const activePatient = patients[activePatientIdx];

  const {
    aLee,
    jDoina,
    rSmith,
    jPotter,
    aBrown,
  } = examplePatientsMap;

  interface XrayCheckboxAttributes {
    key: string;
    label: string;
    required: boolean;
    img?: string;
  }

  interface XraySelectionOptsType {
    [key: string]: {
      [key: string]: XrayCheckboxAttributes;
    }
  }

  const xraySelectionOpts: XraySelectionOptsType = {
    knee: {
      flex: {
        key: 'flex',
        label: 'Bilateral Standing PA with Flexion',
        required: true,
        img: aLee.xrays.forReview[0].src,
      },
      nonFlex: {
        key: 'nonFlex',
        label: 'Bilateral Standing PA with no Flexion',
        required: true,
        img: aLee.xrays.forReview[1].src,
      },
      bilateralKneecap: {
        key: 'bilateralKneecap',
        label: 'Bilateral Kneecap',
        required: activePatient.jointSide === 'both',
        img: BilatKnee,
      },
      rightKneecap: {
        key: 'rightKneecap',
        label: 'Right Kneecap',
        required: activePatient.jointSide === 'right',
        img: aLee.xrays.forReview[2].src,
      },
      rightLateral: {
        key: 'rightLateral',
        label: 'Right Lateral',
        required: activePatient.jointSide === 'right' || activePatient.jointSide === 'both',
        img: rSmith.xrays.forReview[3].src,
      },
      leftKneecap: {
        key: 'leftKneecap',
        label: 'Left Kneecap',
        required: activePatient.jointSide === 'left',
        img: jDoina.xrays.forReview[2].src,
      },
      leftLateral: {
        key: 'leftLateral',
        label: 'Left Lateral',
        required: activePatient.jointSide === 'left' || activePatient.jointSide === 'both',
        img: aLee.xrays.forReview[3].src,
      },
    },
    hip: {
      apPelvis: {
        key: 'apPelvis',
        label: 'AP Pelvis',
        required: true,
        img: jPotter.xrays.forReview[0].src,
      },
      rightLateral: {
        key: 'rightLateral',
        label: 'Right Lateral',
        required: activePatient.jointSide === 'right' || activePatient.jointSide === 'both',
        img: jPotter.xrays.forReview[1].src,
      },
      leftLateral: {
        key: 'leftLateral',
        label: 'Left Lateral',
        required: activePatient.jointSide === 'left' || activePatient.jointSide === 'both',
        img: aBrown.xrays.forReview[1].src,
      },
    },
  };

  const selectedOptsInit = Object.keys(xraySelectionOpts[joint]).reduce((obj, xray) => {
    obj[xray] = false;
    return obj;
  }, {} as {[ key: string ]: boolean})

  const [showCorrectSelections, setShowCorrectSelections] = useState<boolean>(false);
  const [selectedOpts, setSelectedOpts] = useState<{[ key: string ]: boolean}>(selectedOptsInit);

  const resetState = () => {
    setSelectedOpts(selectedOptsInit);
    setShowCorrectSelections(false);
  };

  const subheadTransDurMS = 500;

  const handleBioTransition = (direction: 'forward' | 'back') => {
    setTimeout(() => {
      resetState();
      setActivePatientIdx(direction === 'forward' ? activePatientIdx + 1 : activePatientIdx - 1);
      setShowBio(true);
    }, subheadTransDurMS);
    setShowBio(false);
  };

  const next = () => {
    if (showCorrectSelections) {
      if (activePatientIdx === patients.length - 1) return nextStep();
      handleBioTransition('forward');
      return;
    };
    setShowCorrectSelections(true);
  };

  const getNextDisabledState = () => !(Object.values(selectedOpts).find((opt) => !!opt));

  const prev = () => {
    if (activePatientIdx === 0) return prevStep();
    handleBioTransition('back');
  };

  const incorrectSelectionMade = showCorrectSelections
    && Object.entries(selectedOpts).find(([key, isSelected]) => (
      (isSelected && !xraySelectionOpts[joint!][key].required)
      || (!isSelected && xraySelectionOpts[joint!][key].required)
    ));

  return (
    <div className={eduPageContainerClassName}>
      <div className={eduContentContainerClassName}>
        <div className='XraySelectionStep__HeaderContainer'>
          <h3 className='XraySelectionStep__Header'>
            What X-rays would you request?
          </h3>
          <p
            className={`XraySelectionStep__SubHeader ${showBio ? 'xss-visible' : ''}`}
            style={{ transition: `all ${subheadTransDurMS}ms ease` }}
          >
            {activePatient.bio}
          </p>
        </div>
        <Surface className='XraySelectionStep__ContentContainer'>
          {Object.values(xraySelectionOpts[joint!]).map((opt) => (
            <div key={opt.key} className={`XraySelectionStep__SelectionContainer xss-${joint}`}>
              <div className='XraySelectionStep__CheckboxContainer'>
                <Checkbox
                  centerToParent
                  size='small'
                  isSelected={selectedOpts[opt.key]}
                  isDisabled={showCorrectSelections}
                  imgSrc={opt.img}
                  imgClass='XraySelectionStep__XrayImg'
                  iconLabel={
                    <div className={`XraySelectionStep__IconContainer ${showCorrectSelections && ((opt.required && selectedOpts[opt.key]) || (!opt.required && selectedOpts[opt.key]) || (opt.required && !selectedOpts[opt.key])) ? 'rxss-with-icon' : ''}`}>
                      <CheckIcon
                        className={`XraySelectionStep__Icon rxss-success ${showCorrectSelections && opt.required && selectedOpts[opt.key] ? 'rxss-visible' : ''}`}
                      />
                      <XIcon
                        className={`XraySelectionStep__Icon rxss-error ${showCorrectSelections && !opt.required && selectedOpts[opt.key] ? 'rxss-visible' : ''}`}
                      />
                      <MissedIcon
                        className={`XraySelectionStep__Icon rxss-missed ${showCorrectSelections && opt.required && !selectedOpts[opt.key] ? 'rxss-visible' : ''}`}
                      />
                    </div>
                  }
                  checkboxContainerClass={`XraySelectionStep__CheckboxContainer ${showCorrectSelections && opt.required ? 'rxss-hilight' : ''} ${showCorrectSelections && !opt.required && selectedOpts[opt.key] ? 'rxss-hilight-error' : ''}`}
                  onChange={
                    selectedOpts[opt.key]
                      ? () => setSelectedOpts({ ...selectedOpts, [opt.key]: false })
                      : () => setSelectedOpts({ ...selectedOpts, [opt.key]: true })
                  }
                >
                  <span className='XraySelectionStep__CheckboxLabel'>{opt.label}</span>
                </Checkbox>
              </div>
            </div>
          ))}
        </Surface>
      </div>
      <NavigationFooter
        prev={{ action: prev }}
        centerElement={(
          <>
            {
              incorrectSelectionMade && (
                <h3 className='ReviewXraysSubStep__SelectionResult'>Incorrect</h3>
              )
            }
          </>
        )}
        next={{
          action: next,
          label: showCorrectSelections
            ? 'Next'
            : 'Confirm',
          disabled: getNextDisabledState(),
        }}
      />
    </div>
  );
};

export default XraySelectionStep;
