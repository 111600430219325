import React, { useState } from 'react';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { CornerTag } from '../../atoms/CornerTag';
import { ImageShowcaseFrame } from '../../molecules/ImageShowcaseFrame';
import { Button } from '../../atoms/Button';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { DegenerationLevels, XrayForAnalysis } from '../../../models/ExampleXray';
import { comparisonXrays } from '../../../data/comparisonXrays';
import { getJoint, getJointSide } from '../../../helpers/helpers';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import { ExamplePatient } from '../../../models/ExamplePatient';
import { XrayMatchingTutorialModal } from '../XrayMatchingTutorialModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './XrayEvaluationSubStep.scss';

// patient flow step 5 - the user is asked to compare the patient's xrays against example
// xrays for each level of degeneration, and determine what that compartment's degeneration
// level is

// this component is also used on this page in the education flow:
// https://education.jointai.co/knee-education/xray-matching

interface XrayEvaluationSubStepProps extends StepProps {
  patient: ExamplePatient;
  patientNumber?: number | null;
  xray: XrayForAnalysis;
  header?: string;
  hidePatientTag?: boolean;
  handleSubmitAnswer?(args?: any): void;
  handleClickNext(args?: any): void;
  showModal?: boolean;
  back?(): void;
  showHelpButton?: boolean;
}

const XrayEvaluationSubStep: React.FC<XrayEvaluationSubStepProps> = ({
  flow,
  patient,
  patientNumber,
  xray,
  header,
  showModal: showModalFromParent,
  hidePatientTag,
  handleSubmitAnswer,
  handleClickNext,
  back,
  showHelpButton,
}) => {
  const {
    eduPageContainerClassName,
    eduContentContainerClassName,
  } = useLayoutState({
    eduPageContainerBaseClassName: 'XrayEvaluationSubStep__EduPage',
    eduContentContainerBaseClassName: 'XrayEvaluationSubStep__EduContainer',
  });

  const jointSide = getJointSide(patient.joint);
  const joint = getJoint(patient.joint);

  const degOpts = Object.values(DegenerationLevels);

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;
  const isPatientFlow = isTrainingFlow || isTestingFlow;

  const evaluationIsComplete = isPatientFlow ? patient[flow].evaluatedXrays[xray.id]?.complete : false;

  const [selectedOpt, setSelectedOpt] = useState<DegenerationLevels | null>(evaluationIsComplete ? xray.degeneration : null);
  const [showResult, setShowResult] = useState<boolean>(evaluationIsComplete);
  const [showModal, setShowModal] = useState<boolean>(!!showModalFromParent);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const answerIsCorrect = selectedOpt === xray.degeneration;

  const handleOptionClick = (opt: DegenerationLevels) => {
    if (showResult && answerIsCorrect) return;
    if (showResult && !answerIsCorrect) setShowResult(false);
    setSelectedOpt(opt);
  };

  const handleSubmitClick = () => {
    if (!selectedOpt) return;
    if (handleSubmitAnswer) handleSubmitAnswer(selectedOpt);
    setShowResult(true);
  };

  return (
    <>
      {showModal && (
        <XrayMatchingTutorialModal
          joint={joint!}
          closeModal={closeModal}
        />
      )}
      <div className={isPatientFlow ? 'XrayEvaluationSubStep__Page' : eduPageContainerClassName}>
        {!hidePatientTag && (
          <CornerTag>
            {isTrainingFlow && patient.name}
            {isTestingFlow && patientNumber && <>Patient {patientNumber}</>}
          </CornerTag>
        )}
        <div className={isPatientFlow ? 'XrayEvaluationSubStep__Container' : eduContentContainerClassName}>
          <h3 className={`XrayEvaluationSubStep__Header ${!isPatientFlow ? 'xess-edu' : ''}`}>
            {header || 'X-ray Evaluation'}
          </h3>
          <div className='XrayEvaluationSubStep__MainContent'>
            <div className='XrayEvaluationSubStep__MainContentL'>
              <h4 className='XrayEvaluationSubStep__SubHeader'>
                What level of degeneration do you see in the PATIENT'S X-RAY?
              </h4>
              <div className='XrayEvaluationSubStep__AnswerOptsContainer'>
                {degOpts.map((opt) => (
                  <Button
                    key={opt}
                    onPress={() => handleOptionClick(opt)}
                    selected={selectedOpt === opt}
                    variant="mixed"
                    fullWidth
                  >
                    {opt}
                  </Button>
                ))}
              </div>
              <div className='XrayEvaluationSubStep__SubmitBtnContainer'>
                {!showResult && (
                  <Button
                    onPress={handleSubmitClick}
                    isDisabled={!selectedOpt}
                    dropShadow
                  >
                    Submit Evaluation
                  </Button>
                )}
                {showResult && answerIsCorrect && (
                  <>
                    <h3 className='correct'>Correct!</h3>
                    <Button dropShadow onPress={handleClickNext}>Next</Button>
                  </>
                )}
                {showResult && !answerIsCorrect && (
                  <h3 className='incorrect'>Try again.</h3>
                )}
              </div>
            </div>
            <div className='XrayEvaluationSubStep__MainContentR'>
              <div className='XrayEvaluationSubStep__XraysContainer'>
                <div className='XrayEvaluationSubStep__XraysLabelContainer'>
                  <p className='XrayEvaluationSubStep__XraysLabel uppercase'>
                    {patient.joint} - {xray.viewType}
                  </p>
                  <p className='XrayEvaluationSubStep__XraysLabel'>
                    {jointSide} {xray.compartment}
                  </p>
                  {showHelpButton && (
                    <FontAwesomeIcon
                      className='XrayEvaluationSubStep__TutorialBtn'
                      onClick={openModal}
                      onKeyDown={openModal}
                      icon={faQuestionCircle}
                      role='button'
                      tabIndex={0}
                      title='View Tutorial'
                    />
                  )}
                </div>
                <ImageShowcaseFrame
                  className={`XrayEvaluationSubStep__ImageShowcaseFrame ${!isPatientFlow ? 'xess-edu' : ''}`}
                  label="PATIENT'S X-RAY"
                  imgSrc={xray.src}
                />
                <ImageShowcaseFrame
                  className={`XrayEvaluationSubStep__ImageShowcaseFrame ${!isPatientFlow ? 'xess-edu' : ''}`}
                  label="COMPARISON X-RAY"
                  imgStyles={patient.joint === 'left hip' ? { transform: 'scaleX(-1)' } : {}}
                  imgSrc={selectedOpt ? comparisonXrays[jointSide!][xray.viewType][xray.compartment][selectedOpt] : null}
                />
              </div>
            </div>
          </div>
        </div>
        {back && (
          <NavigationFooter
            style={{ zIndex: 0 }}
            prev={{ action: back }}
          />
        )}
      </div>
    </>
  );
};

export default XrayEvaluationSubStep;
