import { EducationCompleteStep } from "../components/organisms/EducationCompleteStep";
import { IdentifyJointCompartmentsStep } from "../components/organisms/IdentifyJointCompartmentsStep";
import { JointSpaceNarrowingStep } from "../components/organisms/JointSpaceNarrowingStep";
import { VideoShowcaseStep } from "../components/organisms/VideoShowcaseStep";
import { XrayMatchingStep } from "../components/organisms/XrayMatchingStep";
import { XraySelectionStep } from "../components/organisms/XraySelectionStep";
import { FlowMapType } from "../models/Flows";
import { EducationFlowStepKeys } from "./educationFlowKeys";

// education flow steps in order

export const educationFlowMap: FlowMapType = {
  [EducationFlowStepKeys.JointArthritisEduVideo]: {
    key: EducationFlowStepKeys.JointArthritisEduVideo,
    renderComponent: (props) => <VideoShowcaseStep {...props} />,
    route: `/${EducationFlowStepKeys.JointArthritisEduVideo}`,
    generateNavLabel: (joint) => `${joint} Arthritis Education`,
  },
  [EducationFlowStepKeys.IdentifyJointCompartments]: {
    key: EducationFlowStepKeys.IdentifyJointCompartments,
    renderComponent: (props) => <IdentifyJointCompartmentsStep {...props} />,
    route: `/${EducationFlowStepKeys.IdentifyJointCompartments}`,
    generateNavLabel: (joint) => `Learn About ${joint} ${joint === 'hip' ? 'Zones' : 'Compartments'}`,
  },
  [EducationFlowStepKeys.XraySelectionVideo]: {
    key: EducationFlowStepKeys.XraySelectionVideo,
    renderComponent: (props) => <VideoShowcaseStep {...props} />,
    route: `/${EducationFlowStepKeys.XraySelectionVideo}`,
    generateNavLabel: () => 'How to Select X-ray Views',
  },
  [EducationFlowStepKeys.XraySelection]: {
    key: EducationFlowStepKeys.XraySelection,
    renderComponent: (props) => <XraySelectionStep {...props} />,
    route: `/${EducationFlowStepKeys.XraySelection}`,
    generateNavLabel: () => 'X-ray Selection Exercise',
  },
  [EducationFlowStepKeys.JointArthritisEvalVideo]: {
    key: EducationFlowStepKeys.JointArthritisEvalVideo,
    renderComponent: (props) => <VideoShowcaseStep {...props} />,
    route: `/${EducationFlowStepKeys.JointArthritisEvalVideo}`,
    generateNavLabel: () => 'Evaluating Arthritis with X-rays',
  },
  [EducationFlowStepKeys.JointSpaceNarrowing]: {
    key: EducationFlowStepKeys.JointSpaceNarrowing,
    renderComponent: (props) => <JointSpaceNarrowingStep {...props} />,
    route: `/${EducationFlowStepKeys.JointSpaceNarrowing}`,
    generateNavLabel: () => 'Joint Space Narrowing Exercise',
  },
  [EducationFlowStepKeys.ConfirmingEvaluations]: {
    key: EducationFlowStepKeys.ConfirmingEvaluations,
    renderComponent: (props) => <VideoShowcaseStep {...props} />,
    route: `/${EducationFlowStepKeys.ConfirmingEvaluations}`,
    generateNavLabel: (joint) => `Confirming ${joint} X-ray Evaluations`,
  },
  [EducationFlowStepKeys.XrayMatching]: {
    key: EducationFlowStepKeys.XrayMatching,
    renderComponent: (props) => <XrayMatchingStep {...props} />,
    route: `/${EducationFlowStepKeys.XrayMatching}`,
    generateNavLabel: () => 'X-ray Matching Exercise',
  },
  [EducationFlowStepKeys.SampleEvalVideo]: {
    key: EducationFlowStepKeys.SampleEvalVideo,
    renderComponent: (props) => <VideoShowcaseStep {...props} />,
    route: `/${EducationFlowStepKeys.SampleEvalVideo}`,
    generateNavLabel: (joint) => `Sample ${joint} Evaluation`,
  },
  [EducationFlowStepKeys.CareRecommendationVideo]: {
    key: EducationFlowStepKeys.CareRecommendationVideo,
    renderComponent: (props) => <VideoShowcaseStep {...props} />,
    route: `/${EducationFlowStepKeys.CareRecommendationVideo}`,
    generateNavLabel: () => 'Recommending a Care Pathway',
  },
  [EducationFlowStepKeys.Complete]: {
    key:EducationFlowStepKeys.Complete,
    renderComponent: (props) => <EducationCompleteStep {...props} />,
    route: `/${EducationFlowStepKeys.Complete}`,
  },
};
