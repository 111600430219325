import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PDF.scss';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

interface PDFProps {
  src: string;
}

const PDF: React.FC<PDFProps> = ({ src }) => {
  const [numPages, setNumPages] = useState<number>();
  
  return (
    <div className='PDF__container'>
      <div className='PDF__container__document'>
        <Document
          file={src}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          options={options}
          
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PDF;
