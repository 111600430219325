import React, { useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CornerTag } from '../../atoms/CornerTag';
import { Checkbox } from '../../atoms/Checkbox';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { XrayTile } from '../../molecules/XrayTile';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import { getJoint, getJointSide } from '../../../helpers/helpers';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check_icon.svg';
import { ReactComponent as XIcon } from '../../../assets/icons/x_icon.svg';
import { ReactComponent as MissedIcon } from '../../../assets/icons/missed_icon.svg';
import { ReactComponent as LeftHip } from '../../../assets/images/LEFTHIPSKELETON.svg';
import { ReactComponent as RightHip } from '../../../assets/images/RIGHTHIPSKELETON.svg';
import { ReactComponent as LeftKnee } from '../../../assets/images/LEFTKNEESKELETON.svg';
import { ReactComponent as RightKnee } from '../../../assets/images/RIGHKNEESKELETON.svg';
import './ReviewXraysSubStep.scss';

// patient flow step 5 - reviewing the patient's xrays
// in the training flow, the user is only prompted to visually examine each xray image
// in the testing flow, the user is first prompted to select the xrays that the exam should
// require, and then visually examine them

const ReviewXraysSubStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    completeActiveSubStep,
    closeActiveSubStep,
    updatePatientSelectedXrays,
    lockInPatientSelectedXrays,
  } = useGlobalState({ flow });

  const jointSide = getJointSide(activePatient?.joint);
  const joint = getJoint(activePatient?.joint);

  const lockedInXraySelections = activePatient![RouteKeys.TEST_YOUR_KNOWLEDGE].selectedXrays;
  const selectionsAreLocked = lockedInXraySelections && !!Object.values(lockedInXraySelections).length;

  enum ReviewXraysSubStepScreens {
    SELECTION = 'selection',
    REVIEW = 'review',
  };

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  interface XrayRadioAttributes {
    key: string;
    label: string;
    required: boolean;
  }

  interface XraySelectionOpts {
    [key: string]: {
      [key: string]: XrayRadioAttributes;
    }
  }

  const xraySelectionOpts: XraySelectionOpts = {
    knee: {
      flex: {
        key: 'flex',
        label: 'Bilateral Standing PA with Flexion',
        required: true,
      },
      nonFlex: {
        key: 'nonFlex',
        label: 'Bilateral Standing PA with no Flexion',
        required: true,
      },
      bilateralKneecap: {
        key: 'bilateralKneecap',
        label: 'Bilateral Kneecap',
        required: false,
      },
      rightKneecap: {
        key: 'rightKneecap',
        label: 'Right Kneecap',
        required: jointSide === 'right',
      },
      rightLateral: {
        key: 'rightLateral',
        label: 'Right Lateral',
        required: jointSide === 'right',
      },
      leftKneecap: {
        key: 'leftKneecap',
        label: 'Left Kneecap',
        required: jointSide === 'left',
      },
      leftLateral: {
        key: 'leftLateral',
        label: 'Left Lateral',
        required: jointSide === 'left',
      },
    },
    hip: {
      apPelvis: {
        key: 'apPelvis',
        label: 'AP Pelvis',
        required: true,
      },
      rightLateral: {
        key: 'rightLateral',
        label: 'Right Lateral',
        required: jointSide === 'right',
      },
      leftLateral: {
        key: 'leftLateral',
        label: 'Left Lateral',
        required: jointSide === 'left',
      },
    },
  };

  const [activeScreen, setActiveScreen] = useState<ReviewXraysSubStepScreens>(
    isTrainingFlow
      ? ReviewXraysSubStepScreens.REVIEW
      : ReviewXraysSubStepScreens.SELECTION
  );
  const [showCorrectSelections, setShowCorrectSelections] = useState<boolean>(selectionsAreLocked);
  const [selectedOpts, setSelectedOpts] = useState<{ [key: string]: boolean }>(
    selectionsAreLocked
      ? lockedInXraySelections
      : Object.keys(xraySelectionOpts[joint!]).reduce((obj, xray) => {
        obj[xray] = false;
        return obj;
      }, {} as { [key: string]: boolean })
  );

  const incorrectSelectionMade = showCorrectSelections &&
    Object.entries(selectedOpts).find(([key, isSelected]) => (
      (isSelected && !xraySelectionOpts[joint!][key].required)
      || (!isSelected && xraySelectionOpts[joint!][key].required)
    ));

  const next = () => {
    if (activeScreen === ReviewXraysSubStepScreens.SELECTION) {
      if (showCorrectSelections) return setActiveScreen(ReviewXraysSubStepScreens.REVIEW);
      lockInPatientSelectedXrays(selectedOpts);
      setShowCorrectSelections(true);
      return;
    }
    return completeActiveSubStep();
  };

  const getNextDisabledState = () => {
    if (activeScreen === ReviewXraysSubStepScreens.SELECTION) {
      return !(Object.values(selectedOpts).find((opt) => !!opt));
    }
    return false;
  }

  const prev = () => activeScreen === ReviewXraysSubStepScreens.SELECTION
    ? closeActiveSubStep()
    : isTestingFlow
      ? setActiveScreen(ReviewXraysSubStepScreens.SELECTION)
      : completeActiveSubStep();

  if (!activePatient || (!isTrainingFlow && !isTestingFlow)) return null;

  return (
    <div className='ReviewXraysSubStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient?.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='ReviewXraysSubStep__Container'>

        <div className="ReviewXraysSubStep__ReviewContainer">
          {activeScreen === ReviewXraysSubStepScreens.REVIEW && (
            <div className='ReviewXraysSubStep__XrayContainer'>
              {activePatient.xrays.forReview.map((xray) => (
                <XrayTile
                  clickToZoom
                  key={xray.id}
                  src={xray.src}
                  label={xray.viewType}
                  className='ReviewXraysSubStep__XrayTile'
                />
              ))}
            </div>
          )}
        </div>

        {activeScreen === ReviewXraysSubStepScreens.SELECTION && (
          <>
            <h3 className='ReviewXraysSubStep__Header'>
              Review X-rays
            </h3>
            <p className='ReviewXraysSubStep__SubHeader'>
              Select the X-rays that the patient will need for their evaluation.
            </p>
            <div className='ReviewXraysSubStep__MainContent'>
              <div className='ReviewXraysSubStep__MainContentL'>
                {Object.values(xraySelectionOpts[joint!]).map((opt) => (
                  <div key={opt.key} className='ReviewXraysSubStep__SelectionContainer'>
                    <div className={`ReviewXraysSubStep__IconContainer ${showCorrectSelections ? 'rxss-with-icon' : ''}`}>
                      <CheckIcon
                        className={`ReviewXraysSubStep__Icon rxss-success ${showCorrectSelections && opt.required && (selectionsAreLocked ? lockedInXraySelections[opt.key] : selectedOpts[opt.key]) ? 'rxss-visible' : ''}`}
                      />
                      <XIcon
                        className={`ReviewXraysSubStep__Icon rxss-error ${showCorrectSelections && !opt.required && (selectionsAreLocked ? lockedInXraySelections[opt.key] : selectedOpts[opt.key]) ? 'rxss-visible' : ''}`}
                      />
                      <MissedIcon
                        className={`ReviewXraysSubStep__Icon rxss-missed ${showCorrectSelections && opt.required && (selectionsAreLocked ? !lockedInXraySelections[opt.key] : !selectedOpts[opt.key]) ? 'rxss-visible' : ''}`}
                      />
                    </div>
                    <div className={`ReviewXraysSubStep__CheckboxContainer ${showCorrectSelections && opt.required ? 'rxss-hilight' : ''} ${showCorrectSelections && !opt.required && lockedInXraySelections[opt.key] ? 'rxss-hilight-error' : ''}`}>
                      <Checkbox
                        isSelected={selectionsAreLocked ? lockedInXraySelections[opt.key] : selectedOpts[opt.key]}
                        isDisabled={selectionsAreLocked}
                        onChange={
                          // TODO: move this logic into a helper
                          activePatient[RouteKeys.TEST_YOUR_KNOWLEDGE].reviewXrays
                            ? () => { }
                            : selectionsAreLocked
                              ? () => updatePatientSelectedXrays(opt.key)
                              : selectedOpts[opt.key]
                                ? () => setSelectedOpts({ ...selectedOpts, [opt.key]: false })
                                : () => setSelectedOpts({ ...selectedOpts, [opt.key]: true })
                        }
                      >
                        <span className='ReviewXraysSubStep__CheckboxLabel'>{opt.label}</span>
                      </Checkbox>
                    </div>
                  </div>
                ))}
              </div>
              <div className='ReviewXraysSubStep__MainContentR'>
                {activePatient.joint === 'right knee' && <RightKnee />}
                {activePatient.joint === 'left knee' && <LeftKnee />}
                {activePatient.joint === 'right hip' && <RightHip />}
                {activePatient.joint === 'left hip' && <LeftHip />}
              </div>
            </div>
          </>
        )}
      </div>
      <NavigationFooter
        style={{ zIndex: '3' }}
        prev={{ action: prev }}
        centerElement={(
          <>
            {incorrectSelectionMade
              && activeScreen === ReviewXraysSubStepScreens.SELECTION
              && (
                <h3 className='ReviewXraysSubStep__SelectionResult'>Incorrect</h3>
              )
            }
          </>
        )}
        next={{
          action: next,
          // TODO: move this logic into a helper
          label: activeScreen === ReviewXraysSubStepScreens.REVIEW
            ? 'Next'
            : showCorrectSelections ? 'View X-rays' : 'Confirm',
          disabled: getNextDisabledState(),
        }}
      />
    </div>
  );
};

export default ReviewXraysSubStep;
