import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Button } from '../../atoms/Button';
import { routes } from '../../../data/routes';
import { RouteKeys } from '../../../data/routeKeys';
import { educationFlowMap } from '../../../data/educationFlow';
import { ReactComponent as LegsImage } from '../../../assets/images/skeleton_legs.svg';
import { ReactComponent as JointIqLogo } from '../../../assets/logos/jointiQ logo.svg';
import './Home.scss';

// the app landing page
const Home: React.FC = () => {
  const navigate = useNavigate();

  const { setActiveStep } = useGlobalState();

  useEffect(() => setActiveStep(null), [setActiveStep]);

  return (
    <div className='Home__Page'>
      <div className='Home__Left'>
        <JointIqLogo className='Home__Logo' />
        <h3 className='Home__SubHeader'>Education and Training</h3>
        <div className='Home__Content'>
          <div className='Home__Buttons'>
            <div className='Home__Buttons__Primary'>
              <Button
                dropShadow
                className='Home__Button__Primary'
                onPress={() => navigate(`${routes[RouteKeys.HIP_EDU].route}${Object.values(educationFlowMap)[0].route}`)}
              >
                Hip Education
              </Button>
              <Button
                dropShadow
                className='Home__Button__Primary'
                onPress={() => navigate(`${routes[RouteKeys.KNEE_EDU].route}${Object.values(educationFlowMap)[0].route}`)}
              >
                Knee Education
              </Button>
            </div>
            <div className='Home__Buttons__Secondary'>
              <Button
                fullWidth
                dropShadow
                variant='secondary'
                onPress={() => navigate(routes[RouteKeys.SBS_TRAINING].route)}
              >
                <span style={{ textTransform: 'none' }}>Joint iQ Training</span>
              </Button>
              <Button
                fullWidth
                dropShadow
                variant='secondary'
                onPress={() => navigate(routes[RouteKeys.TEST_YOUR_KNOWLEDGE].route)}
              >
                Test Your Knowledge
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='Home__Right'>
        <LegsImage className='Home__LegsImg' />
        <Button
          dropShadow
          variant='gradient'
          onClick={() => navigate(routes[RouteKeys.CTA].route)}
        >
          Try JointDx Now!
        </Button>
      </div>
    </div>
  );
};

export default Home;
