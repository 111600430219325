import React, { ButtonHTMLAttributes, ElementType, useRef } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';
import './Button.scss';

type ButtonProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
  variant?: 'primary' | 'secondary' | 'mixed' | 'gradient';
  dropShadow?: boolean;
  icon?: React.ReactNode;
  selected?: boolean;
  pulse?: boolean; // only supported in primary and secondary variants
} & AriaButtonOptions<ElementType> & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    fullWidth,
    dropShadow,
    icon,
    selected,
    pulse,
    variant = 'primary',
  } = props;

  const ref = useRef(null);

  const { buttonProps } = useButton(props, ref);
  
  return (
    <button
      {...buttonProps}
      ref={ref}
      className={`Button__Container ${pulse ? 'btn-pulse' : ''} ${variant} ${selected ? 'selected' : ''} ${dropShadow ? 'drop-shadow' : ''} ${fullWidth ? 'full-width' : ''} ${icon ? 'icon' : ''} ${props.className ? props.className : ''}`}
    >
      {variant === 'gradient' && <div className='Button__BG' />}
      <>
        <div className={`Button__IconContainer ${icon ? 'b-icon-visible' : ''}`}>{icon}</div>
        <span className={`Button__TextContainer ${icon ? 'b-icon-visible' : ''}`}>{children}</span>
      </>
    </button>
  );
};

export default Button;
