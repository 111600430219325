import React, { HTMLAttributes } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { getJoint, getJointSide } from '../../../helpers/helpers';
import { ReactComponent as LHip } from '../../../assets/images/skeleton_legs_lhip.svg';
import { ReactComponent as RHip } from '../../../assets/images/skeleton_legs_rhip.svg';
import { ReactComponent as LKnee } from '../../../assets/images/skeleton_legs_lknee.svg';
import { ReactComponent as RKnee } from '../../../assets/images/skeleton_legs_rknee.svg';
import { ReactComponent as SkeletonImg } from '../../../assets/images/skeleton_legs.svg';
import './DynamicJointIndicator.scss';

type DynamicJointIndicatorProps = {
  hideBg?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const DynamicJointIndicator: React.FC<DynamicJointIndicatorProps> = ({
  hideBg,
  ...props
}) => {
  const { activePatient } = useGlobalState();

  const jointSide = getJointSide(activePatient?.joint);
  const joint = getJoint(activePatient?.joint);

  return (
    <div {...props} className={`DynamicJointIndicator__Container  ${hideBg ? 'hide-bg' : ''} ${props.className ? props.className : ''}`}>
      <SkeletonImg className='DynamicJointIndicator__SkeletonImg dji-visible dji-bg' />
      <LHip className={`DynamicJointIndicator__SkeletonImg ${joint === 'hip' && jointSide === 'left' ? 'dji-visible' : ''}`} />
      <RHip className={`DynamicJointIndicator__SkeletonImg ${joint === 'hip' && jointSide === 'right' ? 'dji-visible' : ''}`} />
      <LKnee className={`DynamicJointIndicator__SkeletonImg ${joint === 'knee' && jointSide === 'left' ? 'dji-visible' : ''}`} />
      <RKnee className={`DynamicJointIndicator__SkeletonImg ${joint === 'knee' && jointSide === 'right' ? 'dji-visible' : ''}`} />
    </div>
  );
};

export default DynamicJointIndicator;
