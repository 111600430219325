// keys for each step in the education flow. each member of this enum must have a
// corresponding Step object in the educationFlowMap object from educationFlow.tsx

export enum EducationFlowStepKeys {
  JointArthritisEduVideo = 'joint-arthritis',
  IdentifyJointCompartments = 'compartment-identification',
  XraySelectionVideo = 'xray-selection',
  XraySelection = 'request-xrays',
  JointArthritisEvalVideo = 'arthritis-evaluation',
  JointSpaceNarrowing = 'joint-space',
  ConfirmingEvaluations = 'confirming-evaluations',
  XrayMatching = 'xray-matching',
  SampleEvalVideo = 'sample-evaluation',
  CareRecommendationVideo = 'care-recommendation',
  Complete = 'complete',
}
