import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

interface ModalProps {
  children: React.ReactNode;
  closeModal?(): void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  closeModal,
}) => {
  const portalElement = document.getElementById('portal');

  const modalElement = (
    <div
      className='Modal__Container'
      onClick={closeModal}
      onKeyDown={closeModal}
      tabIndex={0}
      role='button'
    >
      <div className='Modal__Background' />
      <div className='Modal'>
        {children}
      </div>
    </div>
  );

  return portalElement
    ? ReactDOM.createPortal(modalElement, portalElement) : null;
};

export default Modal;
