import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Surface } from '../../atoms/Surface';
import { BulletItem } from '../../atoms/BulletItem';
import { TextButton } from '../../atoms/TextButton';
import { Button } from '../../atoms/Button';
import { RouteKeys } from '../../../data/routeKeys';
import { routes } from '../../../data/routes';
import { ReactComponent as JointAILogo } from '../../../assets/logos/JointAILogo2023.svg';
import './CTA.scss';

// CTA page that is nagivated to from the CTA button on the home page, and
// shown after the user completes patients 6 and 10 in the test your knowledge flow

interface CTAProps {
  declineBtnAction?(): void;
  declineBtnText?: string
}

const CTA: React.FC<CTAProps> = ({
  declineBtnAction,
  declineBtnText,
}) => {
  const navigate = useNavigate();

  return (
    <div className='CTA__Page'>
      <div className='CTA__Content left'>
        <JointAILogo className='CTA__Logo' />
        <p className='CTA__Desc'>
          Learn about becoming a Partner Site and getting
          early access to JointDx, an ML-powered tool to help
          your orthopedic clinic triage new patients to the right
          clinician.
        </p>
        <Button
          fullWidth
          onPress={() => window.open('https://calendly.com/dj--mlu/introductory-call', '_blank')}
        >
          Schedule my demo!
        </Button>
        <TextButton
          className='CTA__BackBtn'
          onPress={declineBtnAction ? declineBtnAction : () => navigate(routes[RouteKeys.HOME].route)}
        >
          {declineBtnText || 'Maybe later, take me back.'}
        </TextButton>
      </div>
      <div className='CTA__Content'>
        <h3>JointDx Core Features:</h3>
        <Surface
          fullWidth
          className='CTA__FeaturePanel'
          variant='smooth'
        >
          <div className='CTA__FeaturePanelCorner top-left' />
          <div className='CTA__FeaturePanelCorner top-right' />
          <div className='CTA__FeaturePanelCorner bottom-left' />
          <div className='CTA__FeaturePanelCorner bottom-right' />
          <ul className='CTA__FeatureList'>
            <BulletItem>
              <p className='CTA__Bullet'>
                Degenerative Joint Disease ML Recognition in X-rays
              </p>
            </BulletItem>
            <BulletItem>
              <p className='CTA__Bullet'>
                PRO Survey Collection and Reporting
              </p>
            </BulletItem>
            <BulletItem>
              <p className='CTA__Bullet'>
                Personalized Care Pathway Recommendation
              </p>
            </BulletItem>
          </ul>
        </Surface>
      </div>
    </div>
  );
};

export default CTA;
