import React from 'react';
import { CarePathways, ExamplePatient } from '../../../models/ExamplePatient';
import './SurgeryMatrix.scss';

interface SurgeryMatrixProps {
  patient: ExamplePatient;
  hideHighlight?: boolean;
}

const SurgeryMatrix: React.FC<SurgeryMatrixProps> = ({
  patient,
  hideHighlight,
}) => (
  <div className='SurgeryMatrix__Container'>
    <div className={`SurgeryMatrix__Section ${!hideHighlight && patient.carePathway === CarePathways.PARTIAL_REPLACEMENT ? 'sm-hilight' : ''}`}>
      <div className='SurgeryMatrix__SectionL'>
        <p>1 compartment is Near-End Stage to End Stage</p>
        <p>2 compartments are Normal / Slight.</p>
      </div>
      <div className={`SurgeryMatrix__SectionR ${!hideHighlight && patient.carePathway === CarePathways.PARTIAL_REPLACEMENT ? 'sm-hilight-r' : ''}`}>
        <p>Partial Replacement Candidate</p>
      </div>
    </div>
    <div className={`SurgeryMatrix__Section ${!hideHighlight && patient.carePathway === CarePathways.TOTAL_REPLACEMENT ? 'sm-hilight' : ''}`}>
      <div className='SurgeryMatrix__SectionL'>
        <p>More than one compartment is Moderate to End Stage</p>
      </div>
      <div className={`SurgeryMatrix__SectionR ${!hideHighlight && patient.carePathway === CarePathways.TOTAL_REPLACEMENT ? 'sm-hilight-r' : ''}`}>
        <p>Total Replacement Candidate</p>
      </div>
    </div>
  </div>
);

export default SurgeryMatrix;
