import React, {
  ButtonHTMLAttributes,
  ElementType,
  RefAttributes,
  useRef,
} from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';
import './SubtitleButton.scss';

type SubtitleButtonProps = {
  children: React.ReactNode;
  subtitle: string;
  active?: boolean;
  fullWidth?: boolean;
  variant?: 'primary' | 'secondary';
  icon?: React.ReactNode;
  fillBg?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>
  & RefAttributes<HTMLButtonElement>
  & AriaButtonOptions<ElementType>;

const SubtitleButton: React.FC<SubtitleButtonProps> = (props) => {
  const  {
    children,
    subtitle,
    active,
    fullWidth,
    icon,
    fillBg,
    variant = 'primary',
  } = props;

  const ref = useRef(null);

  const { buttonProps } = useButton(props, ref);

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={`SubtitleButton__Container ${fillBg ? 's-fill-bg' : ''} ${active ? 's-active' : ''} s-${variant} ${fullWidth ? 'full-width' : ''} ${props.className ? props.className : ''}`}
    >
      <div>
        {children}
        <p className={`SubtitleButton__Subtitle ${active ? 's-active' : ''}`}>{subtitle}</p>
      </div>
      {icon && icon}
    </button>
  );
};

export default SubtitleButton;
