import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import { ReactComponent as LegsImage } from '../../../assets/images/skeleton_legs.svg';
import { ReactComponent as JointIqLogo } from '../../../assets/logos/jointiQ logo.svg';
import './EducationCompleteStep.scss';

// corresponding page in app:
// https://education.jointai.co/knee-education/complete

const EducationCompleteStep: React.FC<StepProps> = ({ flow }) => {
  const {
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const { eduPageContainerClassName } = useLayoutState({
    eduPageContainerBaseClassName: 'EducationCompleteStep__Page',
  });

  const isKneeFlow = flow === RouteKeys.KNEE_EDU;
  const isHipFlow = flow === RouteKeys.HIP_EDU;

  return (
    <div className={eduPageContainerClassName}>
      <div className='EducationCompleteStep__Container'>
        <div className='EducationCompleteStep__MainContentL'>
          <JointIqLogo className='EducationCompleteStep__Logo' />
          {isKneeFlow && <span className='EducationCompleteStep__Content'>Knee Education Complete!</span>}
          {isHipFlow && <span className='EducationCompleteStep__Content'>Hip Education Complete!</span>}
          <span className='EducationCompleteStep__Content'>You can now try out your own joint evaluation.</span>
        </div>
        <div className='EducationCompleteStep__MainContentR'>
          <LegsImage className='EducationCompleteStep__LegsImg' />
        </div>
      </div>
      <NavigationFooter
        prev={{ action: prevStep }}
        next={{ label: 'Finish', action: nextStep }}
      />
    </div>
  );
};

export default EducationCompleteStep;
