import React, { HTMLAttributes } from 'react';
import { Button } from '../../atoms/Button';
import './NavigationFooter.scss';

interface NavigationButton {
  action(): void;
  label?: string;
  disabled?: boolean;
  tag?: string;
}

type NavigationFooterProps = {
  next?: NavigationButton;
  prev?: NavigationButton;
  centerElement?: JSX.Element | null;
} & HTMLAttributes<HTMLDivElement>;

const NavigationFooter: React.FC<NavigationFooterProps> = ({
  next,
  prev,
  centerElement,
  ...props
}) => (
  <div {...props} className={`NavigationFooter__Container ${props.className ? props.className : ''}`}>
    <div className='NavigationFooter__Third'>
      {prev && (
        <div className='NavigationFooter__ActionContainer'>
          {prev.tag && <span className='NavigationFooter__Tag nf--back'>{prev.tag}</span>}
          <Button
            isDisabled={prev.disabled}
            onPress={prev.action}
          >
            {prev.label || 'Back'}
          </Button>
        </div>
      )}
    </div>
    {!!centerElement && centerElement}
    <div className='NavigationFooter__Third'>
      {next && (
        <Button
          isDisabled={next.disabled}
          onPress={next.action}
        >
          {next.label || 'Next'}
        </Button>
      )}
    </div>
  </div>
);

export default NavigationFooter;
