import React, { useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { XrayTable } from '../../molecules/XrayTable';
import { CornerTag } from '../../atoms/CornerTag';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { getJoint, getWorstCaseDeg } from '../../../helpers/helpers';
import { DegenerationIndicator } from '../../atoms/DegenerationIndicator';
import { DegenerationLevels } from '../../../models/ExampleXray';
import { Button } from '../../atoms/Button';
import { RouteKeys } from '../../../data/routeKeys';
import { PatientFlowStepKeys } from '../../../data/patientFlowKeys';
import { StepProps } from '../../../models/StepProps';
import './JointEvaluationStep.scss';

// patient flow step 7 - confirming what the joint's worst case degeneration is.
// in the training flow, the user is simply told the answer
// in the testing flow, the user is prompted to decide what the worst case degeneration is themselves

const JointEvaluationStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    completeActiveStep,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const worstCaseDeg = getWorstCaseDeg(Object.values(activePatient!.xrays.forAnalysis));

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;
  const stepIsComplete = !!activePatient![flow as RouteKeys.SBS_TRAINING | RouteKeys.TEST_YOUR_KNOWLEDGE][PatientFlowStepKeys.JointEvaluation];

  const [selectedDegLvl, setSelectedDegLvl] = useState<DegenerationLevels | null>(stepIsComplete ? worstCaseDeg : null);
  const [showResult, setShowResult] = useState<boolean>(stepIsComplete);

  if (!activePatient || (!isTrainingFlow && !isTestingFlow)) return null;

  const joint = getJoint(activePatient.joint);

  return (
    <div className='JointEvaluationStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient!.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='JointEvaluationStep__Container'>
        <h3 className='JointEvaluationStep__Header'>Joint Evaluation {activePatient!.joint}</h3>
        <div className='JointEvaluationStep__MainContent'>
          <XrayTable patient={activePatient} />
          <div className='JointEvaluationStep__OutcomeContainer'>
            {isTrainingFlow && (
              <h4 className='JointEvaluationStep__Label--indicator'>
                Worst Case Degeneration in {joint}
              </h4>
            )}
            {isTestingFlow && (
              <h4 className='JointEvaluationStep__Label'>
                From the chart above, select the overall worst case x-ray:
              </h4>
            )}
            {isTrainingFlow && (
              <DegenerationIndicator
                className='JointEvaluationStep__DegIndicator'
                degLvl={worstCaseDeg}
              />
            )}
            {isTestingFlow && (
              <div className='JointEvaluationStep__ActionsContainer'>
                <div className='JointEvaluationStep__BtnsContainer'>
                  {Object.values(DegenerationLevels).map((degLvl) => (
                    <Button
                      key={degLvl}
                      variant='mixed'
                      onPress={() => {
                        if (showResult && selectedDegLvl === worstCaseDeg) return;
                        setShowResult(false);
                        setSelectedDegLvl(degLvl)}
                      }
                      selected={selectedDegLvl === degLvl}
                      fullWidth
                    >
                      {degLvl}
                    </Button>
                  ))}
                </div>
                <div className='JointEvaluationStep__DegIndicatorContainer'>
                  <h4 className='JointEvaluationStep__Label--indicator'>
                    Worst Case Degeneration in {joint}
                  </h4>
                  <DegenerationIndicator
                    className='JointEvaluationStep__DegIndicator'
                    degLvl={selectedDegLvl}
                  />
                  <div className='JointEvaluationStep__ResultContainer'>
                    {showResult && selectedDegLvl === worstCaseDeg && <p className='success'>Correct!</p>}
                    {showResult && selectedDegLvl !== worstCaseDeg && <p className='error'>Incorrect</p>}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <NavigationFooter
        prev={{ action: prevStep }}
        next={{
          label: (
            isTrainingFlow
              ? 'Next'
              : showResult && selectedDegLvl && selectedDegLvl === worstCaseDeg
                ? 'Next'
                : 'Confirm'
              ),
          disabled: (
            isTrainingFlow
              ? false
              : stepIsComplete
                ? false
                : !selectedDegLvl
          ),
          action: (
            isTrainingFlow 
              ? nextStep
              : () => {
                if (!stepIsComplete && (selectedDegLvl === worstCaseDeg)) completeActiveStep();
                if (showResult && stepIsComplete) return nextStep();
                setShowResult(true);
              }
            ),
        }} />
    </div>
  );
};

export default JointEvaluationStep;
