import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { CornerTag } from '../../atoms/CornerTag';
import { ViewTypes, XrayForAnalysis, JointCompartments } from '../../../models/ExampleXray';
import { XrayEvaluationSubStep } from '../XrayEvaluationSubStep';
import { OuterIconButton } from '../../atoms/OuterIconButton';
import { getJoint, getJointSide, sortXrayEntiresByGroupKey } from '../../../helpers/helpers';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import { ReactComponent as CircleCheckIcon } from '../../../assets/icons/circle_check_icon.svg';
import { ReactComponent as LeftHip } from '../../../assets/images/LEFTHIPSKELETON.svg';
import { ReactComponent as RightHip } from '../../../assets/images/RIGHTHIPSKELETON.svg';
import { ReactComponent as LeftKnee } from '../../../assets/images/LEFTKNEESKELETON.svg';
import { ReactComponent as RightKnee } from '../../../assets/images/RIGHKNEESKELETON.svg';
import './XrayConfirmationStep.scss';

// patient flow step 5 - the launching pad for each individual compartment evaluation
// substep. 

const XrayConfirmationStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    activeXray,
    setActiveXray,
    saveActiveXrayEvalFirstSelection,
    completeActiveXrayEval,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const joint = getJoint(activePatient!.joint);
  const jointSide = getJointSide(activePatient!.joint);

  type GroupKeyOptions = JointCompartments | ViewTypes;

  const groupKeySet = {} as Record<GroupKeyOptions, boolean>;

  // hip xrays are grouped by view type while knee xrays are grouped by compartment
  for (const item of Object.values(activePatient!.xrays.forAnalysis)) {
    if (joint === 'hip') {
      groupKeySet[item.viewType] = true;
    } else {
      groupKeySet[item.compartment] = true;
    }
  };

  const groupedXrays = {} as Record<GroupKeyOptions, XrayForAnalysis[]>;

  Object.keys(groupKeySet).forEach((type) => {
    groupedXrays[type as GroupKeyOptions] = [...Object.values(activePatient!.xrays.forAnalysis).filter((xray) => joint === 'hip' ? xray.viewType === type : xray.compartment === type)];
  });

  const groupedAndSortedXrays = Object.entries(groupedXrays).sort((a, b) => sortXrayEntiresByGroupKey(a, b, joint));
  const allXraysOrdered = groupedAndSortedXrays.map(([, xrays]) => xrays).flat();

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;
  if (!isTrainingFlow && !isTestingFlow) return null;

  const allSubStepsComplete = (Object.values(activePatient![flow].evaluatedXrays).length === Object.values(activePatient!.xrays.forAnalysis).length);

  const getButtonDisabledState = (currentXrayIdx: number, arr: XrayForAnalysis[]): boolean => {
    if (currentXrayIdx === 0) return false;
    const prevXrayInOrder = arr[currentXrayIdx - 1];
    if (!(activePatient![flow].evaluatedXrays[prevXrayInOrder.id] && activePatient![flow].evaluatedXrays[prevXrayInOrder.id].complete)) return true;
    return false;
  };

  if (activeXray) {
    return (
      <XrayEvaluationSubStep
        showHelpButton
        flow={flow}
        patient={activePatient!}
        patientNumber={activePatientNumber}
        xray={activeXray}
        handleSubmitAnswer={saveActiveXrayEvalFirstSelection}
        handleClickNext={completeActiveXrayEval}
      />
    );
  };

  return (
    <div className='XrayConfirmationStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient!.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='XrayConfirmationStep__Container'>
        <h3 className='XrayConfirmationStep__Header'>X-ray Evaluation Confirmation</h3>
        <div className='XrayConfirmationStep__MainContent'>
          <div className='XrayConfirmationStep__MainContentL'>
            {activePatient!.joint === 'right knee' && <RightKnee />}
            {activePatient!.joint === 'left knee' && <LeftKnee />}
            {activePatient!.joint === 'right hip' && <RightHip />}
            {activePatient!.joint === 'left hip' && <LeftHip />}
          </div>
          <div className='XrayConfirmationStep__MainContentR'>
            {groupedAndSortedXrays.map(([groupKey, xrays], sectIdx, arr) => (
              <div
                key={groupKey}
                className={`XrayConfirmationStep__ViewTypeSection ${sectIdx === 0 ? 'first' : ''} ${sectIdx === arr.length - 1 ? 'last' : ''}`}
              >
                <h4 className='XrayConfirmationStep__ViewTypeSectionLabel'>{groupKey}</h4>
                <div className='XrayConfirmationStep__XrayButtons'>
                  {xrays.map((xray, xrayIdx) => (
                    <OuterIconButton
                      key={xray.id}
                      outerIcon={xray.src}
                      innerIcon={
                        activePatient![flow].evaluatedXrays[xray.id] && activePatient![flow].evaluatedXrays[xray.id].complete
                          ? <CircleCheckIcon height="100%" width="100%" />
                          : null
                      }
                      isDisabled={getButtonDisabledState((sectIdx * 2) + xrayIdx, allXraysOrdered)}
                      pulse={
                        !(activePatient![flow].evaluatedXrays[xray.id] && activePatient![flow].evaluatedXrays[xray.id].complete)
                        && (!getButtonDisabledState((sectIdx * 2) + xrayIdx, allXraysOrdered))
                      }
                      onPress={() => setActiveXray(activePatient!.xrays.forAnalysis[xray.id])}
                    >
                      {joint === 'hip'
                        ? `${jointSide} ${xray.compartment}`
                        : `${xray.viewType === ViewTypes.NON_FLEX ? 'Non-Flexion' : xray.viewType} View`}
                    </OuterIconButton>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <NavigationFooter
        prev={{ action: prevStep, tag: 'to Patient Profile' }}
        next={allSubStepsComplete ? { action: nextStep } : undefined}
      />
    </div>
  );
};

export default XrayConfirmationStep;
