import { CarePathways, ExamplePatient, PatientTrainingState } from "../models/ExamplePatient";
import { ViewTypes, DegenerationLevels, JointCompartments } from "../models/ExampleXray";
import { ProfileSubStepKeys, PatientFlowStepKeys } from "./patientFlowKeys";
import { RouteKeys } from "./routeKeys";

import BMillerFlexReview from '../assets/patientAssets/BMiller/1 With Flexion_BMiller.jpg';
import BMillerNonFlexReview from '../assets/patientAssets/BMiller/2 Non Flexion_BMiller.jpg';
import BMillerKneecapReview from '../assets/patientAssets/BMiller/3 Kneecap_BMiller.jpg';
import BMillerLateralReview from '../assets/patientAssets/BMiller/4 Right Later_BMillerjpg.jpg';
import BMillerKneecapAnalyze from '../assets/patientAssets/BMiller/BMiller Kneecap.jpg';
import BMillerFlexLatAnalyze from '../assets/patientAssets/BMiller/Flex-Lateral.jpg';
import BMillerFlexMedAnalyze from '../assets/patientAssets/BMiller/Flex-Medial.jpg';
import BMillerNonFlexLatAnalyze from '../assets/patientAssets/BMiller/NFlex-Lateral.jpg';
import BMillerNonFlexMedAnalyze from '../assets/patientAssets/BMiller/NFlex-Medial.jpg';
import BMillerSurvey from "../assets/patientAssets/BMiller/BMillerSurvey.png";
import BMillerGraphs from "../assets/patientAssets/BMiller/BMillerGraphs.png";
import BMillerPdf2 from "../assets/patientAssets/BMiller/BMiller Pt Summary 2.pdf";
import BMillerTYKSurvey from "../assets/patientAssets/BMiller/BMillerTYKSurvey.png";
import BMillerTYKPdf from "../assets/patientAssets/BMiller/BMillerTYKPdf.pdf";

import JDoinaFlexReview from '../assets/patientAssets/JDoina/1 flex_JDoina.jpg';
import JDoinaNonFlexReview from '../assets/patientAssets/JDoina/2 non flex_JDoina.jpg';
import JDoinaKneecapReview from '../assets/patientAssets/JDoina/3 Left Kneecap_JDoina.jpg';
import JDoinaLateralReview from '../assets/patientAssets/JDoina/4 Left lateral_JDoina.jpg';
import JDoinaKneecapAnalyze from '../assets/patientAssets/JDoina/JDoina Kneecap.jpg';
import JDoinaFlexLatAnalyze from '../assets/patientAssets/JDoina/Flex-Lateral.jpg';
import JDoinaFlexMedAnalyze from '../assets/patientAssets/JDoina/Flex-Medial.jpg';
import JDoinaNonFlexLatAnalyze from '../assets/patientAssets/JDoina/NFlex-Lateral.jpg';
import JDoinaNonFlexMedAnalyze from '../assets/patientAssets/JDoina/NFlex-Medial.jpg';
import JDoinaSurvey from '../assets/patientAssets/JDoina/JDoinaSurvey.png';
import JDoinaGraphs from '../assets/patientAssets/JDoina/JDoinaGraphs.png';
import JDoinaPdf from "../assets/patientAssets/JDoina/JDoinaPdf.pdf";
import JDoinaTYKSurvey from "../assets/patientAssets/JDoina/JDoinaTYKSurvey.png";
import JDoinaTYKPdf from "../assets/patientAssets/JDoina/JDoinaTYKPdf.pdf";

import RSmithFlexReview from '../assets/patientAssets/RSmith/1 With Flexion_RSmith_Pt3.jpg';
import RSmithNonFlexReview from '../assets/patientAssets/RSmith/2 With no flexion_RSmith_Pt3.jpg';
import RSmithKneecapReview from '../assets/patientAssets/RSmith/3 NES kneecap_RSmith.jpg';
import RSmithLateralReview from '../assets/patientAssets/RSmith/4 Right Lateral_RSmith_Pt3.jpg';
import RSmithKneecapAnalyze from '../assets/patientAssets/RSmith/RSmith NES Kneecap.jpg';
import RSmithFlexLatAnalyze from '../assets/patientAssets/RSmith/Flex-Lateral.jpg';
import RSmithFlexMedAnalyze from '../assets/patientAssets/RSmith/Flex-Medial.jpg';
import RSmithNonFlexLatAnalyze from '../assets/patientAssets/RSmith/NFlex-Lateral.jpg';
import RSmithNonFlexMedAnalyze from '../assets/patientAssets/RSmith/NFlex-Medial.jpg';
import RSmithSurvey from '../assets/patientAssets/RSmith/RSmithSurvey.png';
import RSmithGraphs from '../assets/patientAssets/RSmith/RSmithGraphs.png';
import RSmithPdf from "../assets/patientAssets/RSmith/RSmithPdf.pdf";
import RSmithTYKSurvey from "../assets/patientAssets/RSmith/RSmithTYKSurvey.png";
import RSmithTYKPdf from "../assets/patientAssets/RSmith/RSmithTYKPdf.pdf";

import BDavisFlexReview from '../assets/patientAssets/BDavis/1 With Flexion_BDavis.jpg';
import BDavisNonFlexReview from '../assets/patientAssets/BDavis/2 With no Flexion_BDavis.jpg';
import BDavisKneecapReview from '../assets/patientAssets/BDavis/3 Kneecap_BDavis.jpg';
import BDavisLateralReview from '../assets/patientAssets/BDavis/4 Right lateral_BDavis.jpg';
import BDavisKneecapAnalyze from '../assets/patientAssets/BDavis/BDavis - Kneecap.jpg';
import BDavisFlexLatAnalyze from '../assets/patientAssets/BDavis/Flex-Lateral.jpg';
import BDavisFlexMedAnalyze from '../assets/patientAssets/BDavis/Flex-Medial.jpg';
import BDavisNonFlexLatAnalyze from '../assets/patientAssets/BDavis/NFlex-Lateral.jpg';
import BDavisNonFlexMedAnalyze from '../assets/patientAssets/BDavis/NFlex-Medial.jpg';
import BDavisSurvey from '../assets/patientAssets/BDavis/BDavisSurvey.png';
import BDavisGraphs from '../assets/patientAssets/BDavis/BDavisGraphs.png';
import BDavisPdf from "../assets/patientAssets/BDavis/BDavisPdf.pdf";
import BDavisTYKSurvey from "../assets/patientAssets/BDavis/BDavisTYKSurvey.png";
import BDavisTYKPdf from "../assets/patientAssets/BDavis/BDavisTYKPdf.pdf";

import SSimonPelvisReview from '../assets/patientAssets/SSimon/1_Pelvis_SSimon.jpg';
import SSimonLateralReview from '../assets/patientAssets/SSimon/2_Right Lateral_SSimon.jpg';
import SSimonPelvisZ1Analyze from '../assets/patientAssets/SSimon/AP-Z1.jpg';
import SSimonPelvisZ2Analyze from '../assets/patientAssets/SSimon/AP-Z2.jpg';
import SSimonLateralZ1Analyze from '../assets/patientAssets/SSimon/Lateral-Z1..jpg';
import SSimonLateralZ2Analyze from '../assets/patientAssets/SSimon/Lateral-Z2..jpg';
import SSimonSurvey from '../assets/patientAssets/SSimon/SSimonSurvey.png';
import SSimonGraphs from '../assets/patientAssets/SSimon/SSimonGraphs.png';
import SSimonPdf from "../assets/patientAssets/SSimon/SSimonPdf.pdf";
import SSimonTYKSurvey from "../assets/patientAssets/SSimon/SSimonTYKSurvey.png";
import SSimonTYKPdf from "../assets/patientAssets/SSimon/SSimonTYKPdf.pdf";

import JPotterPelvisReview from '../assets/patientAssets/JPotter/1_Pelvis_J Potter.jpg';
import JPotterLateralReview from '../assets/patientAssets/JPotter/2_Right frog lateral_J Potter.jpg';
import JPotterPelvisZ1Analyze from '../assets/patientAssets/JPotter/AP-Z1.jpg';
import JPotterPelvisZ2Analyze from '../assets/patientAssets/JPotter/AP-Z2.jpg';
import JPotterLateralZ1Analyze from '../assets/patientAssets/JPotter/Lateral-Z1.jpg';
import JPotterLateralZ2Analyze from '../assets/patientAssets/JPotter/Lateral-Z2.jpg';
import JPotterSurvey from '../assets/patientAssets/JPotter/JPotterSurvey.png';
import JPotterGraphs from '../assets/patientAssets/JPotter/JPotterGraphs.png';
import JPotterPdf from "../assets/patientAssets/JPotter/JPotterPdf.pdf";
import JPotterTYKSurvey from "../assets/patientAssets/JPotter/JPotterTYKSurvey.png";
import JPotterTYKPdf from "../assets/patientAssets/JPotter/JPotterTYKPdf.pdf";

import ABrownPelvisReview from '../assets/patientAssets/ABrown/1_Pelvis_A Brown.jpg';
import ABrownLateralReview from '../assets/patientAssets/ABrown/2_Left frog lateral_A Brown.jpg';
import ABrownPelvisZ1Analyze from '../assets/patientAssets/ABrown/AB_APZ1.jpg';
import ABrownPelvisZ2Analyze from '../assets/patientAssets/ABrown/AP-Z2.jpg';
import ABrownLateralZ1Analyze from '../assets/patientAssets/ABrown/AB_LeftLateralZ1.jpg';
import ABrownLateralZ2Analyze from '../assets/patientAssets/ABrown/Lateral-Z2.jpg';
import ABrownSurvey from '../assets/patientAssets/ABrown/ABrownSurvey.png';
import ABrownGraphs from '../assets/patientAssets/ABrown/ABrownGraphs.png';
import ABrownPdf from "../assets/patientAssets/ABrown/ABrownPdf.pdf";
import ABrownTYKSurvey from "../assets/patientAssets/ABrown/ABrownTYKSurvey.png";
import ABrownTYKPdf from "../assets/patientAssets/ABrown/ABrownTYKPdf.pdf";

import ALeeFlexReview from '../assets/patientAssets/ALee/1 With Flexion_ALee.jpg';
import ALeeNonFlexReview from '../assets/patientAssets/ALee/2 With no Flexion_ALee.jpg';
import ALeeKneecapReview from '../assets/patientAssets/ALee/3 Kneecap_ALee.jpg';
import ALeeLateralReview from '../assets/patientAssets/ALee/4 Left lateral_ALee.jpg';
import ALeeKneecapAnalyze from '../assets/patientAssets/ALee/ALee_Kneecap.jpg';
import ALeeFlexLatAnalyze from '../assets/patientAssets/ALee/Flex-Lateral.jpg';
import ALeeFlexMedAnalyze from '../assets/patientAssets/ALee/Flex-Medial.jpg';
import ALeeNonFlexLatAnalyze from '../assets/patientAssets/ALee/NFlex-Lateral.jpg';
import ALeeNonFlexMedAnalyze from '../assets/patientAssets/ALee/NFlex-Medial.jpg';
import ALeeSurvey from '../assets/patientAssets/ALee/ALeeSurvey.png';
import ALeeGraphs from '../assets/patientAssets/ALee/ALeeGraphs.png';
import ALeePdf from "../assets/patientAssets/ALee/ALeePdf.pdf";
import ALeeTYKSurvey from "../assets/patientAssets/ALee/ALeeTYKSurvey.png";
import ALeeTYKPdf from "../assets/patientAssets/ALee/ALeeTYKPdf.pdf";

import JSilvaPelvisReview from '../assets/patientAssets/JSilva/Jose Silva_AP Pelvis.png';
import JSilvaLateralReview from '../assets/patientAssets/JSilva/Jose Silva_Right Lateral.png';
import JSilvaPelvisZ1Analyze from '../assets/patientAssets/JSilva/JS_APZ1.jpg';
import JSilvaPelvisZ2Analyze from '../assets/patientAssets/JSilva/AP-Z2.jpg';
import JSilvaLateralZ1Analyze from '../assets/patientAssets/JSilva/JSilva_LatZ1.jpg';
import JSilvaLateralZ2Analyze from '../assets/patientAssets/JSilva/JSilva_LatZ2.jpg';
import JSilvaSurvey from '../assets/patientAssets/JSilva/JSilvaSurvey.png';
import JSilvaGraphs from '../assets/patientAssets/JSilva/JSilvaGraphs.png';
import JSilvaPdf from "../assets/patientAssets/JSilva/JSilvaPdf.pdf";
import JSilvaTYKSurvey from "../assets/patientAssets/JSilva/JSilvaTYKSurvey.png";
import JSilvaTYKPdf from "../assets/patientAssets/JSilva/JSilvaTYKPdf.pdf";

import SBenningtonFlexReview from '../assets/patientAssets/SBennington/Upload Flex.jpg';
import SBenningtonNonFlexReview from '../assets/patientAssets/SBennington/Upload Non-Flex.png';
import SBenningtonKneecapReview from '../assets/patientAssets/SBennington/Upload Right Kneecap.png';
import SBenningtonLateralReview from '../assets/patientAssets/SBennington/Upload RightLateral.png';
import SBenningtonKneecapAnalyze from '../assets/patientAssets/SBennington/Analyze SBennington_Kneecap.jpg';
import SBenningtonFlexLatAnalyze from '../assets/patientAssets/SBennington/Analyze Flexion-Lateral.jpg';
import SBenningtonFlexMedAnalyze from '../assets/patientAssets/SBennington/Analyze Flex-Medial.jpg';
import SBenningtonNonFlexLatAnalyze from '../assets/patientAssets/SBennington/Analyze NonFlex-Lateral.jpg';
import SBenningtonNonFlexMedAnalyze from '../assets/patientAssets/SBennington/Analyze NonFlex-Medial.jpg';
import SBenningtonSurvey from "../assets/patientAssets/SBennington/SBenningtonSurvey.png";
import SBenningtonGraphs from "../assets/patientAssets/SBennington/SBenningtonGraphs.png";
import SBenningtonPdf from "../assets/patientAssets/SBennington/SBenningtonPdf.pdf";
import SBenningtonTYKSurvey from "../assets/patientAssets/SBennington/SBenningtonTYKSurvey.png";
import SBenningtonTYKPdf from "../assets/patientAssets/SBennington/SBenningtonTYKPdf.pdf";

// data for each example patient used in the patient flow

export const initPatientFlowState: PatientTrainingState = {
  [ProfileSubStepKeys.ViewSurvey]: false,
  [ProfileSubStepKeys.ReviewXrays]: false,
  [PatientFlowStepKeys.JointEvaluation]: false,
  [PatientFlowStepKeys.CarePathwaySelection]: false,
  carePathwaySelection: null,
  patientComplete: false,
  selectedXrays: {},
  evaluatedXrays: {},
};

export const examplePatientsMap: { [key: string]: ExamplePatient } = {
  bMiller: {
    key: 'bMiller',
    name: 'Bernice Miller',
    joint: 'right knee',
    chiefComplaint: '69 yr old woman with mild right knee pain that is starting to affect her ability to cultivate the various plants in her backyard garden.',
    gender: 'female',
    birthdate: '11/13/1953',
    age: 69,
    height: `5' 6"`,
    weight: '154lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: BMillerFlexReview,
          viewType: ViewTypes.FLEX,
        },
        {
          id: 2,
          src: BMillerNonFlexReview,
          viewType: ViewTypes.NON_FLEX,
        },
        {
          id: 3,
          src: BMillerKneecapReview,
          viewType: ViewTypes.KNEECAP,
        },
        {
          id: 4,
          src: BMillerLateralReview,
          viewType: ViewTypes.LATERAL_KNEE,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: BMillerFlexLatAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        2: {
          id: 2,
          src: BMillerFlexMedAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.NEAR_ENDSTAGE,
        },
        3: {
          id: 3,
          src: BMillerNonFlexLatAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        4: {
          id: 4,
          src: BMillerNonFlexMedAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.MODERATE,
        },
        5: {
          id: 5,
          src: BMillerKneecapAnalyze,
          viewType: ViewTypes.KNEECAP,
          degeneration: DegenerationLevels.NEAR_ENDSTAGE,
          compartment: JointCompartments.KNEECAP,
        },
      },
    },
    carePathway: CarePathways.NON_OPERATIVE,
    proSurvey: BMillerSurvey,
    proGraphs: BMillerGraphs,
    pdf: BMillerPdf2,
    proScore: 85,
    TYKPdf: BMillerTYKPdf,
    TYKProSurvey: BMillerTYKSurvey,
    // this is where the state is stored for the user's progress on this patient in the training flow
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    // this is where the state is stored for the user's progress on this patient in the test your knowledge flow
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 4,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 1,
    },
  },
  jDoina: {
    key: 'jDoina',
    name: 'Jane Doina',
    joint: 'left knee',
    chiefComplaint: '60 yr old woman with mostly moderate but occasionally severe left knee pain that has worsened in the last 3 months.',
    gender: 'female',
    birthdate: '07/04/1962',
    age: 60,
    height: `5' 8"`,
    weight: '160lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: JDoinaFlexReview,
          viewType: ViewTypes.FLEX,
        },
        {
          id: 2,
          src: JDoinaNonFlexReview,
          viewType: ViewTypes.NON_FLEX,
        },
        {
          id: 3,
          src: JDoinaKneecapReview,
          viewType: ViewTypes.KNEECAP,
        },
        {
          id: 4,
          src: JDoinaLateralReview,
          viewType: ViewTypes.LATERAL_KNEE,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: JDoinaFlexLatAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        2: {
          id: 2,
          src: JDoinaFlexMedAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        3: {
          id: 3,
          src: JDoinaNonFlexLatAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        4: {
          id: 4,
          src: JDoinaNonFlexMedAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        5: {
          id: 5,
          src: JDoinaKneecapAnalyze,
          viewType: ViewTypes.KNEECAP,
          degeneration: DegenerationLevels.NORMAL,
          compartment: JointCompartments.KNEECAP,
        },
      },
    },
    carePathway: CarePathways.PARTIAL_REPLACEMENT,
    proSurvey: JDoinaSurvey,
    proGraphs: JDoinaGraphs,
    pdf: JDoinaPdf,
    proScore: 50,
    TYKPdf: JDoinaTYKPdf,
    TYKProSurvey: JDoinaTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 5,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 2,
    },
  },
  rSmith: {
    key: 'rSmith',
    name: 'Roberta Smith',
    joint: 'right knee',
    chiefComplaint: '74 yr old woman with right knee pain that is affecting most activities that she would like to do.',
    gender: 'female',
    birthdate: '11/04/1948',
    age: 74,
    height: `5' 5"`,
    weight: '164lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: RSmithFlexReview,
          viewType: ViewTypes.FLEX,
        },
        {
          id: 2,
          src: RSmithNonFlexReview,
          viewType: ViewTypes.NON_FLEX,
        },
        {
          id: 3,
          src: RSmithKneecapReview,
          viewType: ViewTypes.KNEECAP,
        },
        {
          id: 4,
          src: RSmithLateralReview,
          viewType: ViewTypes.LATERAL_KNEE,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: RSmithFlexLatAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        2: {
          id: 2,
          src: RSmithFlexMedAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        3: {
          id: 3,
          src: RSmithNonFlexLatAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        4: {
          id: 4,
          src: RSmithNonFlexMedAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        5: {
          id: 5,
          src: RSmithKneecapAnalyze,
          viewType: ViewTypes.KNEECAP,
          degeneration: DegenerationLevels.NEAR_ENDSTAGE,
          compartment: JointCompartments.KNEECAP,
        },
      },
    },
    carePathway: CarePathways.TOTAL_REPLACEMENT,
    proSurvey: RSmithSurvey,
    proGraphs: RSmithGraphs,
    pdf: RSmithPdf,
    proScore: 34,
    TYKPdf: RSmithTYKPdf,
    TYKProSurvey: RSmithTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 2,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 3,
    },
  },
  bDavis: {
    key: 'bDavis',
    name: 'Betsy Davis',
    joint: 'right knee',
    chiefComplaint: '45 yr old woman with right mild knee pain, for one year but just started to affect her golf game.',
    gender: 'female',
    birthdate: '10/10/1962',
    age: 60,
    height: `5' 3"`,
    weight: '153lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: BDavisFlexReview,
          viewType: ViewTypes.FLEX,
        },
        {
          id: 2,
          src: BDavisNonFlexReview,
          viewType: ViewTypes.NON_FLEX,
        },
        {
          id: 3,
          src: BDavisKneecapReview,
          viewType: ViewTypes.KNEECAP,
        },
        {
          id: 4,
          src: BDavisLateralReview,
          viewType: ViewTypes.LATERAL_KNEE,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: BDavisFlexLatAnalyze, 
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        2: {
          id: 2,
          src: BDavisFlexMedAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        3: {
          id: 3,
          src: BDavisNonFlexLatAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        4: {
          id: 4,
          src: BDavisNonFlexMedAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        5: {
          id: 5,
          src: BDavisKneecapAnalyze,
          viewType: ViewTypes.KNEECAP,
          degeneration: DegenerationLevels.NORMAL,
          compartment: JointCompartments.KNEECAP,
        },
      },
    },
    carePathway: CarePathways.NON_OPERATIVE,
    proSurvey: BDavisSurvey,
    proGraphs: BDavisGraphs,
    pdf: BDavisPdf,
    proScore: 76,
    TYKPdf: BDavisTYKPdf,
    TYKProSurvey: BDavisTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 1,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 4,
    },
  },
  aLee: {
    key: 'aLee',
    name: 'Anne Lee',
    joint: 'left knee',
    chiefComplaint: '75 year old female who is experiencing moderate left knee pain and has gotten worse in in the last half a year.',
    gender: 'female',
    birthdate: '08/19/1947',
    age: 75,
    height: `5' 7"`,
    weight: '159lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: ALeeFlexReview,
          viewType: ViewTypes.FLEX,
        },
        {
          id: 2,
          src: ALeeNonFlexReview,
          viewType: ViewTypes.NON_FLEX,
        },
        {
          id: 3,
          src: ALeeKneecapReview,
          viewType: ViewTypes.KNEECAP,
        },
        {
          id: 4,
          src: ALeeLateralReview,
          viewType: ViewTypes.LATERAL_KNEE,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: ALeeFlexLatAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        2: {
          id: 2,
          src: ALeeFlexMedAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.MODERATE,
        },
        3: {
          id: 3,
          src: ALeeNonFlexLatAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.NORMAL,
        },
        4: {
          id: 4,
          src: ALeeNonFlexMedAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.MODERATE,
        },
        5: {
          id: 5,
          src: ALeeKneecapAnalyze,
          viewType: ViewTypes.KNEECAP,
          degeneration: DegenerationLevels.MODERATE,
          compartment: JointCompartments.KNEECAP,
        },
      },
    },
    carePathway: CarePathways.NON_OPERATIVE,
    proSurvey: ALeeSurvey,
    proGraphs: ALeeGraphs,
    pdf: ALeePdf,
    proScore: 55,
    TYKPdf: ALeeTYKPdf,
    TYKProSurvey: ALeeTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 6,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 5,
    },
  },
  sBennington: {
    key: 'sBennington',
    name: 'Sue Bennington',
    joint: 'right knee',
    chiefComplaint: '79 year old female who is experiencing severe right knee pain that is preventing her from doing daily activities around the house.',
    gender: 'female',
    birthdate: '10/08/1944',
    age: 79,
    height: `5' 1"`,
    weight: '118lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: SBenningtonFlexReview,
          viewType: ViewTypes.FLEX,
        },
        {
          id: 2,
          src: SBenningtonNonFlexReview,
          viewType: ViewTypes.NON_FLEX,
        },
        {
          id: 3,
          src: SBenningtonKneecapReview,
          viewType: ViewTypes.KNEECAP,
        },
        {
          id: 4,
          src: SBenningtonLateralReview,
          viewType: ViewTypes.LATERAL_KNEE,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: SBenningtonFlexLatAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        2: {
          id: 2,
          src: SBenningtonFlexMedAnalyze,
          viewType: ViewTypes.FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.MODERATE,
        },
        3: {
          id: 3,
          src: SBenningtonNonFlexLatAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.LATERAL,
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        4: {
          id: 4,
          src: SBenningtonNonFlexMedAnalyze,
          viewType: ViewTypes.NON_FLEX,
          compartment: JointCompartments.MEDIAL,
          degeneration: DegenerationLevels.MODERATE,
        },
        5: {
          id: 5,
          src: SBenningtonKneecapAnalyze,
          viewType: ViewTypes.KNEECAP,
          degeneration: DegenerationLevels.ENDSTAGE,
          compartment: JointCompartments.KNEECAP,
        },
      },
    },
    carePathway: CarePathways.TOTAL_REPLACEMENT,
    proSurvey: SBenningtonSurvey,
    proGraphs: SBenningtonGraphs,
    pdf: SBenningtonPdf,
    proScore: 47,
    TYKPdf: SBenningtonTYKPdf,
    TYKProSurvey: SBenningtonTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 3,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 6,
    },
  },
  sSimon: {
    key: 'sSimon',
    name: 'Stacy Simon',
    joint: 'right hip',
    chiefComplaint: '49 yr old woman with mild pain in her right hip occasionally the groin but mostly on the outside part of her hip.',
    gender: 'female',
    birthdate: '05/28/1973',
    age: 49,
    height: `5' 7"`,
    weight: '169lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: SSimonPelvisReview,
          viewType: ViewTypes.AP_PELVIS,
        },
        {
          id: 2,
          src: SSimonLateralReview,
          viewType: ViewTypes.LATERAL_HIP,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: SSimonPelvisZ1Analyze,
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_1, 
          degeneration: DegenerationLevels.NEAR_ENDSTAGE,
        },
        2: {
          id: 2,
          src: SSimonPelvisZ2Analyze, 
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.MODERATE,
        },
        3: {
          id: 3,
          src: SSimonLateralZ1Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_1,
          degeneration: DegenerationLevels.MODERATE,
        },
        4: {
          id: 4,
          src: SSimonLateralZ2Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.MODERATE,
        },
      },
    },
    carePathway: CarePathways.NON_OPERATIVE,
    proSurvey: SSimonSurvey,
    proGraphs: SSimonGraphs,
    pdf: SSimonPdf,
    proScore: 85,
    TYKPdf: SSimonTYKPdf,
    TYKProSurvey: SSimonTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 7,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 7,
    },
  },
  jPotter: {
    key: 'jPotter',
    name: 'James Potter',
    joint: 'right hip',
    chiefComplaint: '70 year old male who is experiencing right groin and hip pain and he has started to use a cane which limits the distances that he can walk.',
    gender: 'male',
    birthdate: '02/20/1953',
    age: 70,
    height: `6' 1"`,
    weight: '200lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: JPotterPelvisReview,
          viewType: ViewTypes.AP_PELVIS,
        },
        {
          id: 2,
          src: JPotterLateralReview,
          viewType: ViewTypes.LATERAL_HIP,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: JPotterPelvisZ1Analyze,
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_1, 
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        2: {
          id: 2,
          src: JPotterPelvisZ2Analyze, 
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.NEAR_ENDSTAGE,
        },
        3: {
          id: 3,
          src: JPotterLateralZ1Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_1,
          degeneration: DegenerationLevels.ENDSTAGE,
        },
        4: {
          id: 4,
          src: JPotterLateralZ2Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.MODERATE,
        },
      },
    },
    carePathway: CarePathways.TOTAL_REPLACEMENT,
    proSurvey: JPotterSurvey,
    proGraphs: JPotterGraphs,
    pdf: JPotterPdf,
    proScore: 43,
    TYKPdf: JPotterTYKPdf,
    TYKProSurvey: JPotterTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 9,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 8,
    },
  },
  aBrown: {
    key: 'aBrown',
    name: 'Arnold Brown',
    joint: 'left hip',
    chiefComplaint: '65 year old male who is experiencing mild left hip pain and is starting to affect his morning walking routine.',
    gender: 'male',
    birthdate: '11/13/1957',
    age: 65,
    height: `5' 11"`,
    weight: '186lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: ABrownPelvisReview,
          viewType: ViewTypes.AP_PELVIS,
        },
        {
          id: 2,
          src: ABrownLateralReview,
          viewType: ViewTypes.LATERAL_HIP,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: ABrownPelvisZ1Analyze,
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_1, 
          degeneration: DegenerationLevels.MODERATE,
        },
        2: {
          id: 2,
          src: ABrownPelvisZ2Analyze, 
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.NORMAL,
        },
        3: {
          id: 3,
          src: ABrownLateralZ1Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_1,
          degeneration: DegenerationLevels.MODERATE,
        },
        4: {
          id: 4,
          src: ABrownLateralZ2Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.NORMAL,
        },
      },
    },
    carePathway: CarePathways.NON_OPERATIVE,
    proSurvey: ABrownSurvey,
    proGraphs: ABrownGraphs,
    pdf: ABrownPdf,
    proScore: 80,
    TYKPdf: ABrownTYKPdf,
    TYKProSurvey: ABrownTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 10,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 9,
    },
  },
  jSilva: {
    key: 'jSilva',
    name: 'José Silva',
    joint: 'right hip',
    chiefComplaint: '72 year old male who is experiencing moderate to severe right hip pain and is limiting his range of hip mobility.',
    gender: 'male',
    birthdate: '10/03/1949',
    age: 75,
    height: `6' 2"`,
    weight: '213lbs',
    xrays: {
      forReview: [
        {
          id: 1,
          src: JSilvaPelvisReview,
          viewType: ViewTypes.AP_PELVIS,
        },
        {
          id: 2,
          src: JSilvaLateralReview,
          viewType: ViewTypes.LATERAL_HIP,
        },
      ],
      forAnalysis: {
        1: {
          id: 1,
          src: JSilvaPelvisZ1Analyze,
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_1, 
          degeneration: DegenerationLevels.NORMAL,
        },
        2: {
          id: 2,
          src: JSilvaPelvisZ2Analyze, 
          viewType: ViewTypes.AP_PELVIS,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.MODERATE,
        },
        3: {
          id: 3,
          src: JSilvaLateralZ1Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_1,
          degeneration: DegenerationLevels.NORMAL,
        },
        4: {
          id: 4,
          src: JSilvaLateralZ2Analyze,
          viewType: ViewTypes.LATERAL_HIP,
          compartment: JointCompartments.ZONE_2,
          degeneration: DegenerationLevels.NORMAL,
        },
      },
    },
    carePathway: CarePathways.NON_OPERATIVE,
    proSurvey: JSilvaSurvey,
    proGraphs: JSilvaGraphs,
    pdf: JSilvaPdf,
    proScore: 61,
    TYKPdf: JSilvaTYKPdf,
    TYKProSurvey: JSilvaTYKSurvey,
    [RouteKeys.SBS_TRAINING]: initPatientFlowState,
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: initPatientFlowState,
    flowOrders: {
      [RouteKeys.SBS_TRAINING]: 8,
      [RouteKeys.TEST_YOUR_KNOWLEDGE]: 10,
    },
  },
};
