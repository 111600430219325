import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/templates/App';
import { BrowserRouter } from "react-router-dom";
import { GlobalStateProvider } from './hooks/useGlobalState';
import { LayoutStateProvider } from './hooks/useLayoutState';
import './styles/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <LayoutStateProvider>
      <GlobalStateProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GlobalStateProvider>
    </LayoutStateProvider>
  </React.StrictMode>
);
