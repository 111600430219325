import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Surface } from '../../atoms/Surface';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import { ReactComponent as FullLeftHip } from '../../../assets/images/FULLLEFTHIP.svg';
import { ReactComponent as FullRightHip } from '../../../assets/images/FULLRIGHTHIP.svg';
import { ReactComponent as FullLeftKnee } from '../../../assets/images/FULLLEFTKNEE.svg';
import { ReactComponent as FullRightKnee } from '../../../assets/images/FULLRIGHTKNEE.svg';
import './PreliminaryReviewStep.scss';

// patient flow step 2 - the preliminary patient details overview

const PreliminaryReviewStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    nextStep,
    prevStep,
  } = useGlobalState({ flow });

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  if (!activePatient) return null;

  return (
    <div className='PreliminaryReviewStep__Page'>
      <div className='PreliminaryReviewStep__Container'>
        <div className='PreliminaryReviewStep__Left'>
          <div className='PreliminaryReviewStep__HeaderContainer'>
            {isTrainingFlow && <h3>Joint iQ Training</h3>}
            {isTestingFlow && <h3>Test Your Knowledge</h3>}
            <p className='PreliminaryReviewStep__SubHeader'>
              Review the patient's primary concerns
            </p>
          </div>
          <Surface fullWidth className='PreliminaryReviewStep__ComplaintDetails'>
            <h4 className='PreliminaryReviewStep__ComplaintDetailsPatientName'>
              {isTrainingFlow && activePatient.name}
              {isTestingFlow && <>Patient {activePatientNumber}</>}
            </h4>
            <p className='PreliminaryReviewStep__Label'>Chief Complaint</p>
            <p>{activePatient.chiefComplaint}</p>
            {activePatient.historyOfIllness && <p>History of present illness: {activePatient.historyOfIllness}</p>}
          </Surface>
          <Surface fullWidth variant='smooth' className='PreliminaryReviewStep__PatientDemographics'>
            <p className='PreliminaryReviewStep__Label'>Patient Demographics</p>
            <div className='PreliminaryReviewStep__DemographicsCards'>
              <div className='PreliminaryReviewStep__DemographicsCard'>
                <p><span>Name:</span> {isTrainingFlow ? activePatient.name : `Patient ${activePatientNumber}`}</p>
                <p><span>Gender:</span> {activePatient.gender}</p>
              </div>
              <div className='PreliminaryReviewStep__DemographicsCard'>
                <p><span>Height:</span> {activePatient.height}</p>
                <p><span>Weight:</span> {activePatient.weight}</p>
              </div>
              <div className='PreliminaryReviewStep__DemographicsCard'>
                <p><span>Birthdate:</span> {activePatient.birthdate}</p>
                <p><span>Age:</span> {activePatient.age}</p>
              </div>
            </div>
          </Surface>
        </div>
        <div className='PreliminaryReviewStep__Right'>
          <h4 className='PreliminaryReviewStep__RightSubHeader'>Joint Priority and Pain</h4>
          {activePatient.joint === 'left hip' && <FullLeftHip />}
          {activePatient.joint === 'right hip' && <FullRightHip />}
          {activePatient.joint === 'left knee' && <FullLeftKnee />}
          {activePatient.joint === 'right knee' && <FullRightKnee />}
        </div>
      </div>
      <NavigationFooter
        next={{
          action: nextStep,
          label: flow === RouteKeys.SBS_TRAINING ? 'Start Training' : 'Begin Exam',
        }}
        prev={{ action: prevStep, tag: 'to Patient List' }}
      />
    </div>
  );
};

export default PreliminaryReviewStep;
