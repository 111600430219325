export enum ViewTypes {
  FLEX = 'flexion',
  NON_FLEX = 'no flexion',
  KNEECAP = 'kneecap',
  LATERAL_KNEE = 'lateral knee',
  AP_PELVIS = 'AP pelvis',
  LATERAL_HIP = 'lateral hip',
}

export enum DegenerationLevels {
  NORMAL = 'normal / slight',
  MODERATE = 'moderate',
  NEAR_ENDSTAGE = 'near-End stage',
  ENDSTAGE = 'endstage',
}

export enum JointCompartments {
  ZONE_1 = 'zone 1',
  ZONE_2 = 'zone 2',
  MEDIAL = 'medial',
  LATERAL = 'lateral',
  KNEECAP = 'kneecap',
}

export interface ExampleXray {
  id: number;
  src: string;
  viewType: ViewTypes;
}

export interface XrayForAnalysis extends ExampleXray {
  degeneration: DegenerationLevels;
  compartment: JointCompartments;
}
