import React, { useState, HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '../Modal';
import './XrayTile.scss';

type XrayTileProps = {
  src: string;
  label?: string;
  labelPosition?: 'above' | 'below';
  clickToZoom?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const XrayTile: React.FC<XrayTileProps> = ({
  src,
  label,
  labelPosition = 'above',
  clickToZoom,
  ...props
}) => {
  const [showZoom, setShowZoom] = useState<boolean>(false);

  return (
    <div>
      {showZoom && (
        <Modal closeModal={() => setShowZoom(false)}>
          <img
            src={src}
            className='XrayTile__XrayLarge'
            alt="Patient Xray"
            draggable="false"
          />
        </Modal>
      )}
      <div
        {...props}
        className={`XrayTile__Container ${props.className
          ? props.className
          : ''} ${clickToZoom ? 'xt-pointer' : ''}`}
        onClick={clickToZoom ? () => setShowZoom(true) : () => { }}
        onKeyDown={clickToZoom ? () => setShowZoom(true) : () => { }}
        tabIndex={clickToZoom ? 0 : undefined}
        role={clickToZoom ? 'button' : undefined}
      >
        {label && labelPosition === 'above' && <h4 className='XrayTile__LabelAbove'>{label}</h4>}
        <div className='XrayTile__XrayContainer'>
          {clickToZoom && (
            <FontAwesomeIcon
              size='2x'
              icon={faMagnifyingGlass}
              className='XrayTile__Icon'
            />
          )}
          <img
            className='XrayTile__Xray'
            src={src}
            alt="Patient Xray"
            draggable={false}
          />
        </div>
        {label && labelPosition === 'below' && <h4 className='XrayTile__LabelBelow'>{label}</h4>}
      </div>
    </div>
  );
};

export default XrayTile;
