import React, { useState } from 'react';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CornerTag } from '../../atoms/CornerTag';
import { RouteKeys } from '../../../data/routeKeys';
import { StepProps } from '../../../models/StepProps';
import './PatientSurveySubStep.scss';

// patient flow step 4 - viewing the patient's PRO responses and charts

const PatientSurveySubStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    closeActiveSubStep,
    completeActiveSubStep,
  } = useGlobalState({ flow });

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  enum SurveySubStepScreens {
    SURVEY = 'survey',
    GRAPHS = 'graphs',
  };

  const [activeScreen, setActiveScreen] = useState<SurveySubStepScreens>(SurveySubStepScreens.SURVEY);
  const [surveyLoaded, setSurveyLoaded] = useState<boolean>(false);
  const [graphLoaded, setGraphLoaded] = useState<boolean>(false);

  const next = () => activeScreen === SurveySubStepScreens.SURVEY
    ? setActiveScreen(SurveySubStepScreens.GRAPHS)
    : completeActiveSubStep();
  
  const prev = () => activeScreen === SurveySubStepScreens.SURVEY
    ? closeActiveSubStep()
    : setActiveScreen(SurveySubStepScreens.SURVEY);

  return (
    <div className='PatientSurveySubStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient?.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='PatientSurveySubStep__Container'>
        {activeScreen === SurveySubStepScreens.SURVEY && (
          <img
            className={`PatientSurveySubStep__Survey ${surveyLoaded ? 'psss-loaded' : ''}`}
            src={isTrainingFlow ? activePatient?.proSurvey : activePatient?.TYKProSurvey}
            alt="Patient Survey"
            onLoad={() => setSurveyLoaded(true)}
          />
        )}
        {activeScreen === SurveySubStepScreens.GRAPHS && (
          <img
            className={`PatientSurveySubStep__Graphs ${graphLoaded ? 'psss-loaded' : ''}`}
            src={activePatient?.proGraphs}
            alt="Patient Graphs"
            onLoad={() => setGraphLoaded(true)}
          />
        )}
      </div>
      <NavigationFooter prev={{ action: prev }} next={{ action: next }} />
    </div>
  );
};

export default PatientSurveySubStep;
