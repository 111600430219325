import React, { ElementType, useRef } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';
import { isMobile } from 'react-device-detect';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { educationFlowMap } from '../../../data/educationFlow';
import { RouteKeys } from '../../../data/routeKeys';
import { Step } from '../../../models/Flows';
import { EducationFlowStepKeys } from '../../../data/educationFlowKeys';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faX } from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../../data/routes';
import './EducationNavPanel.scss';

interface EducationNavPanelProps {
  flow: RouteKeys.KNEE_EDU | RouteKeys.HIP_EDU;
  activeStep: Step | undefined | null;
}

type NavItemProps = {
  children: React.ReactNode;
  active?: boolean;
  isComplete?: boolean;
  hideConnector?: boolean;
} & AriaButtonOptions<ElementType>;

const NavItem: React.FC<NavItemProps> = (props) => {
  const {
    children,
    active,
    isComplete,
    hideConnector,
    isDisabled,
  } = props;

  const buttonRef = useRef(null);

  const { buttonProps } = useButton({
    ...props,
    elementType: 'div',
  }, buttonRef);

  return (
    <div
      {...buttonProps}
      className={`EducationNavPanel__NavItem ${isDisabled ? 'enpni-disabled' : ''}`}
      ref={buttonRef}
    >
      <div className='EducationNavPanel__NavItemBulletContainer'>
        <div className={`EducationNavPanel__NavItemBullet ${isDisabled ? 'enpni-disabled' : ''}`}/>
        {!hideConnector && (
          <div className='EducationNavPanel__NavItemConnectorContainer'>
            <div
              className='EducationNavPanel__NavItemConnector'
              style={{ transform: `translateY(${isComplete ? '0' : '-100%'})` }}
            />
          </div>
        )}
      </div>
      <h5 className={`EducationNavPanel__NavItemLabel ${active ? 'enpni-active' : ''} ${isDisabled ? 'enpni-disabled' : ''}`}>{children}</h5>
    </div>
  );
};

const EducationNavPanel: React.FC<EducationNavPanelProps> = (props) => {
  const { flow, activeStep } = props;

  const navigate = useNavigate();

  const { educationDrawerOpen, toggleEducationDrawerOpen } = useLayoutState();

  const jointsMap: {[K in RouteKeys]?: 'hip' | 'knee'} = {
    [RouteKeys.KNEE_EDU]: 'knee',
    [RouteKeys.HIP_EDU]: 'hip',
  };

  const closeButtonref = useRef(null);
  const openButtonRef = useRef(null);

  const { buttonProps: closeButtonProps } = useButton({
    ...props,
    elementType: 'div',
    onPress: toggleEducationDrawerOpen,
  }, closeButtonref);

  const { buttonProps: openButtonProps } = useButton({
    ...props,
    elementType: 'div',
    onPress: toggleEducationDrawerOpen,
  }, openButtonRef);

  const handleNavItemClick = (step: Step) => {
    navigate(`${routes[flow].route}${step.route}`);
    if (isMobile) toggleEducationDrawerOpen();
  };

  return (
    <div className='EducationNavPanel__OuterContainer'>
      <div className={`EducationNavPanel__Container ${!educationDrawerOpen ? 'enp-closed' : ''}`}>
        <div
          {...closeButtonProps}
          className='EducationNavPanel__CloseBtn'
          ref={closeButtonref}
        >
          <FontAwesomeIcon icon={faX} />
        </div>
        <h4 className='EducationNavPanel__Header'>
          {flow === RouteKeys.HIP_EDU && 'Hip Education Module'}
          {flow === RouteKeys.KNEE_EDU && 'Knee Education Module'}
        </h4>
        {Object.values(educationFlowMap)
          .filter((step) => step.generateNavLabel)
          .map((step, i, a) => (
            <NavItem
              key={step.key}
              active={activeStep!.key === step.key}
              isComplete={i < a.indexOf(activeStep!) || activeStep?.key === EducationFlowStepKeys.Complete}
              isDisabled={activeStep?.key !== EducationFlowStepKeys.Complete && i > a.indexOf(activeStep!)}
              hideConnector={i === a.length - 1}
              onPress={() => handleNavItemClick(step)}
            >
              {step.generateNavLabel!(jointsMap[flow])}
            </NavItem>
        ))}
      </div>
      <div
        {...openButtonProps}
        className='EducationNavPanel__DrawerHandle'
        style={educationDrawerOpen ? { width: '0rem', border: 'none', opacity: 0 } : undefined}
        ref={openButtonRef}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};

export default EducationNavPanel;
