import React, { useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CornerTag } from '../../atoms/CornerTag';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { getJoint, getWorstCaseDeg, sortXrayEntiresByGroupKey } from '../../../helpers/helpers';
import { PROScoreIndicator } from '../../atoms/PROScoreIndicator';
import { DegenerationIndicator } from '../../atoms/DegenerationIndicator';
import { Button } from '../../atoms/Button';
import { CareMatrix } from '../../molecules/CareMatrix';
import { SurgeryMatrix } from '../../molecules/SurgeryMatrix';
import { CarePathways } from '../../../models/ExamplePatient';
import { JointCompartments, XrayForAnalysis } from '../../../models/ExampleXray';
import { RouteKeys } from '../../../data/routeKeys';
import { StepProps } from '../../../models/StepProps';
import { PatientFlowStepKeys } from '../../../data/patientFlowKeys';
import { ReactComponent as CareMatrixIcon } from '../../../assets/icons/care_matrix_icon.svg';
import './CarePathwaySelectionStep.scss';

// patient flow step 8 - the user is presented with the care matrix and asked
// to determine what treatment pathway the patient should be a candidate for

const CarePathwaySelectionStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    updatePatientFlowState,
    completeActiveStep,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  const firstSelectedOption = activePatient 
    ? activePatient[flow as RouteKeys.SBS_TRAINING | RouteKeys.TEST_YOUR_KNOWLEDGE].carePathwaySelection
    : null;
  const stepComplete = activePatient 
    ? activePatient[flow as RouteKeys.SBS_TRAINING | RouteKeys.TEST_YOUR_KNOWLEDGE][PatientFlowStepKeys.CarePathwaySelection]
    : null;

  const joint = getJoint(activePatient?.joint);

  const isSelectionCorrect = (selection?: CarePathways | 'op' | null) => {
    if (joint === 'hip' && selection === 'op' && activePatient?.carePathway === CarePathways.TOTAL_REPLACEMENT) return true;
    return selection === activePatient?.carePathway;
  };

  const lockedInFirstAnswerCorrect = isSelectionCorrect(firstSelectedOption);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [showCorrectCell, setShowCorrectCell] = useState<boolean>(false);
  const [disableNextBtn, setDisableNextBtn] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(!!(stepComplete || lockedInFirstAnswerCorrect));
  const [nonLockedSelection, setNonLockedSelection] = useState<CarePathways | 'op' | null>(
    stepComplete ? activePatient!.carePathway : null
  );
  const [showSecondaryButtons, setShowSecondaryButtons] = useState<boolean>(
    (stepComplete && joint !== 'hip' && activePatient?.carePathway !== CarePathways.NON_OPERATIVE)
    || (
      joint !== 'hip'
      && lockedInFirstAnswerCorrect
      && firstSelectedOption !== CarePathways.NON_OPERATIVE
    )
  );

  if (!activePatient || (!isTrainingFlow && !isTestingFlow)) return null;

  const nonLockedInAnswerCorrect = isSelectionCorrect(nonLockedSelection);

  const handleSelectionClick = (selection: CarePathways | 'op') => {
    if (lockedInFirstAnswerCorrect || stepComplete) return;
    setDisableNextBtn(false);
    if (showResult && !nonLockedInAnswerCorrect) setShowResult(false);
    if (selection === 'op') {
      if (joint === 'hip' || activePatient.carePathway === CarePathways.NON_OPERATIVE) {
        setNonLockedSelection(selection);
        return;
      }
      setNonLockedSelection(null);
      setShowSecondaryButtons(true);
      return;
    }
    if (selection === CarePathways.NON_OPERATIVE && showSecondaryButtons) {
      setNonLockedSelection(selection);
      setShowSecondaryButtons(false);
      return;
    };
    if (nonLockedSelection && nonLockedSelection === selection) setNonLockedSelection(null);
    setNonLockedSelection(selection);
  };

  const handleNextClick = () => {
    if (!nonLockedInAnswerCorrect) setDisableNextBtn(true);
    if (!showResult) {
      setShowResult(true);

      if (!firstSelectedOption) updatePatientFlowState({ ...activePatient[flow], carePathwaySelection: nonLockedSelection });

      if (!nonLockedInAnswerCorrect) {
        setShowCorrectCell(true);
        setDrawerOpen(true);
      }
    } else {
      if (lockedInFirstAnswerCorrect || nonLockedInAnswerCorrect) {
        completeActiveStep();
        nextStep();
      };
    }
  };

  const handleShowMatrixBtnClick = () => {
    setShowCorrectCell(false);
    setDrawerOpen(true);
  };

  type GroupKeyOptions = JointCompartments;

  const groupKeySet = {} as Record<GroupKeyOptions, boolean>;

  for (const item of Object.values(activePatient!.xrays.forAnalysis)) {
    groupKeySet[item.compartment] = true;
  };

  const groupedXrays = {} as Record<GroupKeyOptions, XrayForAnalysis[]>;

  Object.keys(groupKeySet).forEach((type) => {
    groupedXrays[type as GroupKeyOptions] = [...Object.values(activePatient!.xrays.forAnalysis).filter((xray) => xray.compartment === type)];
  });

  const groupedAndSortedXrays = Object.entries(groupedXrays).sort((a, b) => sortXrayEntiresByGroupKey(a, b, joint));

  return (
    <div className={`CarePathwaySelectionStep__Page ${drawerOpen ? 'drawer-open' : ''}`}>
      <CornerTag>
        {isTrainingFlow && activePatient!.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className={`CarePathwaySelectionStep__Container ${drawerOpen ? 'drawer-open' : ''}`}>
        <h3 className={`CarePathwaySelectionStep__Header ${drawerOpen ? 'drawer-open' : ''}`}>
          Select the Care Pathway: {activePatient.joint}
        </h3>
        <div className='CarePathwaySelectionStep__MainContent'>
          <div className={`CarePathwaySelectionStep__MainContentL ${drawerOpen ? 'drawer-open' : ''}`}>
            <div className='CarePathwaySelectionStep__MainContentL--Top'>
              <div className='CarePathwaySelectionStep__Outcomes'>
                {joint === 'knee' && groupedAndSortedXrays.map(([compartment, xrayGroup]) => (
                  <div key={compartment} className={`CarePathwaySelectionStep__IndicatorContainer ${joint}`}>
                    <p>{compartment}</p>
                    <DegenerationIndicator
                      className='CarePathwaySelectionStep__DegIndicator'
                      degLvl={getWorstCaseDeg(xrayGroup)}
                    />
                  </div>
                ))}
                {joint === 'hip' && (
                  <div className={`CarePathwaySelectionStep__IndicatorContainer ${joint}`}>
                    <p>X-rays</p>
                    <DegenerationIndicator
                      className='CarePathwaySelectionStep__DegIndicator'
                      degLvl={getWorstCaseDeg(Object.values(activePatient.xrays.forAnalysis))}
                    />
                  </div>
                )}
                <div className={`CarePathwaySelectionStep__IndicatorContainer ${joint}`}>
                  <p>
                    Overall <br /> <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>{joint} Health</span>
                  </p>
                  <PROScoreIndicator
                    className='CarePathwaySelectionStep__DegIndicator'
                    PROScore={activePatient.proScore}
                  />
                </div>
              </div>
              <div className={`CarePathwaySelectionStep__DrawerToggle ${drawerOpen ? 'drawer-open' : ''}`}>
                <Button
                  onPress={handleShowMatrixBtnClick}
                  icon={<CareMatrixIcon className='CarePathwaySelectionStep__DrawerToggleIcon' />}
                >
                  View the care matrix
                </Button>
              </div>
            </div>
            <div className='CarePathwaySelectionStep__PathwayOptionBtns'>
              <div className='CarePathwaySelectionStep__BtnsContainer'>
                <div className={`CarePathwaySelectionStep__PrimaryBtns ${showSecondaryButtons ? 'cpss-sb-open' : ''}`}>
                  <Button
                    fullWidth
                    variant='mixed'
                    onPress={() => handleSelectionClick(CarePathways.NON_OPERATIVE)}
                    selected={
                      (firstSelectedOption === CarePathways.NON_OPERATIVE && activePatient.carePathway === CarePathways.NON_OPERATIVE)
                      || nonLockedSelection === CarePathways.NON_OPERATIVE
                    }
                  >
                    Non-Operative Candidate
                  </Button>
                  <Button
                    fullWidth
                    variant='mixed'
                    onPress={() => handleSelectionClick('op')}
                    selected={
                      stepComplete
                        ? activePatient.carePathway !== CarePathways.NON_OPERATIVE || nonLockedSelection === 'op'
                        : nonLockedSelection === 'op' || showSecondaryButtons
                    }
                    isDisabled={showSecondaryButtons}
                  >
                    Surgical Candidate
                  </Button>
                </div>
                <div className={`CarePathwaySelectionStep__SecondaryBtns ${showSecondaryButtons ? 'cpss-sb-open' : ''}`}>
                  <Button
                    fullWidth
                    variant='mixed'
                    onPress={() => handleSelectionClick(CarePathways.PARTIAL_REPLACEMENT)}
                    selected={
                      (firstSelectedOption === CarePathways.PARTIAL_REPLACEMENT && activePatient.carePathway === CarePathways.PARTIAL_REPLACEMENT)
                      || nonLockedSelection === CarePathways.PARTIAL_REPLACEMENT
                    }
                  >
                    {CarePathways.PARTIAL_REPLACEMENT} Replacement
                  </Button>
                  <Button
                    fullWidth
                    variant='mixed'
                    onPress={() => handleSelectionClick(CarePathways.TOTAL_REPLACEMENT)}
                    selected={
                      (firstSelectedOption === CarePathways.TOTAL_REPLACEMENT && activePatient.carePathway === CarePathways.TOTAL_REPLACEMENT)
                      || nonLockedSelection === CarePathways.TOTAL_REPLACEMENT
                    }
                  >
                    {CarePathways.TOTAL_REPLACEMENT} Replacement
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={`CarePathwaySelectionStep__MainContentR ${drawerOpen ? 'drawer-open' : ''}`}>
            <div className='CarePathwaySelectionStep__CloseDrawerBtn'>
              <span
                onClick={() => setDrawerOpen(false)}
                onKeyDown={() => setDrawerOpen(false)}
                role="button"
                tabIndex={0}
              >
                X
              </span>
            </div>
            <h2 className='CarePathwaySelectionStep__DrawerHeader'>SBS Care Matrix</h2>
            <div className='CarePathwaySelectionStep__MatrixContainer'>
              {!showSecondaryButtons && <div className='CarePathwaySelectionStep__MatrixContainerL'>
                <p className='CarePathwaySelectionStep__MatrixLabel left'>
                  Worst Degeneration in X-Rays
                </p>
              </div>}
              <div className='CarePathwaySelectionStep__MatrixContainerR'>
                {!showSecondaryButtons && <p className='CarePathwaySelectionStep__MatrixLabel'>
                  PRO Scores
                </p>}
                {showSecondaryButtons
                  ? (
                    <SurgeryMatrix
                      patient={activePatient}
                      hideHighlight={!showCorrectCell}
                    />
                  )
                  : (
                    <CareMatrix
                      patient={activePatient}
                      hideHighlight={!showCorrectCell}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigationFooter
        className={`CarePathwaySelectionStep__Nav ${drawerOpen ? 'drawer-open' : ''}`}
        prev={{ action: prevStep }}
        next={{
          action: handleNextClick,
          label: showResult && (lockedInFirstAnswerCorrect || nonLockedInAnswerCorrect) ? 'Next' : 'Confirm',
          disabled: disableNextBtn || (lockedInFirstAnswerCorrect ? false : !nonLockedSelection),
        }}
        centerElement={(
          <>
            {(stepComplete || (showResult && (lockedInFirstAnswerCorrect || nonLockedInAnswerCorrect))) && (
              <h3 className='CarePathwaySelectionStep__PathwayResult success'>Correct!</h3>
            )}
            {!stepComplete && showResult && !(lockedInFirstAnswerCorrect || nonLockedInAnswerCorrect) && (
              <h3 className='CarePathwaySelectionStep__PathwayResult error'>Try Again.</h3>
            )}
          </>
        )}
      />
    </div>
  );
};

export default CarePathwaySelectionStep;
