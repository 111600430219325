import React, { useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { XrayEvaluationSubStep } from '../XrayEvaluationSubStep';
import { examplePatientsMap } from '../../../data/examplePatients';
import { ExamplePatient } from '../../../models/ExamplePatient';
import { XrayForAnalysis } from '../../../models/ExampleXray';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';

// corresponding page in app:
// https://education.jointai.co/knee-education/xray-matching

const XrayMatchingStep: React.FC<StepProps> = ({ flow }) => {
  const {
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const isKneeFlow = flow === RouteKeys.KNEE_EDU;

  const { aLee, rSmith, jPotter, sSimon } = examplePatientsMap;

  interface EducationMatchingXray {
    patient: ExamplePatient;
    xray: XrayForAnalysis;
  }

  const kneeXrays: EducationMatchingXray[] = [
    {
      patient: rSmith,
      xray: rSmith.xrays.forAnalysis[2],
    },
    {
      patient: rSmith,
      xray: rSmith.xrays.forAnalysis[1],
    },
    {
      patient: aLee,
      xray: aLee.xrays.forAnalysis[5],
    },
  ];

  const hipXrays: EducationMatchingXray[] = [
    {
      patient: jPotter,
      xray: jPotter.xrays.forAnalysis[1]
    },
    {
      patient: sSimon,
      xray: sSimon.xrays.forAnalysis[2],
    },
  ];

  const [activeXrayIdx, setActiveXrayIdx] = useState<number>(0);

  const generateComponent = (
    xrayData: EducationMatchingXray,
    back: () => void,
    next: () => void,
  ) => (
    <XrayEvaluationSubStep
      hidePatientTag
      key={xrayData.xray.id}
      flow={flow}
      patient={xrayData.patient}
      xray={xrayData.xray}
      header='X-ray Matching'
      back={back}
      handleClickNext={next}
    />
  );

  const activeXrays = isKneeFlow ? kneeXrays : hipXrays;
  
  const components: JSX.Element[] = activeXrays.map((xray) => generateComponent(
    xray,
    activeXrayIdx === 0
      ? prevStep
      : () => setActiveXrayIdx(activeXrayIdx - 1),
    activeXrayIdx !== activeXrays.length - 1
      ? () => setActiveXrayIdx(activeXrayIdx + 1)
      : nextStep
  ));

  return components[activeXrayIdx];
};

export default XrayMatchingStep;
