import React, { useRef } from 'react';
import { useToggleState, ToggleProps } from 'react-stately';
import { useCheckbox, useFocusRing, VisuallyHidden } from 'react-aria';
import { XrayTile } from '../../molecules/XrayTile';
import './Checkbox.scss';

type CheckboxProps = {
  children: React.ReactNode;
  imgSrc?: string;
  imgClass?: string;
  checkboxContainerClass?: string;
  iconLabel?: React.ReactNode;
  size?: 'small' | 'normal';
  centerToParent?: boolean;
} & ToggleProps;

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    children,
    imgSrc,
    imgClass,
    checkboxContainerClass,
    iconLabel,
    centerToParent,
    size = 'normal',
  } = props;

  const state = useToggleState(props);

  const ref = useRef(null);
  const { inputProps } = useCheckbox(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const isSelected = state.isSelected;

  return (
    <label className='Checkbox__OuterContainer'>
      {imgSrc && <XrayTile className={`Checkbox__Img ${imgClass ? imgClass : ''}`} src={imgSrc} />}
      <div className='Checkbox__InnerContainer'>
        <div className={`Checkbox__Container ${checkboxContainerClass} ${size} ${centerToParent ? 'xss-center' : ''}`}>
          {iconLabel && iconLabel}
          <VisuallyHidden>
            <input
              {...inputProps}
              {...focusProps}
              ref={ref}
            />
          </VisuallyHidden>
          <div className={`Checkbox__StateIndicator ${isFocusVisible ? 'focus' : ''} ${size}`}>
            <div className={`Checkbox__StateIndicatorInner ${isSelected ? 'checked' : ''}`} />
          </div>
          {children}
        </div>
      </div>
    </label>
  );
};

export default Checkbox;
