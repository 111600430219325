import React from 'react';
import { SubtitleButton } from '../../atoms/SubtitleButton';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Surface } from '../../atoms/Surface';
import { DynamicJointIndicator } from '../../molecules/DynamicJointIndicator';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { RouteKeys } from '../../../data/routeKeys';
import { StepProps } from '../../../models/StepProps';
import { calculateTestScore, getJoint } from '../../../helpers/helpers';
import './SelectPatientStep.scss';

// patient flow step 1 - selecting which patient to test on

const SelectPatientStep: React.FC<StepProps> = ({ flow }) => {
  const {
    patients,
    activePatient,
    setActivePatient,
    viewPatientExamScore,
    nextStep,
    prevStep,
  } = useGlobalState({ flow });

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;
  if (!isTrainingFlow && !isTestingFlow) return null;

  const allPatients = Object.values(patients).sort((a, b) => a.flowOrders[flow] - b.flowOrders[flow]);
  const kneePatients = allPatients.filter((patient) => getJoint(patient.joint) === 'knee');
  const hipPatients = allPatients.filter((patient) => getJoint(patient.joint) === 'hip');

  const generatePatientButtons = (joint: 'hip' | 'knee') => (
    <div className={`SelectPatientStep__PatientButtons sps-${joint}`}>
      <h4 className='SelectPatientStep__ButtonsLabel'>{joint} Patients:</h4>
      {(joint === 'hip' ? hipPatients : kneePatients).map((patient, idx, arr) => (
        <div key={patient.name} className='SelectPatientStep__PatientButtonContainer'>
          <SubtitleButton
            fullWidth
            subtitle={`${patient.joint} Pain`}
            fillBg={patient[flow].patientComplete}
            icon={
              patient[flow].patientComplete
                ? (
                  <div className='SelectPatientStep__CompleteTag'>
                    <p>Complete</p>
                  </div>
                )
                : null
            }
            onPress={() => setActivePatient(activePatient?.name === patient.name ? null : patient.key)}
            active={activePatient?.name === patient.name}
            isDisabled={isTrainingFlow && idx !== 0 && !arr[idx - 1][flow].patientComplete}
          >
            {isTrainingFlow
              ? patient.name
              : `Patient ${allPatients.indexOf(patient) + 1}`
            }
          </SubtitleButton>
          {isTestingFlow && (
            <Surface variant='smooth' className='SelectPatientStep__ScoreContainer'>
              {idx === 0 && <p className='SelectPatientStep__ScoreLabel'>Score</p>}
              {patient[flow].patientComplete && (
                <>
                  <h4>
                    {`${calculateTestScore({ patient, flow, includeCarePathway: true }).percentage}%`}
                  </h4>
                  <span
                    className='SelectPatientStep__ScoreBreakdownBtn'
                    role="button"
                    tabIndex={0}
                    onClick={() => viewPatientExamScore(patient)}
                    onKeyDown={() => viewPatientExamScore(patient)}
                  >
                    Score Breakdown
                  </span>
                </>
              )}
            </Surface>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className='SelectPatientStep__Page'>
      <section className='SelectPatientStep__HeaderContainer'>
        <h3 className='SelectPatientStep__Header'>
          {isTrainingFlow && <>Joint iQ Training</>}
          {isTestingFlow && <>Test Your Knowledge</>}
        </h3>
        <p className='SelectPatientStep__SubHeader'>
          {isTrainingFlow && (
            <>
              Hone your step by step care pathway selection
              and evaluation skills with these patients.
            </>
          )}
          {isTestingFlow && (
            <>
              Evaluate and recommend a care pathway for each sample patient.
              Step by Step will review how you did after you complete your evaluation.
            </>
          )}
        </p>
      </section>
      <div className='SelectPatientStep__Container'>
        <section className='SelectPatientStep__MainContent'>
          {generatePatientButtons('knee')}
          <DynamicJointIndicator className='SelectPatientStep__DynamicJointIndicator' />
          {generatePatientButtons('hip')}
        </section>
      </div>
      <NavigationFooter
        className='SelectPatientStep__Footer'
        next={{
          action: nextStep,
          disabled: !activePatient,
          label: !!activePatient && activePatient[flow].patientComplete ? 'Review Test' : 'Next'
        }}
        prev={{ action: prevStep, tag: 'to Home' }}
      />
    </div>
  );
};

export default SelectPatientStep;
