import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { RouteKeys } from '../../../data/routeKeys';
import { EducationNavPanel } from '../../molecules/EducationNavPanel';
import './KneeEdu.scss';

// wrapper for the knee education module

const KneeEdu: React.FC = () => {
  // this flow variable is fed into useGlobalState below to determine
  // the active step based on the user's actions and the current module
  // they're viewing, along with that step's corresponding component
  const flow = RouteKeys.KNEE_EDU

  const { activeStepComponent, activeStep } = useGlobalState({ flow });

  return (
    <div className='KneeEdu__Page'>
      <EducationNavPanel flow={flow} activeStep={activeStep} />
      {activeStepComponent}
    </div>
  );
};

export default KneeEdu;
