import { StepKeys } from "../models/Flows";
import { EducationFlowStepKeys } from "./educationFlowKeys";
import { RouteKeys } from "./routeKeys";

// videos used across various steps in the education flow

type VideoData = {
  url: string;
  headline: string;
}

type EducationVideoMapType = {
  [K in RouteKeys]?: {
    [K in StepKeys]?: VideoData;
  };
}

export const educationVideos: EducationVideoMapType = {
  [RouteKeys.KNEE_EDU]: {
    [EducationFlowStepKeys.JointArthritisEduVideo]: {
      url: 'https://vimeo.com/920175157',
      headline: 'Knee Arthritis Education',
    },
    [EducationFlowStepKeys.JointArthritisEvalVideo]: {
      url: 'https://vimeo.com/920157626',
      headline: 'Evaluating Arthritis with X-rays'
    },
    [EducationFlowStepKeys.SampleEvalVideo]: {
      url: 'https://vimeo.com/920157700',
      headline: 'Sample Knee Evaluation'
    },
    [EducationFlowStepKeys.CareRecommendationVideo]: {
      url: 'https://vimeo.com/920157396',
      headline: 'Recommending a Care Pathway'
    },
    [EducationFlowStepKeys.XraySelectionVideo]: {
      url: 'https://vimeo.com/920179196',
      headline: 'How to Select X-ray Views'
    },
    [EducationFlowStepKeys.ConfirmingEvaluations]: {
      url: 'https://vimeo.com/920157616',
      headline: 'Confirming Knee X-ray Evaluations',
    },
  },
  [RouteKeys.HIP_EDU]: {
    [EducationFlowStepKeys.JointArthritisEduVideo]: {
      url: 'https://vimeo.com/920156191',
      headline: 'Hip Arthritis Education',
    },
    [EducationFlowStepKeys.JointArthritisEvalVideo]: {
      url: 'https://vimeo.com/920156159',
      headline: 'Evaluating Arthritis with X-rays'
    },
    [EducationFlowStepKeys.SampleEvalVideo]: {
      url: 'https://vimeo.com/920156242',
      headline: 'Sample Hip Evaluation'
    },
    [EducationFlowStepKeys.CareRecommendationVideo]: {
      url: 'https://vimeo.com/920157396',
      headline: 'Recommending a Care Pathway'
    },
    [EducationFlowStepKeys.XraySelectionVideo]: {
      url: 'https://vimeo.com/920156269',
      headline: 'How to Select X-ray Views'
    },
    [EducationFlowStepKeys.ConfirmingEvaluations]: {
      url: 'https://vimeo.com/920156121',
      headline: 'Confirming Hip X-ray Evaluations',
    },
  },
};
