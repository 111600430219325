import { ViewTypes, JointCompartments, DegenerationLevels } from '../models/ExampleXray';

import RKneecapNormal from '../assets/comparisonXrays/RKneecap/right_kneecap_normal.png';
import RKneecapModerate from '../assets/comparisonXrays/RKneecap/right_kneecap_mod.png';
import RKneecapNES from '../assets/comparisonXrays/RKneecap/right_kneecap_nes.png';
import RKneecapES from '../assets/comparisonXrays/RKneecap/right_kneecap_end_stage.png';

import LKneecapNormal from '../assets/comparisonXrays/LKneecap/left_kneecap_normal.png';
import LKneecapModerate from '../assets/comparisonXrays/LKneecap/left_kneecap_mod.png';
import LKneecapNES from '../assets/comparisonXrays/LKneecap/left_kneecap_nes.png';
import LKneecapES from '../assets/comparisonXrays/LKneecap/left_kneecap_end_stage.png';

import RKneeLateralNormal from '../assets/comparisonXrays/RKneeLateral/BL_right_normal_lateral.png';
import RKneeLateralModerate from '../assets/comparisonXrays/RKneeLateral/BL_right_mod_lateral.png';
import RKneeLateralNES from '../assets/comparisonXrays/RKneeLateral/BL_right_nes_lateral.png';
import RKneeLateralES from '../assets/comparisonXrays/RKneeLateral/BL_right_end_stage_lateral.png';

import LKneeLateralNormal from '../assets/comparisonXrays/LKneeLateral/BL_left_normal_lateral.png';
import LKneeLateralModerate from '../assets/comparisonXrays/LKneeLateral/BL_left_mod_lateral.png';
import LKneeLateralNES from '../assets/comparisonXrays/LKneeLateral/BL_left_nes_lateral.png';
import LKneeLateralES from '../assets/comparisonXrays/LKneeLateral/BL_left_end_stage_lateral.png';

import RKneeMedialNormal from '../assets/comparisonXrays/RKneeMedial/BL_right_normal_medial.png';
import RKneeMedialModerate from '../assets/comparisonXrays/RKneeMedial/BL_right_mod_medial.png';
import RKneeMedialNES from '../assets/comparisonXrays/RKneeMedial/BL_right_nes_medial.png';
import RKneeMedialES from '../assets/comparisonXrays/RKneeMedial/BL_right_end_stage_medial.png';

import LKneeMedialNormal from '../assets/comparisonXrays/LKneeMedial/BL_left_normal_medial.png';
import LKneeMedialModerate from '../assets/comparisonXrays/LKneeMedial/BL_left_mod_medial.png';
import LKneeMedialNES from '../assets/comparisonXrays/LKneeMedial/BL_left_nes_medial.png';
import LKneeMedialES from '../assets/comparisonXrays/LKneeMedial/BL_left_end_stage_medial.png';

import RLatHipZ1Normal from '../assets/comparisonXrays/RLatHipZ1/hip_Lateral_Normal_Z1.png';
import RLatHipZ1Moderate from '../assets/comparisonXrays/RLatHipZ1/hip_Lateral_Moderate_Z1.png';
import RLatHipZ1NES from '../assets/comparisonXrays/RLatHipZ1/hip_Lateral_NES_Z1.png';
import RLatHipZ1ES from '../assets/comparisonXrays/RLatHipZ1/hip_Lateral_End Stage_Z1.png';

import RLatHipZ2Normal from '../assets/comparisonXrays/RLatHipZ2/hip_Lateral_Normal_Z2.png';
import RLatHipZ2Moderate from '../assets/comparisonXrays/RLatHipZ2/hip_Lateral_Moderate_Z2.png';
import RLatHipZ2NES from '../assets/comparisonXrays/RLatHipZ2/hip_Lateral_NES_Z2.png';
import RLatHipZ2ES from '../assets/comparisonXrays/RLatHipZ2/hip_Lateral_End Stage_Z2.png';

import RPelvisZ1Normal from '../assets/comparisonXrays/RPelvisZ1/hip_AP_Normal_Z1.png';
import RPelvisZ1Moderate from '../assets/comparisonXrays/RPelvisZ1/hip_AP_Moderate_Z1.png';
import RPelvisZ1NES from '../assets/comparisonXrays/RPelvisZ1/hip_AP_NES_Z1.png';
import RPelvisZ1ES from '../assets/comparisonXrays/RPelvisZ1/hip_AP_ES_Z1.png';

import RPelvisZ2Normal from '../assets/comparisonXrays/RPelvisZ2/hip_AP_Normal_Z2.png';
import RPelvisZ2Moderate from '../assets/comparisonXrays/RPelvisZ2/hip_AP_Moderate_Z2.png';
import RPelvisZ2NES from '../assets/comparisonXrays/RPelvisZ2/hip_AP_NES_Z2.png';
import RPelvisZ2ES from '../assets/comparisonXrays/RPelvisZ2/hip_AP_ES_Z2.png';

//  comparison xrays sorted by joint side, then view type, then compartment, then degeneration level

interface ComparisonXraysMap {
  [key: string]: any
}

export const comparisonXrays: ComparisonXraysMap = {
  right: {
    [ViewTypes.KNEECAP]: {
      [JointCompartments.KNEECAP]: {
        [DegenerationLevels.NORMAL]: RKneecapNormal,
        [DegenerationLevels.MODERATE]: RKneecapModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RKneecapNES,
        [DegenerationLevels.ENDSTAGE]: RKneecapES,
      },
    },
    [ViewTypes.FLEX]: {
      [JointCompartments.LATERAL]: {
        [DegenerationLevels.NORMAL]: RKneeLateralNormal,
        [DegenerationLevels.MODERATE]: RKneeLateralModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RKneeLateralNES,
        [DegenerationLevels.ENDSTAGE]: RKneeLateralES,
      },
      [JointCompartments.MEDIAL]: {
        [DegenerationLevels.NORMAL]: RKneeMedialNormal,
        [DegenerationLevels.MODERATE]: RKneeMedialModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RKneeMedialNES,
        [DegenerationLevels.ENDSTAGE]: RKneeMedialES,
      },
    },
    [ViewTypes.NON_FLEX]: {
      [JointCompartments.LATERAL]: {
        [DegenerationLevels.NORMAL]: RKneeLateralNormal,
        [DegenerationLevels.MODERATE]: RKneeLateralModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RKneeLateralNES,
        [DegenerationLevels.ENDSTAGE]: RKneeLateralES,
      },
      [JointCompartments.MEDIAL]: {
        [DegenerationLevels.NORMAL]: RKneeMedialNormal,
        [DegenerationLevels.MODERATE]: RKneeMedialModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RKneeMedialNES,
        [DegenerationLevels.ENDSTAGE]: RKneeMedialES,
      },
    },
    [ViewTypes.LATERAL_HIP]: {
      [JointCompartments.ZONE_1]: {
        [DegenerationLevels.NORMAL]: RLatHipZ1Normal,
        [DegenerationLevels.MODERATE]: RLatHipZ1Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RLatHipZ1NES,
        [DegenerationLevels.ENDSTAGE]: RLatHipZ1ES,
      },
      [JointCompartments.ZONE_2]: {
        [DegenerationLevels.NORMAL]: RLatHipZ2Normal,
        [DegenerationLevels.MODERATE]: RLatHipZ2Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RLatHipZ2NES,
        [DegenerationLevels.ENDSTAGE]: RLatHipZ2ES,
      },
    },
    [ViewTypes.AP_PELVIS]: {
      [JointCompartments.ZONE_1]: {
        [DegenerationLevels.NORMAL]: RPelvisZ1Normal,
        [DegenerationLevels.MODERATE]: RPelvisZ1Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RPelvisZ1NES,
        [DegenerationLevels.ENDSTAGE]: RPelvisZ1ES,
      },
      [JointCompartments.ZONE_2]: {
        [DegenerationLevels.NORMAL]: RPelvisZ2Normal,
        [DegenerationLevels.MODERATE]: RPelvisZ2Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RPelvisZ2NES,
        [DegenerationLevels.ENDSTAGE]: RPelvisZ2ES,
      },
    },
  },
  left: {
    [ViewTypes.KNEECAP]: {
      [JointCompartments.KNEECAP]: {
        [DegenerationLevels.NORMAL]: LKneecapNormal,
        [DegenerationLevels.MODERATE]: LKneecapModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: LKneecapNES,
        [DegenerationLevels.ENDSTAGE]: LKneecapES,
      },
    },
    [ViewTypes.FLEX]: {
      [JointCompartments.LATERAL]: {
        [DegenerationLevels.NORMAL]: LKneeLateralNormal,
        [DegenerationLevels.MODERATE]: LKneeLateralModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: LKneeLateralNES,
        [DegenerationLevels.ENDSTAGE]: LKneeLateralES,
      },
      [JointCompartments.MEDIAL]: {
        [DegenerationLevels.NORMAL]: LKneeMedialNormal,
        [DegenerationLevels.MODERATE]: LKneeMedialModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: LKneeMedialNES,
        [DegenerationLevels.ENDSTAGE]: LKneeMedialES,
      },
    },
    [ViewTypes.NON_FLEX]: {
      [JointCompartments.LATERAL]: {
        [DegenerationLevels.NORMAL]: LKneeLateralNormal,
        [DegenerationLevels.MODERATE]: LKneeLateralModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: LKneeLateralNES,
        [DegenerationLevels.ENDSTAGE]: LKneeLateralES,
      },
      [JointCompartments.MEDIAL]: {
        [DegenerationLevels.NORMAL]: LKneeMedialNormal,
        [DegenerationLevels.MODERATE]: LKneeMedialModerate,
        [DegenerationLevels.NEAR_ENDSTAGE]: LKneeMedialNES,
        [DegenerationLevels.ENDSTAGE]: LKneeMedialES,
      },
    },
    // need left hip images
    [ViewTypes.LATERAL_HIP]: {
      [JointCompartments.ZONE_1]: {
        [DegenerationLevels.NORMAL]: RLatHipZ1Normal,
        [DegenerationLevels.MODERATE]: RLatHipZ1Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RLatHipZ1NES,
        [DegenerationLevels.ENDSTAGE]: RLatHipZ1ES,
      },
      [JointCompartments.ZONE_2]: {
        [DegenerationLevels.NORMAL]: RLatHipZ2Normal,
        [DegenerationLevels.MODERATE]: RLatHipZ2Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RLatHipZ2NES,
        [DegenerationLevels.ENDSTAGE]: RLatHipZ2ES,
      },
    },
    [ViewTypes.AP_PELVIS]: {
      [JointCompartments.ZONE_1]: {
        [DegenerationLevels.NORMAL]: RPelvisZ1Normal,
        [DegenerationLevels.MODERATE]: RPelvisZ1Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RPelvisZ1NES,
        [DegenerationLevels.ENDSTAGE]: RPelvisZ1ES,
      },
      [JointCompartments.ZONE_2]: {
        [DegenerationLevels.NORMAL]: RPelvisZ2Normal,
        [DegenerationLevels.MODERATE]: RPelvisZ2Moderate,
        [DegenerationLevels.NEAR_ENDSTAGE]: RPelvisZ2NES,
        [DegenerationLevels.ENDSTAGE]: RPelvisZ2ES,
      },
    },
  },
};
