import { PatientFlowStepKeys, ProfileSubStepKeys } from "../data/patientFlowKeys";
import { RouteKeys } from "../data/routeKeys";
import { DegenerationLevels, ExampleXray, XrayForAnalysis } from "./ExampleXray";

export enum CarePathways {
  TOTAL_REPLACEMENT = 'total',
  PARTIAL_REPLACEMENT = 'partial',
  NON_OPERATIVE = 'non-operative',
}

export interface PatientTrainingState {
  [ProfileSubStepKeys.ViewSurvey]: boolean;
  [ProfileSubStepKeys.ReviewXrays]: boolean;
  [PatientFlowStepKeys.JointEvaluation]: boolean;
  [PatientFlowStepKeys.CarePathwaySelection]: boolean;
  carePathwaySelection: CarePathways | 'op' | null;
  patientComplete: boolean;
  selectedXrays: { 
    [key: string]: boolean;
  };
  evaluatedXrays: {
    [key: number]: {
      firstSelection: DegenerationLevels;
      complete: boolean;
    };
  };
}

export interface ExamplePatient {
  key: string;
  name: string;
  joint: 'right knee' | 'left knee' | 'right hip' | 'left hip';
  chiefComplaint: string;
  historyOfIllness?: string;
  gender: 'male' | 'female';
  birthdate: string;
  age: number;
  height: string;
  weight: string;
  xrays: { forAnalysis: { [key: number]: XrayForAnalysis }; forReview: ExampleXray[]; };
  carePathway: CarePathways;
  proScore: number;
  proSurvey: string;
  TYKProSurvey: string;
  proGraphs: string;
  TYKPdf: string;
  pdf: string;
  [RouteKeys.SBS_TRAINING]: PatientTrainingState;
  [RouteKeys.TEST_YOUR_KNOWLEDGE]: PatientTrainingState;
  flowOrders: {
    [RouteKeys.SBS_TRAINING]: number;
    [RouteKeys.TEST_YOUR_KNOWLEDGE]: number;
  };
}
