import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { RouteKeys } from '../../../data/routeKeys';
import './TestYourKnowledge.scss';
import EmailFormGate from '../../organisms/EmailFormGate/EmailFormGate';

// wrapper for the patient test your knowledge flow

const TestYourKnowledge: React.FC = () => {
  // the flow param fed to useGlobalState is used to determine
  // the active step based on the user's actions and the current module
  // they're viewing
  const { activeStepComponent } = useGlobalState({ flow: RouteKeys.TEST_YOUR_KNOWLEDGE });

  return (
    <div className='TestYourKnowledge__Page'>
      <EmailFormGate>
        {activeStepComponent}
      </EmailFormGate>
    </div>
  );
};

export default TestYourKnowledge;
