// keys for each step in the patient flow. each member of this enum must have a
// corresponding Step object in the patientFlowMap object from patientFlow.tsx

export enum PatientFlowStepKeys {
  PatientSelect = 'patientSelectStep',
  PreliminaryReview = 'preliminaryReviewStep',
  PatientProfile = 'patientProfileStep',
  XrayConfirmation = 'xrayConfirmationStep',
  EvaluationReportCard = 'evaluationReportCardStep',
  JointEvaluation = 'jointEvaluationStep',
  CarePathwaySelection = 'carePathwaySelectionStep',
  JointRecommendation = 'jointRecommendationStep',
  OverallReportCard = 'overallReportCardStep',
  Summary = 'summaryStep',
}

export enum ProfileSubStepKeys {
  ViewSurvey = 'viewSurvey',
  ReviewXrays = 'reviewXrays',
}
