import React from 'react'
import './StepperController.scss';

interface StepperControllerProps {
  stepCount: number;
  activeStep: number;
  setStep(step: number): void;
}

const StepperController: React.FC<StepperControllerProps> = ({
  stepCount,
  activeStep,
  setStep,
}) => (
  <div className='StepperController__Container'>
    {Array
      .from({ length: stepCount }, (_, index) => index + 1)
      .map((num) => (
        <div
          key={num}
          className={`StepperController__StepIndicator ${num === activeStep ? 'sc-active' : ''}`}
          onClick={() => setStep(num)}
          onKeyDown={() => setStep(num)}
          tabIndex={0}
          role="button"
        />
      ))
    }
  </div>
);

export default StepperController;
