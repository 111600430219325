import React, { useState, useRef, useEffect, ElementType } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';
import useOutsideClick from '../../../hooks/useOutsideClick';
import './DropdownMenu.scss';

interface DropdownMenuItem {
  label: string;
  action(args?: any): any;
  icon?: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
}

interface DropdownMenuProps {
  icon: React.ReactNode;
  items: DropdownMenuItem[];
  contextualHide?: boolean;
}

type DropdownMenuButtonProps = {
  label: string;
  icon?: React.ReactNode;
  active?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
} & AriaButtonOptions<ElementType>;

const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = (props) => {
  const {
    label,
    icon, 
    active,
    isFirst,
    isLast,
  } = props;

  const buttonRef = useRef(null);

  const { buttonProps } = useButton({
    ...props,
    elementType: 'div',
  }, buttonRef);

  return (
    <div
      {...buttonProps}
      className={`DropdownMenu__Button ${active ? 'ddm-active' : ''} ${isFirst ? 'ddm-first' : ''} ${isLast ? 'ddm-last' : ''}`}
      ref={buttonRef}
    >
      {icon && (
        <div className='DropdownMenu__ButtonIconContainer'>
          {icon}
        </div>
      )}
      <h4>{label}</h4>
    </div>
  );
};

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  const {
    icon,
    items,
    contextualHide,
  } = props;

  const [iconHeight, setIconHeight] = useState<number | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const iconRef = useRef<HTMLDivElement>(null);

  const { elementRef: containerRef } = useOutsideClick(closeMenu);

  useEffect(() => {
    if (!iconRef.current) return;
    setIconHeight(iconRef.current.offsetHeight);
  }, [iconRef]);

  useEffect(() => {
    if (contextualHide) closeMenu();
  }, [contextualHide]);

  const { buttonProps } = useButton({
    ...props,
    onPress: toggleMenu,
    elementType: 'div',
  }, iconRef);

  return (
    <div className='DropdownMenu__Container' ref={containerRef}>
      <div
        {...buttonProps}
        className={`DropdownMenu__IconContainer ${menuOpen ? 'ddm-open' : ''}`}
        ref={iconRef}
      >
        {icon}
      </div>
      <div
        className={`DropdownMenu__MenuContainer ${menuOpen ? 'ddm-open' : ''}`}
        style={{ top: `${iconHeight}px` }}
      >
        <div className='DropdownMenu__Menu'>
          {items.map((item, i, a) => (
            <DropdownMenuButton
              key={item.label}
              label={item.label}
              icon={item.icon}
              isDisabled={item.disabled}
              active={item.active}
              onPress={() => {
                item.action();
                closeMenu();
              }}
              isFirst={i === 0}
              isLast={i === a.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
