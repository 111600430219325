import React, { useRef } from 'react';
import { useTextField, AriaTextFieldProps } from 'react-aria';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './Input.scss';

type InputProps = {
  isLoading?: boolean;
  showError?: boolean;
  className?: string;
} & AriaTextFieldProps;

const Input: React.FC<InputProps> = (props) => {
  const {
    label,
    errorMessage,
    isLoading,
    showError,
    className,
  } = props;

  const ref = useRef(null);

  const {
    labelProps,
    inputProps,
    errorMessageProps
  } = useTextField(props, ref);

  return (
    <div className={`Input__Container ${className ? className : ''}`}>
      {label && <label {...labelProps}>Label</label>}
      <div className='Input__InputContainer'>
        <input className={`Input__Input ${isLoading ? 'input--loading' : ''}`} {...inputProps} ref={ref} />
        <div className={`Input__LoadingIcon ${isLoading ? 'input--visible' : ''}`}>
          <FontAwesomeIcon
            spin
            style={{ objectFit: 'contain' }}
            icon={faCircleNotch}
            />
        </div>
      </div>
      <div className={`Input__ErrorContainer ${!!showError ? 'input--visible' : ''}`}>
        <div {...errorMessageProps} className='Input__Error'>{errorMessage}</div>
      </div>
    </div>
  );
};

export default Input;
