import { CarePathwaySelectionStep } from "../components/organisms/CarePathwaySelectionStep";
import { EvaluationReportCardStep } from "../components/organisms/EvaluationReportCardStep";
import { JointEvaluationStep } from "../components/organisms/JointEvaluationStep";
import { JointRecommendationStep } from "../components/organisms/JointRecommendationStep";
import { OverallReportCardStep } from "../components/organisms/OverallReportCardStep";
import { PatientProfileStep } from "../components/organisms/PatientProfileStep";
import { PatientSurveySubStep } from "../components/organisms/PatientSurveySubStep";
import { PreliminaryReviewStep } from "../components/organisms/PreliminaryReviewStep";
import { ReviewXraysSubStep } from "../components/organisms/ReviewXraysSubStep";
import { SelectPatientStep } from "../components/organisms/SelectPatientStep";
import { SummaryStep } from "../components/organisms/SummaryStep";
import { XrayConfirmationStep } from "../components/organisms/XrayConfirmationStep";
import { FlowMapType } from "../models/Flows";
import { PatientFlowStepKeys, ProfileSubStepKeys } from "./patientFlowKeys";
import { RouteKeys } from "./routeKeys";

// patient flow steps in order

export const patientFlowMap: FlowMapType = {
  [PatientFlowStepKeys.PatientSelect]: {
    key: PatientFlowStepKeys.PatientSelect,
    renderComponent: (props) => <SelectPatientStep {...props} />,
  },
  [PatientFlowStepKeys.PreliminaryReview]: {
    key: PatientFlowStepKeys.PreliminaryReview,
    renderComponent: (props) => <PreliminaryReviewStep {...props} />,
  },
  [PatientFlowStepKeys.PatientProfile]: {
    key: PatientFlowStepKeys.PatientProfile,
    renderComponent: (props) => <PatientProfileStep {...props} />,
    subSteps: {
      [ProfileSubStepKeys.ViewSurvey]: {
        key: ProfileSubStepKeys.ViewSurvey,
        renderComponent: (props) => <PatientSurveySubStep {...props} />,
      },
      [ProfileSubStepKeys.ReviewXrays]: {
        key: ProfileSubStepKeys.ReviewXrays,
        renderComponent: (props) => <ReviewXraysSubStep {...props} />,
      },
    },
  },
  [PatientFlowStepKeys.XrayConfirmation]: {
    key: PatientFlowStepKeys.XrayConfirmation,
    renderComponent: (props) => <XrayConfirmationStep {...props} />,
  },
  [PatientFlowStepKeys.EvaluationReportCard]: {
    key:PatientFlowStepKeys.EvaluationReportCard,
    renderComponent: (props) => <EvaluationReportCardStep {...props} />,
    excludeFrom: RouteKeys.SBS_TRAINING,
  },
  [PatientFlowStepKeys.JointEvaluation]: {
    key: PatientFlowStepKeys.JointEvaluation,
    renderComponent: (props) => <JointEvaluationStep {...props} />,
  },
  [PatientFlowStepKeys.CarePathwaySelection]: {
    key: PatientFlowStepKeys.CarePathwaySelection,
    renderComponent: (props) => <CarePathwaySelectionStep {...props} />,
  },
  [PatientFlowStepKeys.JointRecommendation]: {
    key: PatientFlowStepKeys.JointRecommendation,
    renderComponent: (props) => <JointRecommendationStep {...props} />,
  },
  [PatientFlowStepKeys.OverallReportCard]: {
    key:PatientFlowStepKeys.OverallReportCard,
    renderComponent: (props) => <OverallReportCardStep {...props} />,
    excludeFrom: RouteKeys.SBS_TRAINING,
  },
  [PatientFlowStepKeys.Summary]: {
    key: PatientFlowStepKeys.Summary,
    renderComponent: (props) => <SummaryStep {...props} />,
  },
};
