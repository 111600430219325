const saveItem = (key: string, item: string) => localStorage.setItem(key, item);

const getItem = (key: string) => localStorage.getItem(key);

const funcs = {
  saveItem,
  getItem,
};

export default funcs;
