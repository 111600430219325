import React, { HTMLAttributes } from 'react';
import './PROScoreIndicator.scss';

type PROScoreIndicatorProps = {
  PROScore: number
} & HTMLAttributes<HTMLDivElement>;

const PROScoreIndicator: React.FC<PROScoreIndicatorProps> = ({
  PROScore,
  ...props
}) => {
  const getCssClass = () => {
    if (PROScore > 70) return 'success';
    return 'error';
  };

  return (
    <div
      {...props}
      className={`PROScoreIndicator__Container ${getCssClass()} ${props.className ? props.className : ''}`}
    >
      <h4>{PROScore}</h4>
    </div>
  );
};

export default PROScoreIndicator;
