import React, { HTMLAttributes, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOutsideClick from '../../../hooks/useOutsideClick';
import './DropdownSelect.scss';

interface DropdownSelectOpt {
  value: any;
  handleClick(args?: any): void;
}

type DropdownSelectProps = {
  options: DropdownSelectOpt[];
  value: any;
  label?: string;
  showDropShadow?: boolean;
  showBorder?: boolean;
} & HTMLAttributes<HTMLDivElement>;

// NOTE: The dropshadow mixin can have weird effects on this component, incorrectly hiding 
// text within the .DropdownSelect__OptionsContainer div on certain screens and at certain 
// resolutions. 🤷‍♂️ Use at your own risk. showBorder will outline the options box with a 
// 1px wide border to create separation en lieu of the dropshadow if it's giving you issues.

// TODO: implement react-aria at some point

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  options,
  value,
  label,
  showDropShadow,
  showBorder,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeMenu = () => setOpen(false);

  const elementHeightRem = 2.75;

  const { elementRef } = useOutsideClick(closeMenu);

  return (
    <div
      {...props}
      style={{ ...props.style, width: '100%' }}
    >
      {label && (
        <label
          aria-label={`${label}-dropdown-label`}
          style={{ alignSelf: 'flex-start' }}
        >
          <p>{label}</p>
        </label>
      )}
      <div
        className='DropdownSelect__SelectionContainer'
        aria-labelledby={label ? `${label}-dropdown-label` : undefined}
        ref={elementRef}
        style={{ height: `${elementHeightRem}rem` }}
        onClick={() => setOpen(!open)}
      >
        <p className='DropdownSelect__Selection'>
          {value}
        </p>
        <FontAwesomeIcon
          className='DropdownSelect__ChevronIcon'
          icon={faChevronDown}
          style={{ transform: `rotate(${open ? '180deg' : '0deg'})` }}
        />
        <div
          className={`DropdownSelect__OptionsContainer ${showDropShadow ? 'dds-shadow' : ''} ${showBorder ? `dds-border--${open ? 'open' : 'closed'}` : ''}`}
          style={{
            top: `${elementHeightRem}rem`,
            height: open ? `${elementHeightRem * options.length}rem` : '0rem',
          }}
        >
          {options.map((option, i, arr) => (
            <div
              className={`DropdownSelect__Option ${i === 0 ? 'dds-first' : ''} ${i === arr.length - 1 ? 'dds-last' : ''}`}
              key={option.value}
              style={{ height: `${elementHeightRem}rem` }}
              onClick={(e) => {
                e.stopPropagation();
                option.handleClick();
                setOpen(false);
              }}
            >
              <p className='DropdownSelect__Selection'>
                {option.value}
              </p>
            </div>
          ))}  
        </div>
      </div>
    </div>
  );
};

export default DropdownSelect;
