import axios, { AxiosRequestConfig } from 'axios';

interface RequestParams {
  url: string;
  data: any;
  config?: AxiosRequestConfig<any>;
}

const get = ({ url }: RequestParams) => axios.get(url);

const post = ({ url, data, config }: RequestParams) => axios.post(url, data, config);

const patch = ({ url, data }: RequestParams) => axios.patch(url, data);

const destroy = ({ url }: RequestParams) => axios.delete(url);

const funcs = {
  get,
  post,
  patch,
  destroy,
};

export default funcs;
