import { RouteKeys } from "../data/routeKeys";
import { CarePathways, ExamplePatient } from "../models/ExamplePatient";
import { DegenerationLevels, ViewTypes, XrayForAnalysis, JointCompartments } from "../models/ExampleXray";

// returns the worst case degeneration from an array of degeneration levels
export const getWorstCaseDeg = (
  xrays: XrayForAnalysis[],
): DegenerationLevels => {
  if (xrays.find((xray) => xray.degeneration === DegenerationLevels.ENDSTAGE)) return DegenerationLevels.ENDSTAGE;
  if (xrays.find((xray) => xray.degeneration === DegenerationLevels.NEAR_ENDSTAGE)) return DegenerationLevels.NEAR_ENDSTAGE;
  if (xrays.find((xray) => xray.degeneration === DegenerationLevels.MODERATE)) return DegenerationLevels.MODERATE;
  return DegenerationLevels.NORMAL;
};

// gets the joint side from a full joint string
export const getJointSide = (joint?: 'left knee' | 'right knee' | 'left hip' | 'right hip') => joint?.split(' ')[0];
// gets the joint from a full joint string
export const getJoint = (joint?: 'left knee' | 'right knee' | 'left hip' | 'right hip') => joint?.split(' ')[1];

interface CalculateTestScoreParams {
  patient: ExamplePatient;
  flow: RouteKeys.SBS_TRAINING | RouteKeys.TEST_YOUR_KNOWLEDGE;
  includeCarePathway?: boolean;
}

// calculates the user's exam score in the patient flow
export const calculateTestScore = ({
  patient,
  flow,
  includeCarePathway,
}: CalculateTestScoreParams) => {
  let total = Object.values(patient.xrays.forAnalysis).length;
  let incorrectSum = 0;

  const joint = getJoint(patient.joint);

  Object.entries(patient[flow].evaluatedXrays).forEach(([id, evaluation]) => {
    if (evaluation.firstSelection !== patient.xrays.forAnalysis[parseInt(id)].degeneration) incorrectSum++;
  });

  if (includeCarePathway) {
    total++;
    
    if (joint === 'knee') {
      if (patient.carePathway !== patient[flow].carePathwaySelection) incorrectSum++;
    };
    if (joint === 'hip') {
      if (
        (
          patient.carePathway === CarePathways.TOTAL_REPLACEMENT
          || patient.carePathway === CarePathways.PARTIAL_REPLACEMENT
        )
        && patient[flow].carePathwaySelection !== 'op'
      ) {
        incorrectSum++;
      } else if (
        patient.carePathway === CarePathways.NON_OPERATIVE
        && patient[flow].carePathwaySelection !== CarePathways.NON_OPERATIVE
      ) {
        incorrectSum++;
      }
    }
  }

  return {
    total,
    score: total - incorrectSum,
    percentage: Math.floor((total - incorrectSum) / total * 100),
  };
};

type SortXraysCallbackComparator = [
  string,
  any,
];

// ensures that joint compartments are sorted properly
export const sortXrayEntiresByGroupKey = (a: SortXraysCallbackComparator, b: SortXraysCallbackComparator, joint?: string) => {
  const order: { [key: string]: number } = joint === 'knee' 
    ? {
      [JointCompartments.MEDIAL]: 0,
      [JointCompartments.LATERAL]: 1,
      [JointCompartments.KNEECAP]: 2
    }
    : {
      [ViewTypes.AP_PELVIS]: 0,
      [ViewTypes.LATERAL_HIP]: 1,
    };

  const indexA = order[a[0]];
  const indexB = order[b[0]];

  return indexA - indexB;
};

const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

// validates the email on the form
export const validateEmail = (string?: string) => emailRegex.test(string || '');
