import React, { ButtonHTMLAttributes, ElementType } from 'react';
import { Button } from '../Button';
import { AriaButtonOptions } from 'react-aria';
import './OuterIconButton.scss';

type OuterIconButtonProps = {
  children: React.ReactNode;
  outerIcon: React.ReactNode | string;
  innerIcon?: React.ReactNode | string;
  variant?: 'primary' | 'secondary';
  dropShadow?: boolean;
  pulse?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement> & AriaButtonOptions<ElementType>;

const OuterIconButton: React.FC<OuterIconButtonProps> = ({
  children,
  outerIcon,
  innerIcon,
  dropShadow,
  pulse,
  variant = 'primary',
  ...props
}) => (
  <div className='OuterIconbutton__Container'>
    <div className='OuterIconbutton__IconContainer'>
      {typeof outerIcon === 'string' 
        ? <img src={outerIcon} alt="" />
        : outerIcon}
    </div>
    <Button
      fullWidth
      pulse={pulse}
      dropShadow={dropShadow}
      isDisabled={props.isDisabled}
      onPress={props.onPress}
      onKeyDown={props.onKeyDown}
      icon={
        typeof innerIcon === 'string'
          ? <img src={innerIcon} alt="Checkmark" height="100%" width="100%" />
          : innerIcon
      }
    >
      {children}
    </Button>
  </div>
);

export default OuterIconButton;
