import React, { useRef, useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { Surface } from '../../atoms/Surface';
import { StepProps } from '../../../models/StepProps';
import { DegenerationLevels, JointCompartments } from '../../../models/ExampleXray';
import { RouteKeys } from '../../../data/routeKeys';

import MedialNormal from '../../../assets/educationAssets/JointSpaceNarrowingStep/medial/right normal medial.jpg';
import MedialModerate from '../../../assets/educationAssets/JointSpaceNarrowingStep/medial/right mod medial.jpg';
import MedialNES from '../../../assets/educationAssets/JointSpaceNarrowingStep/medial/right nes medial.jpg';
import MedialES from '../../../assets/educationAssets/JointSpaceNarrowingStep/medial/right end stage medial.jpg';

import LateralNormal from '../../../assets/educationAssets/JointSpaceNarrowingStep/lateral/right normal lateral.png';
import LateralModerate from '../../../assets/educationAssets/JointSpaceNarrowingStep/lateral/right mod lateral.png';
import LateralNES from '../../../assets/educationAssets/JointSpaceNarrowingStep/lateral/right nes lateral.png';
import LateralES from '../../../assets/educationAssets/JointSpaceNarrowingStep/lateral/right end stage lateral.png';

import KneecapNormal from '../../../assets/educationAssets/JointSpaceNarrowingStep/kneecap/right normal kneecap.png';
import KneecapModerate from '../../../assets/educationAssets/JointSpaceNarrowingStep/kneecap/right mod kneecap.png';
import KneecapNES from '../../../assets/educationAssets/JointSpaceNarrowingStep/kneecap/right nes kneecap.png';
import KneecapES from '../../../assets/educationAssets/JointSpaceNarrowingStep/kneecap/right es kneecap.png';

import Z1Normal from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z1/AP_Normal_Z1.jpg';
import Z1Moderate from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z1/AP_Moderate_Z1.jpg';
import Z1NES from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z1/AP_NES_Z1.jpg';
import Z1ES from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z1/AP_ES_Z1.jpg';

import Z2Normal from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z2/AP_Normal_Z2.jpg';
import Z2Moderate from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z2/AP_Moderate_Z2.jpg';
import Z2NES from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z2/AP_NES_Z2.jpg';
import Z2ES from '../../../assets/educationAssets/JointSpaceNarrowingStep/Z2/AP_ES_Z2.jpg';

import './JointSpaceNarrowingStep.scss';

// corresponding page in app:
// https://education.jointai.co/knee-education/joint-space

const JointSpaceNarrowingStep: React.FC<StepProps> = ({ flow }) => {
  const {
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const {
    eduPageContainerClassName,
    eduContentContainerClassName,
  } = useLayoutState({
    eduPageContainerBaseClassName: 'JointSpaceNarrowingStep__Page',
    eduContentContainerBaseClassName: 'JointSpaceNarrowingStep__Container',
  });

  const isKneeFlow = flow === RouteKeys.KNEE_EDU;
  const isHipFlow = flow === RouteKeys.HIP_EDU;

  interface XrayImgState {
    imgSrc: string;
    identified: boolean;
  }

  type CompartmentCollection = {
    [K in DegenerationLevels]: XrayImgState;
  };

  type XrayCollectionState = {
    [K in JointCompartments]?: CompartmentCollection;
  };

  const initState = { identified: false };

  const kneeCollections: XrayCollectionState = {
    [JointCompartments.MEDIAL]: {
      [DegenerationLevels.MODERATE]: {
        ...initState,
        imgSrc: MedialModerate,
      },
      [DegenerationLevels.ENDSTAGE]: {
        ...initState,
        imgSrc: MedialES,
      },
      [DegenerationLevels.NORMAL]: {
        ...initState,
        imgSrc: MedialNormal,
      },
      [DegenerationLevels.NEAR_ENDSTAGE]: {
        ...initState,
        imgSrc: MedialNES,
      },
    },
    [JointCompartments.LATERAL]: {
      [DegenerationLevels.NEAR_ENDSTAGE]: {
        ...initState,
        imgSrc: LateralNES,
      },
      [DegenerationLevels.NORMAL]: {
        ...initState,
        imgSrc: LateralNormal,
      },
      [DegenerationLevels.MODERATE]: {
        ...initState,
        imgSrc: LateralModerate,
      },
      [DegenerationLevels.ENDSTAGE]: {
        ...initState,
        imgSrc: LateralES,
      },
    },
    [JointCompartments.KNEECAP]: {
      [DegenerationLevels.ENDSTAGE]: {
        ...initState,
        imgSrc: KneecapES,
      },
      [DegenerationLevels.MODERATE]: {
        ...initState,
        imgSrc: KneecapModerate,
      },
      [DegenerationLevels.NEAR_ENDSTAGE]: {
        ...initState,
        imgSrc: KneecapNES,
      },
      [DegenerationLevels.NORMAL]: {
        ...initState,
        imgSrc: KneecapNormal,
      },
    },
  };

  const hipCollections: XrayCollectionState = {
    [JointCompartments.ZONE_1]: {
      [DegenerationLevels.ENDSTAGE]: {
        ...initState,
        imgSrc: Z1ES,
      },
      [DegenerationLevels.NORMAL]: {
        ...initState,
        imgSrc: Z1Normal,
      },
      [DegenerationLevels.NEAR_ENDSTAGE]: {
        ...initState,
        imgSrc: Z1NES,
      },
      [DegenerationLevels.MODERATE]: {
        ...initState,
        imgSrc: Z1Moderate,
      },
    },
    [JointCompartments.ZONE_2]: {
      [DegenerationLevels.NORMAL]: {
        ...initState,
        imgSrc: Z2Normal,
      },
      [DegenerationLevels.ENDSTAGE]: {
        ...initState,
        imgSrc: Z2ES,
      },
      [DegenerationLevels.NEAR_ENDSTAGE]: {
        ...initState,
        imgSrc: Z2NES,
      },
      [DegenerationLevels.MODERATE]: {
        ...initState,
        imgSrc: Z2Moderate,
      },
    },
  };

  const allXrays = {
    [RouteKeys.KNEE_EDU]: kneeCollections,
    [RouteKeys.HIP_EDU]: hipCollections,
  };

  const [xrayCollectionsMap, setXrayCollectionsMap] = useState<XrayCollectionState>(allXrays[flow as RouteKeys.HIP_EDU | RouteKeys.KNEE_EDU]);
  const xrayCollections = Object.entries(xrayCollectionsMap);

  const [activeCompartmentIdx, setActiveCompartmentIdx] = useState<number>(0);

  const activeCompartment = xrayCollections[activeCompartmentIdx];
  const activeCompartmentLabel: string = activeCompartment[0];
  const activeCompartmentEntries = Object.entries(activeCompartment[1]);

  const [showIncorrectOn, setShowIncorrectOn] = useState<DegenerationLevels | null>(null);

  const getActiveActionCard = (): DegenerationLevels | null => {
    if (!activeCompartment[1][DegenerationLevels.NORMAL].identified) return DegenerationLevels.NORMAL;
    if (!activeCompartment[1][DegenerationLevels.MODERATE].identified) return DegenerationLevels.MODERATE;
    if (!activeCompartment[1][DegenerationLevels.NEAR_ENDSTAGE].identified) return DegenerationLevels.NEAR_ENDSTAGE;
    if (!activeCompartment[1][DegenerationLevels.ENDSTAGE].identified) return DegenerationLevels.ENDSTAGE;
    return null;
  };

  const activeActionCard = getActiveActionCard();

  const identifyXrayByDegLvl = (degLvl: DegenerationLevels) => {
    setXrayCollectionsMap({
      ...xrayCollectionsMap,
      [activeCompartmentLabel]: {
        ...xrayCollectionsMap[activeCompartmentLabel as JointCompartments],
        [degLvl]: {
          ...xrayCollectionsMap[activeCompartmentLabel as JointCompartments]![degLvl],
          identified: true,
        },
      },
    });
  };

  const timers = useRef<NodeJS.Timeout[]>([]);
  
  const clearAllTimers = () => timers.current.forEach(clearTimeout);

  const handleXrayClick = (degLvl: DegenerationLevels, xray: XrayImgState) => {
    setShowIncorrectOn(null);
    clearAllTimers();
    if (xray.identified) return;
    if (
      degLvl === DegenerationLevels.NORMAL
      && !activeCompartment[1][DegenerationLevels.NORMAL].identified
    ) {
      return identifyXrayByDegLvl(degLvl);
    };
    if (
      degLvl === DegenerationLevels.MODERATE
      && activeCompartment[1][DegenerationLevels.NORMAL].identified
      && !activeCompartment[1][DegenerationLevels.MODERATE].identified
    ) {
      return identifyXrayByDegLvl(degLvl);
    };
    if (
      degLvl === DegenerationLevels.NEAR_ENDSTAGE
      && activeCompartment[1][DegenerationLevels.NORMAL].identified
      && activeCompartment[1][DegenerationLevels.MODERATE].identified
      && !activeCompartment[1][DegenerationLevels.NEAR_ENDSTAGE].identified
    ) {
      return identifyXrayByDegLvl(degLvl);
    };
    if (
      degLvl === DegenerationLevels.ENDSTAGE
      && activeCompartment[1][DegenerationLevels.NORMAL].identified
      && activeCompartment[1][DegenerationLevels.MODERATE].identified
      && activeCompartment[1][DegenerationLevels.NEAR_ENDSTAGE].identified
      && !activeCompartment[1][DegenerationLevels.ENDSTAGE].identified
    ) {
      return identifyXrayByDegLvl(degLvl);
    };
    if (showIncorrectOn !== degLvl) {
      const timer = setTimeout(() => setShowIncorrectOn(null), 5000);
      timers.current.push(timer);
      setShowIncorrectOn(degLvl);
    };
  };

  const handleNext = () => {
    clearAllTimers();
    setShowIncorrectOn(null);
    if (activeCompartmentIdx === xrayCollections.length - 1) return nextStep();
    setActiveCompartmentIdx(activeCompartmentIdx + 1);
  };

  const handleBack = () => {
    clearAllTimers();
    setShowIncorrectOn(null);
    if (activeCompartmentIdx === 0) return prevStep();
    setActiveCompartmentIdx(activeCompartmentIdx - 1);
  };

  return (
    <div className={eduPageContainerClassName}>
      <div className={eduContentContainerClassName}>
        <div className='JointSpaceNarrowingStep__HeaderContainer'>
          <h3 className='JointSpaceNarrowingStep__Header'>
            {isKneeFlow && <>Joint Space Narrowing - {activeCompartmentLabel} Compartment</>}
            {isHipFlow && <>Joint Space Narrowing - AP Pelvis - {activeCompartmentLabel}</>}
          </h3>
          <p className='JointSpaceNarrowingStep__SubHeader'>
            Starting with the knee with least degeneration,
            put the X-rays in order of severity of degeneration
            seen in {isKneeFlow ? `the ${activeCompartmentLabel} compartment` : `${activeCompartmentLabel}`}.
          </p>
        </div>
        <div className='JointSpaceNarrowingStep__MainContent'>
          <Surface className='JointSpaceNarrowingStep__ContentContainer'>
            <div className='JointSpaceNarrowingStep__Row'>
              {activeCompartmentEntries.map(([degLvl, xray]) => (
                <div
                  key={xray.imgSrc}
                  className={`JointSpaceNarrowingStep__SelectionSquare ${!xray.identified ? 'with-img jsns-hover jsns-pointer' : ''}`}
                  onClick={() => handleXrayClick(degLvl as DegenerationLevels, xray)}
                  onKeyDown={() => handleXrayClick(degLvl as DegenerationLevels, xray)}
                  role='button'
                  tabIndex={0}
                >
                  {!xray.identified && (
                    <img
                      className='JointSpaceNarrowingStep__XrayImg'
                      src={xray.imgSrc}
                      alt="Example X-ray"
                      draggable={false}
                    />
                  )}
                  <div className={`JointSpaceNarrowingStep__IncorrectLabelContainer ${showIncorrectOn === degLvl ? 'jsns-visible' : ''}`}>
                    <p className='JointSpaceNarrowingStep__IncorrectLabel'>Incorrect</p>
                  </div>
                </div>
              ))}
            </div>
            <div className='JointSpaceNarrowingStep__Row'>
              {Object.values(DegenerationLevels).map((degLvl) => (
                <div
                  key={degLvl}
                  className={`JointSpaceNarrowingStep__SelectionSquare ${activeActionCard === degLvl ? 'jsns-active' : ''} ${activeCompartment[1][degLvl].identified ? 'with-img' : ''}`}
                >
                  {activeActionCard === degLvl && (
                    <div>CLICK on the X-ray with <span style={{ textTransform: 'lowercase' }}>{degLvl}</span> degeneration</div>
                  )}
                  {activeCompartment[1][degLvl].identified && (
                    <img
                      className='JointSpaceNarrowingStep__XrayImg'
                      src={activeCompartment[1][degLvl].imgSrc}
                      alt="Example X-ray"
                      draggable={false}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className='JointSpaceNarrowingStep__ScaleContainer'>
              <div className='JointSpaceNarrowingStep__Scale' />
              <div className='JointSpaceNarrowingStep__Row space-between'>
                <p>Thickest Joint Space</p>
                <p>Least Joint Space</p>
              </div>
            </div>
          </Surface>
        </div>
      </div>
      <NavigationFooter
        prev={{ action: handleBack }}
        centerElement={
          activeCompartment[1][DegenerationLevels.ENDSTAGE].identified
            ? <h3 className='JointSpaceNarrowingStep__CompleteLabel'>Correct!</h3>
            : null
        }
        next={{
          disabled: !!activeCompartmentEntries.find(([, xray]) => !xray.identified),
          action: handleNext,
        }}
      />
    </div>
  );
};

export default JointSpaceNarrowingStep;
