import React, { ButtonHTMLAttributes, ElementType, useRef } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';
import './TextButton.scss';

type ButtonProps = {
  children: React.ReactNode;
} & AriaButtonOptions<ElementType> & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = (props) => {
  const { children } = props;

  const ref = useRef(null);

  const { buttonProps } = useButton(props, ref);
  
  return (
    <button
      {...buttonProps}
      ref={ref}
      className={`TextButton__Container ${props.className ? props.className : ''}`}
    >
      <p>{children}</p>
    </button>
  );
};

export default Button;
