import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CornerTag } from '../../atoms/CornerTag';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { StepProps } from '../../../models/StepProps';
import { XrayTable } from '../../molecules/XrayTable';
import { Surface } from '../../atoms/Surface';
import { calculateTestScore } from '../../../helpers/helpers';
import { RouteKeys } from '../../../data/routeKeys';
import './EvaluationReportCardStep.scss';

// patient flow step 6 - a report card denoting how the user performed
// during the xray evaluations

const EvaluationReportCardStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;
  if (!activePatient || (!isTrainingFlow && !isTestingFlow)) return null;

  const { score, total } = calculateTestScore({ patient: activePatient, flow });

  return (
    <div className='EvaluationReportCardStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient!.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='EvaluationReportCardStep__Container'>
        <h3 className='EvaluationReportCardStep__Header'>{activePatient.joint} Evaluation Results</h3>
        <div className='EvaluationReportCardStep__MainContent'>
          <XrayTable
            className='EvaluationReportCardStep__Table'
            patient={activePatient}
            flow={flow}
            showFirstSelection
            showAnswerMarkers
          />
          <Surface
            className='EvaluationReportCardStep__ScoreCard'
            variant='smooth'
          >
            <h3 className='EvaluationReportCardStep__Score'>Score: {score} / {total}</h3>
          </Surface>
        </div>
      </div>
      <NavigationFooter prev={{ action: prevStep }} next={{ action: nextStep }} />
    </div>
  );
};

export default EvaluationReportCardStep;
