import {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useCallback,
} from "react";
import { ActionMap, Reducer } from "../models/ReducerTypes";
import { isMobile } from 'react-device-detect';

// this hook handles global app layout state using the context api.
// currently the only piece of layout state that needs to be accessed from
// multiple different components is the education flow navigation drawer. the
// base classname passed in as a param has some additional class names applied
// to it here to handle styles based upon whether or not the drawer is open

interface State {
  educationDrawerOpen: boolean;
}

enum ActionTypes {
  SET_EDUCATION_DRAWER_OPEN = 'SET_EDUCATION_DRAWER_OPEN',
};

type Payloads = {
  [ActionTypes.SET_EDUCATION_DRAWER_OPEN]: boolean,
};

type StorageActions = ActionMap<Payloads>[keyof ActionMap<Payloads>];

interface Action<T extends keyof Payloads> {
  type: T;
  payload: Payloads[T];
}

interface LayoutStateContextValues {
  state: State,
  setState: Dispatch<StorageActions>,
}

const LayoutStateContext = createContext<LayoutStateContextValues | undefined>(undefined);
LayoutStateContext.displayName = 'LayoutStateContext';

const reducer: Reducer<State, Action<keyof Payloads>> = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_EDUCATION_DRAWER_OPEN: {
      return { ...state, educationDrawerOpen: action.payload };
    }
    default: {
      throw new Error(`Invalid action type: ${action.type}`);
    }
  };
};

const stateInit: State = {
  educationDrawerOpen: isMobile ? false : true,
};

export const LayoutStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [store, dispatch] = useReducer<Reducer<State, StorageActions>>(reducer, stateInit);
  return (
    <LayoutStateContext.Provider value={{ state: store, setState: dispatch }}>
      {children}
    </LayoutStateContext.Provider>
  );
};

interface UseLayoutStateParams {
  eduPageContainerBaseClassName?: string;
  eduContentContainerBaseClassName?: string;
};

export const useLayoutState = (params?: UseLayoutStateParams) => {
  const context = useContext(LayoutStateContext);

  if (!context) throw new Error('useLayoutState should be called within a LayoutStateProvider');
  
  const { state, setState } = context;
  
  const { educationDrawerOpen } = state;
  
  // helper function exposed for easy state management in other components
  const toggleEducationDrawerOpen = useCallback(
    () => setState({ type: ActionTypes.SET_EDUCATION_DRAWER_OPEN, payload: !educationDrawerOpen }),
    [setState, educationDrawerOpen],
  );

  // appling additional class names to the provided base classnames depending on whether or
  // not the drawer is open
  const eduPageContainerBaseClassName = params?.eduPageContainerBaseClassName || '';
  const eduContentContainerBaseClassName = params?.eduContentContainerBaseClassName || '';

  const eduPageContainerClassName = `${eduPageContainerBaseClassName} ${!educationDrawerOpen ? 'drawer-closed' : ''}`
  const eduContentContainerClassName = `${eduContentContainerBaseClassName} ${!educationDrawerOpen ? 'drawer-closed' : ''}`

  return {
    educationDrawerOpen,
    toggleEducationDrawerOpen,
    eduPageContainerClassName,
    eduContentContainerClassName,
  };
};
