import React from 'react';
import { format } from 'date-fns';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Surface } from '../../atoms/Surface';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { OuterIconButton } from '../../atoms/OuterIconButton';
import { patientFlowMap } from '../../../data/patientFlow';
import { getJointSide } from '../../../helpers/helpers';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import { PatientFlowStepKeys, ProfileSubStepKeys } from '../../../data/patientFlowKeys';
import { ReactComponent as DefaultProfileIcon } from '../../../assets/icons/default_profile_icon.svg';
import { ReactComponent as ClipboardIcon } from '../../../assets/icons/clipboard_icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload_icon.svg';
import { ReactComponent as XrayIcon } from '../../../assets/icons/xray_icon.svg';
import { ReactComponent as CircleCheckIcon } from '../../../assets/icons/circle_check_icon.svg';
import { ReactComponent as LeftHip } from '../../../assets/images/LEFTHIPSKELETON.svg';
import { ReactComponent as RightHip } from '../../../assets/images/RIGHTHIPSKELETON.svg';
import { ReactComponent as LeftKnee } from '../../../assets/images/LEFTKNEESKELETON.svg';
import { ReactComponent as RightKnee } from '../../../assets/images/RIGHKNEESKELETON.svg';
import './PatientProfileStep.scss';

// patient flow step 3 - the selected patient's main profile, which acts as a launching
// pad to the patient survey, xray review, and xray evaluation substeps

interface AddtlBtnProps {
  icon: React.ReactNode;
  label: string;
  handleClick(): void;
  complete: boolean;
}

interface ActionButton extends AddtlBtnProps {
  key: ProfileSubStepKeys | string,
}

const PatientProfileStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    activeSubStepComponent,
    setActiveSubStep,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  if (!activePatient || (!isTrainingFlow && !isTestingFlow)) return null;

  const evaluateXraysStepComplete = !!(
    Object.values(activePatient[flow].evaluatedXrays).filter((evaluation) => evaluation.complete).length ===
    Object.values(activePatient.xrays.forAnalysis).length
  );

  const getAddtlButtonProps = (key: ProfileSubStepKeys): AddtlBtnProps => {
    const handleClick = () => setActiveSubStep(key);
    switch (key) {
      case ProfileSubStepKeys.ViewSurvey:
        return {
          icon: <ClipboardIcon className='PatientProfileStep__ClipboardIcon' />,
          label: 'Patient Survey',
          complete: activePatient[flow][ProfileSubStepKeys.ViewSurvey],
          handleClick,
        };
      case ProfileSubStepKeys.ReviewXrays:
        return {
          icon: <UploadIcon className='PatientProfileStep__UploadIcon' />,
          label: 'Review X-rays',
          complete: activePatient[flow][ProfileSubStepKeys.ReviewXrays],
          handleClick,
        };
      default: return {
        icon: '',
        label: '',
        complete: false,
        handleClick: () => { },
      };
    }
  };

  const nextBtnKey = 'next';

  const actions: ActionButton[] = [
    ...Object.values(patientFlowMap[PatientFlowStepKeys.PatientProfile]!.subSteps!).map((subStep) => ({
      key: (subStep.key as ProfileSubStepKeys),
      ...getAddtlButtonProps(subStep.key as ProfileSubStepKeys),
    })),
    {
      key: nextBtnKey,
      icon: <XrayIcon className='PatientProfileStep__XrayIcon' />,
      label: 'Evaluate X-rays',
      complete: evaluateXraysStepComplete,
      handleClick: nextStep,
    },
  ];

  const getButtonDisabledState = (button: ActionButton) => {
    switch (button.key) {
      case ProfileSubStepKeys.ViewSurvey: return false;
      case ProfileSubStepKeys.ReviewXrays: return !(activePatient[flow][ProfileSubStepKeys.ViewSurvey]);
      case nextBtnKey: return !(
        activePatient[flow][ProfileSubStepKeys.ViewSurvey]
        && activePatient[flow][ProfileSubStepKeys.ReviewXrays]
      );
      default: return true;
    };
  };

  const jointSide = getJointSide(activePatient.joint);

  if (activeSubStepComponent) return <>{activeSubStepComponent}</>;

  return (
    <div className='PatientProfileStep__Page'>
      <div className='PatientProfileStep__Container'>
        <div className='PatientProfileStep__Col'>
          <div className='PatientProfileStep__HeaderContainer'>
            <h3 className='PatientProfileStep__Header'>Patient Profile</h3>
            <Surface className='PatientProfileStep__PatientBadge'>
              <DefaultProfileIcon />
              <div className='PatientProfileStep__PatientBadgeDetails'>
                {isTrainingFlow && <h4>{activePatient.name}</h4>}
                {isTestingFlow && <h4>Patient {activePatientNumber}</h4>}
                <p className='PatientProfileStep__Label'><span>Birthdate:</span> {activePatient.birthdate}</p>
              </div>
            </Surface>
            <p className='PatientProfileStep__Date'>Evaluation Started: {format(new Date(), 'MM-dd-yyyy')}</p>
          </div>
          <div className='PatientProfileStep__ButtonsContainer'>
            {actions.map((action) => (
              <OuterIconButton
                key={action.key}
                outerIcon={action.icon}
                dropShadow
                isDisabled={getButtonDisabledState(action)}
                onPress={action.handleClick}
                innerIcon={
                  action.complete
                    ? <CircleCheckIcon height="100%" width="100%" />
                    : null
                }
              >
                {action.label}
              </OuterIconButton>
            ))}
          </div>
        </div>
        <div className={`PatientProfileStep__Col pps-rcol pps-${jointSide}`}>
          {activePatient.joint === 'right knee' && <RightKnee className='PatientProfileStep__Graphic' />}
          {activePatient.joint === 'left knee' && <LeftKnee className='PatientProfileStep__Graphic' />}
          {activePatient.joint === 'right hip' && <RightHip className='PatientProfileStep__Graphic' />}
          {activePatient.joint === 'left hip' && <LeftHip className='PatientProfileStep__Graphic' />}
        </div>
      </div>
      <NavigationFooter
        prev={{ action: prevStep, tag: 'to Demographics' }}
        next={evaluateXraysStepComplete ? { action: nextStep } : undefined}
      />
    </div>
  );
};

export default PatientProfileStep;
