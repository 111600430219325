import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CornerTag } from '../../atoms/CornerTag';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { getJoint, getWorstCaseDeg } from '../../../helpers/helpers';
import { CarePathways } from '../../../models/ExamplePatient';
import { DegenerationIndicator } from '../../atoms/DegenerationIndicator';
import { PROScoreIndicator } from '../../atoms/PROScoreIndicator';
import { XrayTile } from '../../molecules/XrayTile';
import { StepProps } from '../../../models/StepProps';
import { RouteKeys } from '../../../data/routeKeys';
import './JointRecommendationStep.scss';

// patient flow step 9 - full care pathway recommendation summary

const JointRecommendationStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  if (!activePatient) return null;

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  const worstCaseDeg = getWorstCaseDeg(Object.values(activePatient.xrays.forAnalysis));
  const joint = getJoint(activePatient.joint);

  return (
    <div className='JointRecommendationStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='JointRecommendationStep__Container'>
        <h3 className='JointRecommendationStep__Header'>
          {activePatient.joint} Recommendation:
        </h3>
        <div className='JointRecommendationStep__MainContent'>
          <h3 className='JointRecommendationStep__CarePathway'>
            {
              activePatient.carePathway === CarePathways.NON_OPERATIVE
                ? 'Non-Operative Treatment'
                : `${activePatient.carePathway} ${joint} Replacement`
            }
          </h3>
          <div className='JointRecommendationStep__Outcomes'>
            <div className='JointRecommendationStep__IndicatorContainer'>
              <p>Worst Case Found in X-rays</p>
              <DegenerationIndicator className='JointRecommendationStep__DegIndicator' degLvl={worstCaseDeg} />
            </div>
            <div className='JointRecommendationStep__IndicatorContainer'>
              <p>Overall {joint} Health</p>
              <PROScoreIndicator className='JointRecommendationStep__DegIndicator' PROScore={activePatient.proScore} />
            </div>
          </div>
          <div className='JointRecommendationStep__Xrays'>
            {activePatient.xrays.forReview.map((xray) => (
              <XrayTile
                src={xray.src}
                key={xray.id}
                label={xray.viewType}
                labelPosition='below'
                className='JointRecommendationStep__XrayTile'
              />
            ))}
          </div>
        </div>
      </div>
      <NavigationFooter
        className='JointRecommendationStep__Nav'
        prev={{ action: prevStep }}
        next={{ action: nextStep, label: isTrainingFlow ? 'Patient Report' : 'Next' }}
      />
    </div>
  );
};

export default JointRecommendationStep;
