import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CornerTag } from '../../atoms/CornerTag';
import { Button } from '../../atoms/Button';
import { XrayTable, XrayTableReportCardSingleRow } from '../../molecules/XrayTable';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { StepProps } from '../../../models/StepProps';
import { Surface } from '../../atoms/Surface';
import { calculateTestScore, getJoint } from '../../../helpers/helpers';
import { CarePathways } from '../../../models/ExamplePatient';
import { PatientFlowStepKeys } from '../../../data/patientFlowKeys';
import { RouteKeys } from '../../../data/routeKeys';
import './OverallReportCardStep.scss';

// this step only show up after step 9 in the test your knowledge patient flow - it
// summarizes the user's test scores in each of the xray evaluations, as well as
// whether or not they selected the correct care pathways, and gives a final score

// this component is also used in the test your knowledge flow when a user clicks on
// the "Score Breakdown" button for a given completed patient on the patient selection
// step. when opened via this method, an additional section is showed at the top with
// the user's percentage score, along with an option to retake the test

const OverallReportCardStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activePatient,
    activePatientNumber,
    resetActivePatientState,
    prevStep,
    nextStep,
    originStepKey,
    setActiveStep,
  } = useGlobalState({ flow });

  const cameFromPatientSelect = originStepKey === PatientFlowStepKeys.PatientSelect;

  const isTrainingFlow = flow === RouteKeys.SBS_TRAINING;
  const isTestingFlow = flow === RouteKeys.TEST_YOUR_KNOWLEDGE;
  if (!activePatient || (!isTrainingFlow && !isTestingFlow)) return null;

  const joint = getJoint(activePatient.joint);

  const { score, total, percentage } = calculateTestScore({
    flow,
    patient: activePatient,
    includeCarePathway: true,
  });

  return (
    <div className='OverallReportCardStep__Page'>
      <CornerTag>
        {isTrainingFlow && activePatient.name}
        {isTestingFlow && <>Patient {activePatientNumber}</>}
      </CornerTag>
      <div className='OverallReportCardStep__Container'>
        <h3 className='OverallReportCardStep__Header'>
          Overall Evaluation Report Card
        </h3>
        <p className='OverallReportCardStep__SubHeader'>
          *You are scored based off your first answer attempt
        </p>
        <div className='OverallReportCardStep__MainContent'>
          {cameFromPatientSelect && (
            <Surface
              className='OverallReportCardStep__OverviewContainer'
              variant='smooth'
            >
              <div className='OverallReportCardStep__OverviewContainerL'>
                <h3>Patient {activePatientNumber}</h3>
                <h4>{activePatient.joint}</h4>
              </div>
              <h2>{percentage}%</h2>
              <Button onPress={resetActivePatientState}>Retake Test</Button>
            </Surface>
          )}
          <div className='OverallReportCardStep__ReportCardContainer'>
            <div className='OverallReportCardStep__ReportCardContainerRow'>
              <div className='OverallReportCardStep__ReportCardContainerRowLeftLabelSect' />
              <div className='OverallReportCardStep__ReportCardContainerRowMainContentSect'>
                <h4 className='OverallReportCardStep__JointLabel'>{activePatient.joint}</h4>
              </div>
            </div>
            <div className='OverallReportCardStep__ReportCardContainerRow'>
              <div className={`OverallReportCardStep__ReportCardContainerRowLeftLabelSect  ${joint}`}>
                <h4>X-rays</h4>
                <div className='OverallReportCardStep__XrayIndicator' />
              </div>
              <div className='OverallReportCardStep__ReportCardContainerRowMainContentSect'>
                <div className='OverallReportCardStep__ReportCardTablesContainer'>
                  <XrayTable flow={flow} patient={activePatient} variant='reportCard' />
                </div>
              </div>
            </div>
            <div className='OverallReportCardStep__ReportCardContainerRow'>
              <div className='OverallReportCardStep__ReportCardContainerRowLeftLabelSect' />
              <div className='OverallReportCardStep__ReportCardContainerRowMainContentSect'>
                <XrayTableReportCardSingleRow
                  label='Care Pathway'
                  correct={
                    activePatient.carePathway === activePatient[flow].carePathwaySelection
                    || (
                      (
                        activePatient.carePathway === CarePathways.TOTAL_REPLACEMENT
                        || activePatient.carePathway === CarePathways.PARTIAL_REPLACEMENT
                      )
                      && activePatient[flow].carePathwaySelection === 'op'
                    ) 
                  }
                />
              </div>
            </div>
          </div>
          <Surface
            className='OverallReportCardStep__ScoreCard'
            variant='smooth'
          >
            <h3 className='OverallReportCardStep__Score'>Score: {score} / {total}</h3>
          </Surface>
        </div>
      </div>
      <NavigationFooter
        prev={{ action: cameFromPatientSelect ? () => setActiveStep(PatientFlowStepKeys.PatientSelect) : prevStep }} 
        next={cameFromPatientSelect ? undefined : { action: nextStep }}
      />
    </div>
  );
};

export default OverallReportCardStep;
