import { createContext, useContext, useReducer, Dispatch, useCallback, useEffect } from "react";
import { ExamplePatient, PatientTrainingState } from "../models/ExamplePatient";
import { DegenerationLevels, XrayForAnalysis } from "../models/ExampleXray";
import { routes } from "../data/routes";
import { RouteKeys } from "../data/routeKeys";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { examplePatientsMap, initPatientFlowState } from "../data/examplePatients";
import { patientFlowMap } from "../data/patientFlow";
import { PatientFlowStepKeys } from "../data/patientFlowKeys";
import { educationFlowMap } from "../data/educationFlow";
import { StepKeys, SubStepKeys } from "../models/Flows";
import { ActionMap, Reducer } from "../models/ReducerTypes";

// this hook handles global state across the app via the context API.
// it tracks the user's state during their use of the platform, including which
// flow they're using, which test they're taking in the patient flow, and which
// patient is active. the hook exposes several helper functions to help manage
// this state across the app

interface State {
  patients: { [key: string]: ExamplePatient };
  activePatientKey: string | null;
  activeXray: XrayForAnalysis | null;
  activeStepKey: StepKeys | null;
  originStepKey: StepKeys | null;
  activeSubStepKey: SubStepKeys | null;
}

enum ActionTypes {
  SET_ACTIVE_PATIENT_KEY = 'SET_ACTIVE_PATIENT_KEY',
  SET_ACTIVE_XRAY = 'SET_ACTIVE_XRAY',
  SET_ACTIVE_STEP_KEY = 'SET_ACTIVE_STEP_KEY',
  SET_ORIGIN_STEP_KEY = 'SET_ORIGIN_STEP_KEY',
  SET_ACTIVE_SUBSTEP_KEY = 'SET_ACTIVE_SUBSTEP_KEY',
  UPDATE_PATIENT_FLOW_STATE = 'UPDATE_PATIENT_FLOW_STATE',
};

type Payloads = {
  [ActionTypes.SET_ACTIVE_PATIENT_KEY]: string | null,
  [ActionTypes.SET_ACTIVE_XRAY]: XrayForAnalysis | null,
  [ActionTypes.SET_ACTIVE_STEP_KEY]: StepKeys | null,
  [ActionTypes.SET_ORIGIN_STEP_KEY]: StepKeys | null,
  [ActionTypes.SET_ACTIVE_SUBSTEP_KEY]: SubStepKeys | null,
  [ActionTypes.UPDATE_PATIENT_FLOW_STATE]: {
    patient: ExamplePatient,
    flow: RouteKeys,
    state: PatientTrainingState,
  },
};

type StorageActions = ActionMap<Payloads>[keyof ActionMap<Payloads>];

interface Action {
  type: ActionTypes;
  payload: any; // TODO: type this properly
}

interface GlobalStateContextValues {
  state: State,
  setState: Dispatch<StorageActions>,
}

const GlobalStateContext = createContext<GlobalStateContextValues | undefined>(undefined);
GlobalStateContext.displayName = 'GlobalStateContext';

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_PATIENT_KEY: {
      return { ...state, activePatientKey: action.payload };
    }
    case ActionTypes.SET_ACTIVE_XRAY: {
      return { ...state, activeXray: action.payload };
    }
    case ActionTypes.SET_ACTIVE_STEP_KEY: {
      return {...state, activeStepKey: action.payload };
    }
    case ActionTypes.SET_ORIGIN_STEP_KEY: {
      return {...state, originStepKey: action.payload };
    }
    case ActionTypes.SET_ACTIVE_SUBSTEP_KEY: {
      return {...state, activeSubStepKey: action.payload };
    }
    case ActionTypes.UPDATE_PATIENT_FLOW_STATE: {
      const patient = action.payload.patient;
      const flow = action.payload.flow;
      const updatedState = action.payload.state;

      return {
        ...state,
        patients: {
          ...state.patients,
          [patient.key]: {
            ...state.patients[patient.key],
            [flow]: updatedState,
          },
        },
      };
    }
    default: {
      throw new Error(`Invalid action type: ${action.type}`);
    }
  };
};

const stateInit: State = {
  patients: examplePatientsMap,
  activePatientKey: null,
  activeXray: null,
  activeStepKey: null,
  originStepKey: null,
  activeSubStepKey: null,
};

// TODO: session state can get confused here and start showing incorrect UI elements if multiple
// tabs are open in one session and running through different points of different patient exams.
// a potential solution if this becomes a problem would be to generate a UUID for each tab upon
// app mount if one does not exist, and save all exam state to session storage with that ID as
// the key rather than the below global key which all tabs currently pull from. this introduces
// the issue of losing saved state if the user reloads the page, but a potential solution there
// could be to write a wrapper hook around react-router-dom's useNavigate which automatically adds
// query parameters to each navigate() call, and then upon starting your first Training or TYK flow,
// adding that UUID to the query parameters and always pulling exam state from there.

const SAVE_STATE_PERSISTENT_KEY = 'appState';

export const GlobalStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // checking session storage to see if any active app state is saved there. if so, we use that
  // as the initial state
  const savedStateStr = sessionStorage.getItem(SAVE_STATE_PERSISTENT_KEY);
  const savedState = savedStateStr ? JSON.parse(savedStateStr) : null;

  const [store, dispatch] = useReducer<Reducer<State, StorageActions>>(reducer, savedState || stateInit);

  return (
    <GlobalStateContext.Provider value={{ state: store, setState: dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

interface UseGlobalStateParams {
  flow?: RouteKeys;
}

export const useGlobalState = (params?: UseGlobalStateParams) => {
  const context = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const { stepParam } = useParams();

  if (!context) throw new Error('useGlobalState should be called within a GlobalStateProvider');

  const { state, setState } = context;

  // the global state gets stringified here and saved in session storage in order to track the
  // user's progress in the tests across their active session
  useEffect(() => {
    const savedStateStr = sessionStorage.getItem(SAVE_STATE_PERSISTENT_KEY);
    if (savedStateStr === JSON.stringify(state)) return;
    sessionStorage.setItem(SAVE_STATE_PERSISTENT_KEY, JSON.stringify(state));
  }, [state]);

  const {
    patients,
    activePatientKey,
    activeXray,
    activeStepKey,
    originStepKey,
    activeSubStepKey,
  } = state;

  // this flow param gets passed in at the top level flow wrapper components:
  // HipEdu.tsx, KneeEdu.tsx, SBSTraining.tsx, TestYourKnowledge.tsx
  // it tracks which flow the user is currently working through between the hip
  // education, knee education, patient training, and patient testing flows
  const flow = params?.flow;
  const isPatientFlow = flow === RouteKeys.SBS_TRAINING || flow === RouteKeys.TEST_YOUR_KNOWLEDGE;

  // selecting the flow map containing the ordered steps for that flow based on the
  // flow parameter
  const flowMap = isPatientFlow ? patientFlowMap : educationFlowMap;

  // tracking which patient the user is actively working through in either of the patient
  // flows
  const activePatient = activePatientKey ? patients[activePatientKey] : null;
  const activePatientNumber = activePatient && isPatientFlow
    ? activePatient.flowOrders[flow]
    : null;

  // pulling all of the steps out from the active flow, skipping over ones that are excluded
  // from the current active flow
  const flowSteps = Object.values(flowMap).filter((step) => {
    if (step.excludeFrom && flow === step.excludeFrom) return null;
    return step;
  });

  // getting active step from the stepMap based on the activeStepKey value
  const activeStep = isPatientFlow 
    ? activeStepKey ? flowMap[activeStepKey] : flowSteps[0]
    : stepParam && flowMap[stepParam as StepKeys] ? flowMap[stepParam as StepKeys] : null;

  // pulling out the component for the active step. we pass the flow parameter in here so that
  // the component knows which flow the user is working through, and can render its contents
  // accordingly
  const activeStepComponent = flow
    ? activeStep?.renderComponent({ flow }) || <Navigate to={flowSteps[0].route || routes[RouteKeys.HOME].route} />
    : null;

  const activeStepIndex = activeStep ? flowSteps.indexOf(activeStep) : null;

  const activeSubStep = activeStep?.subSteps && activeSubStepKey
    ? activeStep.subSteps[activeSubStepKey]
    : null;

  const activeSubStepComponent = activeSubStep && flow
    ? activeSubStep.renderComponent({ flow })
    : null;

  // helpers exposed by the hook for easy state management across the app
  const setActivePatient = useCallback((key: string | null) => setState({ type: ActionTypes.SET_ACTIVE_PATIENT_KEY, payload: key }), [setState]);
  const setActiveXray = (xray: XrayForAnalysis | null) => setState({ type: ActionTypes.SET_ACTIVE_XRAY, payload: xray });
  const setActiveStep = useCallback((key: StepKeys | null) => setState({ type: ActionTypes.SET_ACTIVE_STEP_KEY, payload: key }), [setState]);
  // used to determine what the "back" button does, as the certain components
  // are reused in a couple of different places across the patient flow,
  // and thus their "back" buttons need to take the user to different places
  // based upon that context
  const setOriginStep = useCallback((key: StepKeys | null) => setState({ type: ActionTypes.SET_ORIGIN_STEP_KEY, payload: key }), [setState]);
  const setActiveSubStep = (key: SubStepKeys | null) => setState({ type: ActionTypes.SET_ACTIVE_SUBSTEP_KEY, payload: key });

  // saves the users current progress on a given patient in the patient flow, including
  // what selections they made during the xray evaluation steps as well as which care pathway
  // they selected. this is used to grade the user on each patient they tested on in the test
  // your knowledge flow
  const updatePatientFlowState = useCallback((newState: PatientTrainingState) => {
    if (!activePatient || !flow) return;
    setState({
      type: ActionTypes.UPDATE_PATIENT_FLOW_STATE,
      payload: {
        patient: activePatient,
        flow,
        state: newState,
      },
    });
  }, [activePatient, flow, setState]);

  const resetActivePatientState = useCallback(() => {
    if (!activePatient || !flow  || !isPatientFlow) return;
    updatePatientFlowState(initPatientFlowState);
    setActiveStep(flowSteps[1].key);
  }, [activePatient, flow, isPatientFlow, flowSteps, updatePatientFlowState, setActiveStep]);

  const lockInPatientSelectedXrays = (selections: { [key: string]: boolean }) => {
    if (!activePatient || !flow  || !isPatientFlow) return;
    updatePatientFlowState({
      ...activePatient[flow],
      selectedXrays: selections,
    });
  };
  const updatePatientSelectedXrays = (selection: string) => {
    if (!activePatient || !flow  || !isPatientFlow) return;
    updatePatientFlowState({
      ...activePatient[flow],
      selectedXrays: {
        ...activePatient[flow].selectedXrays,
        [selection]: activePatient[flow].selectedXrays[selection] ? false : true,
      },
    });
  };

  const completeActiveStep = () => {
    if (!activePatient || !activeStepKey || !flow || !isPatientFlow) return;
    updatePatientFlowState({ ...activePatient[flow], [activeStepKey]: true });
    closeActiveSubStep();
  };

  const closeActiveSubStep = () => setActiveSubStep(null);
  const completeActiveSubStep = () => {
    if (!activePatient || !activeSubStepKey || !flow || !isPatientFlow) return;
    updatePatientFlowState({ ...activePatient[flow], [activeSubStepKey]: true });
    closeActiveSubStep();
  };

  const closeActiveXrayEval = () => setActiveXray(null);
  const completeActiveXrayEval = () => {
    if (!activePatient || !activeXray  || !flow || !isPatientFlow) return;
    updatePatientFlowState({
      ...activePatient[flow],
      evaluatedXrays: {
        ...activePatient[flow].evaluatedXrays,
        [activeXray.id]: {
          ...activePatient[flow].evaluatedXrays[activeXray.id],
          complete: true,
        },
    }});
    closeActiveXrayEval();
  };
  // when a user is going through the xray evaluation process in the patient flow,
  // we only track what their first selection was in order to calcuate their grade, even
  // though the user is forced to find the right answer before moving on to the next step
  const saveActiveXrayEvalFirstSelection = (selection: DegenerationLevels) => {
    if (!activePatient || !activeXray  || !flow || !isPatientFlow) return;
    // if the given xray on the patient that owns it already has a firstSelection value,
    // we do nothing here
    if (activePatient[flow].evaluatedXrays[activeXray.id]?.firstSelection) return;
    // otherwise, we save the user's selection to that firstSelection field
    updatePatientFlowState({
      ...activePatient[flow],
      evaluatedXrays: {
        ...activePatient[flow].evaluatedXrays,
        [activeXray.id]: {
          ...activePatient[flow].evaluatedXrays[activeXray.id],
          firstSelection: selection,
        },
    }});
  };

  const completePatientFlow = useCallback(() => {
    if (!activePatient || !flow || !isPatientFlow) return;
    updatePatientFlowState({ ...activePatient[flow], patientComplete: true });
  }, [activePatient, flow, isPatientFlow, updatePatientFlowState]);

  const viewPatientExamScore = useCallback((patient: ExamplePatient) => {
    setActivePatient(patient.key);
    setOriginStep(activeStep?.key || null);
    setActiveStep(PatientFlowStepKeys.OverallReportCard);
  }, [activeStep, setActivePatient, setActiveStep, setOriginStep]);

  const next = useCallback(() => {
    setOriginStep(activeStep?.key || null);
    flowSteps[activeStepIndex! + 1].route
      ? navigate(flow ? `${routes[flow].route}${flowSteps[activeStepIndex! + 1].route!}` : routes[RouteKeys.HOME].route)
      : setActiveStep(flowSteps[activeStepIndex! + 1].key);
  }, [activeStep, flow, flowSteps, activeStepIndex, setActiveStep, setOriginStep, navigate]);

  const nextStep = () => {
    const onLastStep = activeStepIndex === flowSteps.length - 1;
    const onSecondToLastStep = activeStepIndex === flowSteps.length - 2;

    if (!onLastStep) {
      // we want to mark the active patient as complete when we're transitioning to the
      // last step instead of only after completing the last step
      if (isPatientFlow && onSecondToLastStep) completePatientFlow();
      next();
      return;
    }
    if (isPatientFlow && onLastStep) {
      setOriginStep(null);
      setActiveStep(flowSteps[0].key);
      return;
    }
    navigate(routes[RouteKeys.HOME].route);
  };

  const prev = useCallback(() => {
    setOriginStep(activeStep?.key || null);
    flowSteps[activeStepIndex! - 1].route
      ? navigate(flow ? `${routes[flow].route}${flowSteps[activeStepIndex! - 1].route!}` : routes[RouteKeys.HOME].route)
      : setActiveStep(flowSteps[activeStepIndex! - 1].key);
  }, [activeStep, flow, flowSteps, activeStepIndex, setActiveStep, setOriginStep, navigate]);

  const goHome = useCallback(() => {
    setActivePatient(null);
    setOriginStep(null);
    navigate(routes.home.route);
  }, [setActivePatient, setOriginStep, navigate]);

  const prevStep = activeStepIndex === 0
    ? () => goHome()
    : () => prev();

  return {
    patients,
    activePatient,
    activePatientNumber,
    activeXray,
    setActivePatient,
    updatePatientFlowState,
    resetActivePatientState,
    setActiveXray,
    activeStep,
    activeStepComponent,
    completeActiveStep,
    activeSubStep,
    activeSubStepComponent,
    setActiveSubStep,
    closeActiveSubStep,
    completeActiveSubStep,
    lockInPatientSelectedXrays,
    updatePatientSelectedXrays,
    saveActiveXrayEvalFirstSelection,
    completeActiveXrayEval,
    nextStep,
    prevStep,
    viewPatientExamScore,
    originStepKey,
    setActiveStep,
  };
};
