import React, { useState } from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { StepProps } from '../../../models/StepProps';
import { JointCompartments } from '../../../models/ExampleXray';
import { Button } from '../../atoms/Button';
import { RouteKeys } from '../../../data/routeKeys';
import { Surface } from '../../atoms/Surface';
import { ReactComponent as CheckIcon } from '../../../assets/icons/circle_check_icon.svg';
import { ReactComponent as StraightKnee1 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/KneeLat1.svg';
import { ReactComponent as StraightKnee2 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/KneeMedial.svg';
import { ReactComponent as StraightKnee3 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/KneeLat+Medial.svg';
import { ReactComponent as BentKnee1 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/Bent Knee.svg';
import { ReactComponent as BentKnee2 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/Kneecaphighlight.svg';
import { ReactComponent as BentKnee3 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/KneecapLabel.svg';
import { ReactComponent as Hip1 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/1_HipZ2.svg';
import { ReactComponent as Hip2 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/2_HipZ1.svg';
import { ReactComponent as Hip3 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/3_HipZ3.svg';
import { ReactComponent as Hip4 } from '../../../assets/educationAssets/IdentifyJointCompartmentsStep/4_HipComplete.svg';
import './IdentifyJointCompartmentsStep.scss';

// corresponding page in app:
// https://education.jointai.co/knee-education/compartment-identification

const IdentifyJointCompartmentsStep: React.FC<StepProps> = ({ flow }) => {
  const {
    prevStep,
    nextStep,
  } = useGlobalState({ flow });

  const {
    eduPageContainerClassName,
    eduContentContainerClassName,
  } = useLayoutState({
    eduPageContainerBaseClassName: 'IdentifyJointCompartmentsStep__Page',
    eduContentContainerBaseClassName: 'IdentifyJointCompartmentsStep__Container',
  });

  const isKneeFlow = flow === RouteKeys.KNEE_EDU;
  const isHipFlow = flow === RouteKeys.HIP_EDU;

  const compartments: [string, number][] = isKneeFlow
    ? [[JointCompartments.LATERAL, 2], [JointCompartments.MEDIAL, 1], [JointCompartments.KNEECAP, 3]]
    : [[JointCompartments.ZONE_2, 2], [JointCompartments.ZONE_1, 1], ['zone 3', 3]];

  interface CompartmentStateData {
    label: string;
    type: JointCompartments | string;
    identified: boolean;
    buttonOrder: number;
  }

  const [compartmentsState, setCompartmentsState] = useState<CompartmentStateData[]>(
    compartments.map(([comp, buttonOrder]) => ({
      label: isKneeFlow && comp !== JointCompartments.KNEECAP
        ? `Right ${comp}`
        : comp,
      type: comp,
      identified: false,
      buttonOrder,
    })
  ));

  const [showIncorrect, setShowIncorrect] = useState<boolean>(false);

  const firstInOrder = compartmentsState.find((c) => c.type === compartments[0][0]);
  const secondInOrder = compartmentsState.find((c) => c.type === compartments[1][0]);
  const thirdInOrder = compartmentsState.find((c) => c.type === compartments[2][0]);

  const identifyCompartment = (compartment: CompartmentStateData) => {
    setShowIncorrect(false);
    setCompartmentsState([
      ...compartmentsState.map((c) => {
        if (c.type === compartment.type) return {
          ...compartment,
          identified: true,
        }
        return c;
      })
    ]);
  };

  const handleOptionClick = (compartment: CompartmentStateData) => {
    if (compartment.type === compartments[0][0] && !compartment.identified) return identifyCompartment(compartment);
    if (compartment.type === compartments[1][0] && !compartment.identified) {
      if (!firstInOrder?.identified) {
        return setShowIncorrect(true);
      }
      return identifyCompartment(compartment);
    }
    if (compartment.type === compartments[2][0] && !compartment.identified) {
      if (!secondInOrder?.identified) {
        return setShowIncorrect(true);
      }
      return identifyCompartment(compartment);
    }
  };

  return (
    <div className={eduPageContainerClassName}>
      <div className={eduContentContainerClassName}>
        <div className='IdentifyJointCompartmentsStep__HeaderContainer'>
          <h3 className='IdentifyJointCompartmentsStep__Header'>
            {isKneeFlow && <>Learn about the Compartments of the Knee</>}
            {isHipFlow && <>Learn about the Three Zones of the Hip</>}
          </h3>
          <p className='IdentifyJointCompartmentsStep__SubHeader'>
            Click on the compartment name that matches the highlighted compartment.
          </p>
        </div>
        <div className='IdentifyJointCompartmentsStep__MainContent'>
          <div className='IdentifyJointCompartmentsStep__BtnContainer'>
            {compartmentsState.sort((a, b) => a.buttonOrder - b.buttonOrder).map((compartment) => (
              <Button
                key={compartment.label}
                className='IdentifyJointCompartmentsStep__OptButton'
                icon={compartment.identified && <CheckIcon />}
                onPress={() => handleOptionClick(compartment)}
                fullWidth
              >
                {compartment.label}
              </Button>
            ))}
          </div>
          <Surface className='IdentifyJointCompartmentsStep__ContentContainer'>
            {isKneeFlow && (
              <>
                <div className='IdentifyJointCompartmentsStep__ImgContainer'>
                  <p className='IdentifyJointCompartmentsStep__ImgLabel'>Right Knee</p>
                  <div className='IdentifyJointCompartmentsStep__GridStack'>
                    <StraightKnee3 className={!firstInOrder?.identified || !secondInOrder?.identified ? 'ijcs-hide' : ''} />
                    <StraightKnee2 className={!firstInOrder?.identified || secondInOrder?.identified ? 'ijcs-hide' : ''} />
                    <StraightKnee1 className={firstInOrder?.identified ? 'ijcs-hide' : ''} />
                  </div>
                </div>
                <div className='IdentifyJointCompartmentsStep__ImgContainer'>
                  <p className='IdentifyJointCompartmentsStep__ImgLabel'>Right Knee {"(Bent)"}</p>
                  <div className='IdentifyJointCompartmentsStep__GridStack'>
                    <BentKnee3 />
                    <BentKnee2 className={thirdInOrder?.identified ? 'ijcs-hide' : ''} />
                    <BentKnee1 className={secondInOrder?.identified ? 'ijcs-hide' : ''} />
                  </div>
                </div>
              </>
            )}
            {isHipFlow && (
              <div className='IdentifyJointCompartmentsStep__ImgContainer'>
                <div className='IdentifyJointCompartmentsStep__GridStack'>
                  <Hip4 className='ijcs-hip' />
                  <Hip3 className={`ijcs-hip ${thirdInOrder?.identified ? 'ijcs-hide' : ''}`} />
                  <Hip2 className={`ijcs-hip ${secondInOrder?.identified ? 'ijcs-hide' : ''}`} />
                  <Hip1 className={`ijcs-hip ${firstInOrder?.identified ? 'ijcs-hide' : ''}`} />
                </div>
              </div>
            )}
          </Surface>
        </div>
      </div>
      <NavigationFooter
        prev={{ action: prevStep }}
        centerElement={
          showIncorrect
            ? <h3 className='IdentifyJointCompartmentsStep__IncorrectLabel'>Try Again!</h3>
            : null
        }
        next={{
          action: nextStep,
          disabled: !!compartmentsState.find((c) => !c.identified),
        }}
      />
    </div>
  );
};

export default IdentifyJointCompartmentsStep;
