import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { NavigationFooter } from '../../molecules/NavigationFooter';
import { StepProps } from '../../../models/StepProps';
import { educationVideos } from '../../../data/educationVideoData';
import './VideoShowcaseStep.scss';

// corresponding page in app:
// https://education.jointai.co/knee-education/joint-arthritis
// (all video steps in the education flows use this component)

const VideoShowcaseStep: React.FC<StepProps> = ({ flow }) => {
  const {
    activeStep,
    prevStep,
    nextStep,
  } = useGlobalState({ flow });
  
  const { eduPageContainerClassName } = useLayoutState({
    eduPageContainerBaseClassName: 'VideoShowcaseStep__Page',
  });

  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

  useEffect(() => {
    setVideoLoaded(false);
  }, [activeStep]);

  if (!flow) return null;

  const videoData = educationVideos[flow]![activeStep!.key];

  if (!videoData) return null;

  return (
    <div className={eduPageContainerClassName}>
      <div className='VideoShowcaseStep__Container'>
        <div className='VideoShowcaseStep__MainContent'>
          <div className='VideoShowcaseStep__HeaderContainer'>
            <h3 className='VideoShowcaseStep__Header'>{videoData.headline}</h3>
          </div>
          <div className='VideoShowcaseStep__PlayerWrapper'>
            {!videoLoaded && <div className='VideoShowcaseStep__Loader' />}
            <ReactPlayer
              controls
              className="VideoShowcaseStep__Player"
              url={videoData.url}
              width="100%"
              height="100%"
              onReady={() => setVideoLoaded(true)}
              style={{ opacity: videoLoaded ? 1 : 0 }}
            />
          </div>
        </div>
      </div>
      <NavigationFooter
        prev={{ action: prevStep }}
        next={{ action: nextStep }}
      />
    </div>
  );
};

export default VideoShowcaseStep;
