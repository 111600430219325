import { useEffect, useCallback, useRef } from 'react';

// exposes a ref that can be applied to a DOM element in order to
// fire the provided callback when any element outside of the one with
// the ref is clicked

const useOutsideClick = (
  callback: (arg?: any) => void,
) => {
  const elementRef = useRef<any>();

  const handleOutsideClick = useCallback((e: MouseEvent): void => {
    if (elementRef.current && !elementRef.current.contains(e.target as Node)) {
      callback();
    }
  }, [callback, elementRef]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [handleOutsideClick]);

  return { elementRef };
};

export default useOutsideClick;