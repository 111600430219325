import React, { HTMLAttributes } from 'react';
import { DegenerationLevels } from '../../../models/ExampleXray';
import './DegenerationIndicator.scss';

type DegenerationIndicatorProps = {
  degLvl?: DegenerationLevels | null
} & HTMLAttributes<HTMLDivElement>;

const DegenerationIndicator: React.FC<DegenerationIndicatorProps> = ({
  degLvl,
  ...props
}) => {
  const getCssClass = () => {
    switch(degLvl) {
      case DegenerationLevels.ENDSTAGE: return 'error';
      case DegenerationLevels.NEAR_ENDSTAGE: return 'error';
      case DegenerationLevels.MODERATE: return 'warning';
      case DegenerationLevels.NORMAL: return 'success';
      default: return 'none';
    };
  };

  return (
    <div
      {...props}
      className={`DegenerationIndicator__Container ${getCssClass()} ${props.className ? props.className : ''}`}
    >
      <h4>{degLvl}</h4>
    </div>
  );
};

export default DegenerationIndicator;
