import React from 'react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { RouteKeys } from '../../../data/routeKeys';
import { EducationNavPanel } from '../../molecules/EducationNavPanel';
import './HipEdu.scss';

// wrapper for the hip education module

const HipEdu: React.FC = () => {
  // this flow variable is fed into useGlobalState below to determine
  // the active step based on the user's actions and the current module
  // they're viewing, along with that step's corresponding component
  const flow = RouteKeys.HIP_EDU;

  const { activeStepComponent, activeStep } = useGlobalState({ flow });

  return (
    <div className='HipEdu__Page'>
      <EducationNavPanel flow={flow} activeStep={activeStep} />
      {activeStepComponent}
    </div>
  );
};

export default HipEdu;
