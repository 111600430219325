import ReactGA from "react-ga4";
import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { DropdownMenu } from '../../molecules/DropdownMenu';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../../data/routes';
import { RouteKeys } from '../../../data/routeKeys';
import { ReactComponent as JointIqLogo } from '../../../assets/logos/jointiQ logo.svg';
import './App.scss';

const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production'

if (IS_PRODUCTION && process.env.REACT_APP_GA_MEASUREMENT_CODE) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_CODE);
}

const App = () => {
  const { pathname } = useLocation();
  const basePathname = '/' + pathname.split('/').filter((chunk) => chunk !== '/' && chunk !== '')[0];

  const navigate = useNavigate();

  const { setActiveStep, setActivePatient, setActiveXray } = useGlobalState();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (IS_PRODUCTION) {
      ReactGA.send({ hitType: "pageview", page: pathname });
    }
  }, [pathname]);

  const hideDropdown = pathname === routes[RouteKeys.HOME].route
    || pathname === routes[RouteKeys.CTA].route;

  const menuItems = Object.values(routes)
    .filter((route) => route.menuLabel)
    .map((route) => ({
      label: route.menuLabel!,
      action: () => {
        setActivePatient(null);
        setActiveStep(null);
        setActiveXray(null);
        navigate(route.firstSubroute ? route.route + route.firstSubroute : route.route);
      },
      icon: route.menuIcon,
      active: basePathname === route.route,
    }));

  return (
    <div className="App">
      <div className='App__MenuContainer'>
        <div className={`App__Dropdown ${hideDropdown ? 'add-hidden' : ''}`}>
          <DropdownMenu
            icon={<FontAwesomeIcon icon={faBars} />}
            items={menuItems}
            contextualHide={hideDropdown}
          />
        </div>
        <JointIqLogo className={`App__Logo ${hideDropdown ? 'add-hidden' : ''}`} />
      </div>
      <Routes>
        {Object.entries(routes).map(([label, routeData]) => (
          <Route
            key={label}
            path={label === RouteKeys.KNEE_EDU || label === RouteKeys.HIP_EDU ? `/${routeData.route}/:stepParam` : routeData.route}
            element={routeData.component}
          />
        ))}
        <Route path='*' element={<Navigate to={routes[RouteKeys.HOME].route} />} />
      </Routes>
    </div>
  );
};

export default App;
